import {useState, useEffect, useRef, useCallback} from 'react';
import {useParams} from 'react-router-dom';

import NavUtils from '../../utils/NavUtils';
import UserUtils from '../../utils/UserUtils';
import APIUtils from '../../utils/APIUtils';
import Payment from "../Dormant/Payment";
import BankInfo from "../General/BankInfo";

import {UserInfo} from '../../interfaces/user';

import {ReactComponent as IndividualIcon} from '../../assets/img/individual.svg';
import {ReactComponent as Logo} from '../../assets/img/logo.svg';

const Profile = (props: {
  isLoading: boolean
  isPublic: () => void;
}) => {
  const userInfoRef = useRef(false);
  const {username} = useParams();
  
  const [showMobilePayment, setShowMobilePayment] = useState(false);
  const [showCardPayment, setShowCardPayment] = useState(false);
  const [showACHPayment, setShowACHPayment] = useState(false);

  const [isActiveCardPayments, setIsActiveForCardPayments] = useState(false);
  const [hasBankInfo, setHasBankInfo] = useState(false);

  const [userInfo, setUserInfo] = useState<UserInfo>(UserUtils.getEmptyUserInfo());

  const retrieveAccountInfo = (username: string) => {
    APIUtils.callGet(`api/account/profile/${username}`)
    .then(response => {
      if(response.status === 200) {
        return setUserInfo(response.data.userInfo);
      }
      
      NavUtils.redirectToRoot();
    })
    .catch((err) => {
      console.error(err);
      
      NavUtils.redirectToRoot();
    });
  };

  const togglePaymentView = useCallback((view?: string) => {
    if (view === 'ACH' || (hasBankInfo && !isActiveCardPayments)) {
      setShowCardPayment(false);
      setShowACHPayment(true);
    }
    if (view === 'Card' || isActiveCardPayments) {
      setShowACHPayment(false);
      setShowCardPayment(true);
    }
  }, [hasBankInfo, isActiveCardPayments]);

  const checkBankInfo = useCallback(() => {
    if (
      userInfo.bankrouting !== '' && 
      userInfo.bankaccount !== ''
      ){
      setHasBankInfo(true);
    }
  }, [userInfo, setHasBankInfo]);

  const checkAccountEligibility = (stripeconnectid: string) => {
    APIUtils.callGet(`api/payment/eligibility/${stripeconnectid}`)
    .then(response => {
      if (response.data !== true) {
        return setIsActiveForCardPayments(false);
      }
      return setIsActiveForCardPayments(true);
    })
    .catch((err) => {
      return console.error(err);
    });
  };

  useEffect(() => {
    if (userInfoRef.current || !username) return;
    userInfoRef.current = true;
    retrieveAccountInfo(username);
	}, [username]);

  useEffect(() => {
    if(userInfo.stripeconnectid) {
      checkAccountEligibility(userInfo.stripeconnectid);
    }
    checkBankInfo();
    togglePaymentView();
	}, [userInfo, checkBankInfo, togglePaymentView]);

  useEffect(() => {
    props.isPublic();
  }, [props]);

  return (
    <>
    {props.isLoading && (
      <div className="loader"></div>
    )}
    {!props.isLoading && (
      <div className="componentWrap" id="Profile">
        <div className="left">
          <a href="/">
            <Logo />
          </a>
          <div className="wrap">
            <div className="accountCopy">
              <article>
                <div className="image" style={{backgroundImage: `url(${userInfo?.image})`}}>
                  {!userInfo?.image && (
                    <IndividualIcon />
                  )}
                </div>
                <div className="username">@{userInfo.username}</div>
                {userInfo.firstname && userInfo.lastname && (
                  <div>{userInfo.firstname} {userInfo.lastname}</div>
                )}
              </article>
            </div>
            <div className="btnWrap">
              <a href={`${userInfo.username}/start-a-project`} className="btn rounded startAProjectBtn">
                <span>Start A Project</span>
              </a>
              <div className="split">
                {userInfo.phone && (
                  <a href={`tel:${userInfo.phone}`} className="btn outline rounded green">Call</a>
                )}
                {userInfo.email && (
                  <a href={`mailTo:${userInfo.email}`} className="btn outline rounded green">Email</a>
                )}
              </div>
              {
                (isActiveCardPayments ||
                  hasBankInfo) && (
                    <div className="mobile">
                      <span className="or">
                        <span>or</span>
                      </span>
                      <button className="btn rounded" onClick={() => setShowMobilePayment(true)}>
                        <span>
                          Send Payment
                        </span>
                      </button>
                    </div>
                  )
              }
            </div>
          </div>
        </div>
        {
          (isActiveCardPayments ||
          hasBankInfo) && (
            <div className={showMobilePayment ? 'right show': 'right'}>
              <div className="wrap">
                {isActiveCardPayments && hasBankInfo && (
                  <>
                  <h3>Payment</h3>
                  <div className="btnWrap">
                    <div className="split">
                        <button className={`btn outline rounded ${showCardPayment ? 'light' : ''}`} onClick={() => togglePaymentView('Card')}>Card</button>
                        <button className={`btn outline rounded ${showACHPayment ? 'light' : ''}`} onClick={() => togglePaymentView('ACH')}>Wire</button>
                    </div>
                  </div>
                  </>
                )}
                {(isActiveCardPayments && showCardPayment) && (
                  <Payment 
                    userInfo={userInfo}
                    closeModal={() => setShowMobilePayment(false)}
                  />
                )}
                {(hasBankInfo && showACHPayment) && (
                  <BankInfo 
                    routing={userInfo.bankrouting ? userInfo.bankrouting : ''}
                    account={userInfo.bankaccount ? userInfo.bankaccount : ''}
                    closeModal={() => setShowMobilePayment(false)}
                  />
                )}
              </div>
            </div>
          )
        }
      </div>
    )}
    </>
  );
}

export default Profile;
