import React, {useState, useRef} from 'react';
import PhoneNumberInput from '@bebeau/phone-number-input';
import {IntakeClient} from '../../interfaces/client';
import AddressInput from '../General/AddressInput';
import EmailUtils from '../../utils/EmailUtils';

const IntakeModal = (props: {
  username: string,
  showModal: boolean,
  closeModal: () => void,
  showDraftNDA: boolean,
  create: (clientInfo: any) => void,
}) => {
  const unitRef = useRef<HTMLInputElement | null>(null);
  const [errorFields, setErrorFields] = useState<string[]>([]);

  const [clientInfo, setClientInfo] = useState<IntakeClient>({
    firstname: '',
    lastname: '',
    company: '',
    email: '',
    phone: '',
    address: '',
    unit: '',
    city: '',
    state: '',
    zip: '',
  });

  const handleChange = async (e: any, key: string) => {
    e.preventDefault();
    let client: any = {...clientInfo};
    client[key as keyof typeof client] = e.target.value;
    if(errorFields.includes(key)) {
      const errors = [...errorFields];
      const target = errors.indexOf(key)
      errors.splice(target, 1);
      setErrorFields(errors);
    }
    setClientInfo(client);
  };

  const handlePhoneChange = (value: string) => {
    let client: any = {...clientInfo};
    client['phone' as keyof typeof client] = value;
    setClientInfo(client);
  }

  const handleAddressSelect = async (address: any) => {
    let client: any = {...clientInfo};
    client['address'] = address.address;
    client['city'] = address.city;
    client['state'] = address.state;
    client['zip'] = address.zip;
    client['country'] = address.country;
    setClientInfo(client);
    if(unitRef.current) {
      unitRef.current.focus();
    }
  }

  const validateRequiredFields = () => {
    let errors: string[] = [];

    if (typeof clientInfo.email === 'string' && clientInfo.email.length) {
      if (!EmailUtils.isValid(clientInfo.email)) {
        errors.push('email');
      }
    }

    Object.keys(clientInfo).forEach((value) => {
      const optional = ['company', 'unit', 'phone'];
      if(!optional.includes(value) && clientInfo[value as keyof typeof clientInfo] === '') {
        errors.push(value);
      }
    })
    return errors;
  }

  const handleSubmit = () => {
    const errors = validateRequiredFields();
    if(errors.length) {
      setErrorFields(errors);
      return setErrorFields(errors);
    }
    
    props.create(clientInfo);
  }

  return (
    <div id="intakeModal" className={`modal ${props.showModal ? 'show' : ''}`}>
      <div className="modalWrap">
        <button className="btn outline rounded icon close" onClick={() => props.closeModal}></button>
        <div className="modalCopy">
          <h3>Complete Request</h3>
          <p>Upon submitting your project information, you will receive a pricing proposal from @{props.username} for your project request.</p>
          <div className='fieldWrap'>
            
            <h5>Contact</h5>
            <div className="split">
              <input className={errorFields.includes('firstname') ? 'error': ''} placeholder="First Name" value={clientInfo.firstname} onChange={(e) => {handleChange(e, 'firstname')}} />
              <input className={errorFields.includes('lastname') ? 'error': ''} placeholder="Last Name" value={clientInfo.lastname} onChange={(e) => {handleChange(e, 'lastname')}} />
            </div>
            <div className="split">
              <input className={errorFields.includes('email') ? 'error': ''} placeholder="Email" value={clientInfo.email} onChange={(e) => {handleChange(e, 'email')}} />
              <PhoneNumberInput 
                onInputChange={(data: {
                  number: string,
                  isValid: boolean,
                }) => handlePhoneChange(data.number)}
                placeholder='Phone Number'
              />
            </div>
            <input placeholder="Company" value={clientInfo.company} onChange={(e) => {handleChange(e, 'company')}} />
            
            <h5>Address</h5>
            <div className="split address">
              <AddressInput 
                updateAddress={(address) => handleAddressSelect(address)}
                defaultValue={clientInfo.address}
                errorFields={errorFields}
              />
              <input className="unit" ref={unitRef} placeholder="Unit" value={clientInfo.unit} onChange={(e) => {handleChange(e, 'unit')}} />
            </div>
            <div className="split three">
              <input className={errorFields.includes('city') ? 'error': ''} placeholder="City" value={clientInfo.city} onChange={(e) => {handleChange(e, 'city')}} />
              <input className={errorFields.includes('state') ? 'error': ''} placeholder="State" value={clientInfo.state} onChange={(e) => {handleChange(e, 'state')}} />
              <input className={errorFields.includes('zip') ? 'error': ''} placeholder="Zip" value={clientInfo.zip} onChange={(e) => {handleChange(e, 'zip')}} />
            </div>
          </div>

        </div>

        <button className="btn rounded" onClick={handleSubmit}>
          <span>Submit</span>
        </button>

      </div>
    </div>
  );
}

export default IntakeModal;

