import React, {useRef, useState, useEffect} from 'react';
import {PaymentItem} from '../../interfaces/invoice';
import PaymentUtils from '../../utils/PaymentUtils';

const ProposalItems = (props: {
  invoiceApproved: boolean,
  items: PaymentItem[],
  update: (item: PaymentItem[]) => void,
  remove: (index: number) => void,
}) => {

  const initRef = useRef<boolean>(false);
  const itemsRef = useRef<any>([]);
  const [targetRef, setTargetRef] = useState<any>();
  const [targetValue, setTargetValue] = useState<string>('');

  const addNewItemInput = () => {
    let update_items: PaymentItem[] = [...props.items];
    update_items.push({
      description: '',
      qty: 1,
      price: 0.00,
    });
    props.update(update_items);
  }

  const removeItemInput = (index: number) => {
    let update_items: PaymentItem[] = [...props.items];
    update_items.splice(index, 1);

    props.update(update_items);
  }

  const updateItems = (e: any, index: number, field: string) => {
    setTargetRef(itemsRef.current[index]);
    setTargetValue(e.target.value);
    let update_items: PaymentItem[] = [...props.items];

    if (field === 'description') {
      update_items[index].description = e.target.value;
    }
    if (field === 'qty') {
      update_items[index].qty = Number(e.target.value);
    }
    if (field === 'price') {
      update_items[index].price = Number(e.target.value);
    }

    props.update(update_items);
  }

  const resizeItems = () => {
    itemsRef.current.forEach((item: HTMLTextAreaElement, index: number) => {
      if (item) {
        itemsRef.current[index].style.height = '0px';
        const scrollHeight = itemsRef.current[index].scrollHeight;
        itemsRef.current[index].style.height = scrollHeight + 'px';
      }
    });
  }

  useEffect(() => {
    if (targetRef) {
      targetRef.style.height = '0px';
      const scrollHeight = targetRef.scrollHeight;
      targetRef.style.height = scrollHeight + 'px';
    }
  }, [targetRef, targetValue]);

  useEffect(() => {
    if (props.items.length === 0) {
      return addNewItemInput();
    }
    if (props.items.length > 0 && itemsRef.current.length > 0) {
      if (initRef.current) return;
      initRef.current = true;
      resizeItems();
    }
  }, [props.items]);

  useEffect(() => {
    const handleResize = () => {
      resizeItems();
    }
    window.addEventListener("resize", () => handleResize());
    return () => {
      window.removeEventListener("resize", () => handleResize());
    }
  }, []);
  
  useEffect(() => {
    resizeItems();
  }, [props.update]);

  return (
    <div className="fieldWrap items">
      <div className="labelWrap">
        <div className="left">
          <div className="label">Items</div>
        </div>
        <div className="right">
          <div className="label qty">Qty</div>
          <div className="label price">Price</div>
          <div className="label total">Total</div>
        </div>
      </div>
      <div className="itemsWrap">
        {
          props.items.length > 0 &&
          props.items.map((item: PaymentItem, index: number) => {
            return(
              <div className="item" key={`item-${index}`}>
                <div className="desc">
                  <textarea 
                    ref={ref => itemsRef.current.push(ref)}
                    value={item.description} 
                    onChange={!props.invoiceApproved ? (e) => updateItems(e, index, 'description') : (e) => {}} 
                    placeholder="Add item description...">
                  </textarea>
                </div>
                <div className="calc">
                  <div className="fieldWrap qty">
                    <input type='number' value={Number(item.qty) > 0 ? Number(item.qty) : '1'} onChange={!props.invoiceApproved ? (e) => updateItems(e, index, 'qty') : (e) => {}} placeholder="0"></input>
                  </div>
                  <div className="fieldWrap cost">
                    <input type='number' value={Number(item.price) > 0 ? Number(item.price) : ''} onChange={!props.invoiceApproved ? (e) => updateItems(e, index, 'price') : (e) => {}} placeholder="0.00"></input>
                  </div>
                  <div className="fieldWrap total">
                    {PaymentUtils.formatPrice(Number(item.qty) * Number(item.price))}
                  </div>
                </div>
                <div className="mobileLabels">
                  <div className="label">Qty</div>
                  <div className="label">Cost</div>
                  <div className="label">Total</div>
                </div>
                { !props.invoiceApproved &&
                  <button className="btn rounded outline delete" onClick={() => removeItemInput(index)}></button>
                }
              </div>
            );
          })
        }
      </div>
      { !props.invoiceApproved &&
        <div className="btnRight">
          <button className="btn rounded addItem" onClick={() => addNewItemInput()}>
            <span>Add Item</span>
          </button>
        </div>
      }
    </div>
  );
}

export default ProposalItems;
