import _ from 'lodash';
import {useState, useEffect} from 'react';

import DashHead from './DashHead';
import ContractDashMetrics from './ContractDashMetrics';
import InvoiceDashMetrics from './InvoiceDashMetrics';
import ProjectsListing from '../Project/ProjectsListing';
import RecentActivity from './RecentActivity';
import APIUtils from '../../utils/APIUtils';

import {UserInfo} from '../../interfaces/user';
import {Modal} from '../../interfaces/modal';

const Dashboard = (props: {
  userInfo: UserInfo | undefined,
  isAdmin: boolean,
  isLoading: boolean,
  showModal: (details: Modal) => void;
}) => {
  const [contractStats, setContractStats] = useState<any>({
    pending: 0,
    declined: 0,
    signed: 0,
  });

  const [invoiceStats, setInvoiceStats] = useState<any>({
    paid: 0,
    pending: 0,
    late: 0,
  });

  const retrieveContractStats = _.debounce(() => {
    APIUtils.callGet(`api/contract_stats`)
    .then(response => {
      setContractStats(response.data);
    })
    .catch((err) => {
      return console.error(err);
    });
  }, 300);

  const retrieveInvoiceStats = _.debounce(() => {
    APIUtils.callGet(`api/invoice_stats`)
    .then(response => {
      setInvoiceStats(response.data);
    })
    .catch((err) => {
      return console.error(err);
    });
  });

  useEffect(() => {
    if (props.userInfo === undefined) return;
    
    retrieveContractStats();
    retrieveInvoiceStats();
  }, [props.userInfo]);

  return (
    <>
    {props.isLoading && (
      <div className="loader"></div>
    )}
    {!props.isLoading && props.userInfo && (
      <div className="componentWrap" id="dashboard">
        <DashHead
          pageTitle="Dashboard"
          userInfo={props.userInfo}
        />
        <ProjectsListing 
          showTitle={true}
          showButton={true}
          username={props.userInfo.username}
          showModal={(details: Modal) => props.showModal(details)}
        />
        <InvoiceDashMetrics
          collected={invoiceStats.paid}
          pending={invoiceStats.pending}
          outstanding={invoiceStats.late}
          showTitle={true}
        />
        <ContractDashMetrics 
          showFull={true}
          signed={contractStats.signed}
          pending={contractStats.pending}
          declined={contractStats.declined}
        />
        <RecentActivity 
          userID={props.userInfo.id}
        />
      </div>
    )}
    </>
  );
}

export default Dashboard;
