import md5 from 'md5';
import {useState, useEffect} from 'react';

import APIUtils from '../../utils/APIUtils';
import ForgotPassword from '../Login/ForgotPassword';

import {Modal} from '../../interfaces/modal';

const Password = (props: {
  email: string,
  showModal: (details: Modal) => void,
}) => {
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [showForgot, setShowForgot] = useState<boolean>(false);

  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
  const [passMatch, setPassMatch] = useState<boolean>(false);

  const updatePassword = async () => {
    let payload = {
      email: props.email,
      key: md5(currentPassword),
      password: md5(newPassword),
    };

    let response = await APIUtils.callPost('api/account/reset_password', payload);
    if (response.status !== 200) {
      return props.showModal({
        type: 'error',
        icon: 'user',
        title: `Updating Password`,
        message: 'Unable to update password!',
        redirect: '',
      });
    }

    setNewPassword('');
    setConfirmNewPassword('');
    setCurrentPassword('');
    setPassMatch(false);
    
    props.showModal({
      type: 'success',
      icon: 'user',
      title: `Password Updated`,
      message: 'Account password updated!',
      redirect: '',
    });
  };

  useEffect(() => {
    setPassMatch(false);
    if (newPassword === '' || confirmNewPassword === '') return;
    if (newPassword === confirmNewPassword) {
      setPassMatch(true);
    }
  }, [newPassword, confirmNewPassword]);

  const passStyles = `
    fieldWrap passWrap
    ${passMatch ? 'match' : 'diff'}
    ${newPassword !== '' ? 'compare' : ''}
  `
  
  return (
    <div id="profilePass">
      {showForgot && (
        <>
          <div className="details">
            <h2>
            <button className="btn rounded outline icon back" onClick={() => setShowForgot(false)}></button>
            Forgot Password
            </h2>
          </div>
          <ForgotPassword
            email={props.email}
            setShowForgot={() => setShowForgot(false)}
            showModal={(details) => props.showModal(details)}
          />
        </>
      )}
      {!showForgot && (
        <>
          <div className="details">
            <h2>Change Password</h2>
          </div>

          <div className="fieldWrap">
            <div className="label">Current Password *</div>
            <input type="password"  value={currentPassword} onChange={(e: any) => {setCurrentPassword(e.target.value)}} placeholder="******" />
            <button className="btn link green forgot" onClick={() => setShowForgot(true)}>Forgot Password?</button>
          </div>

          <div className="break"></div>

          <div className={`${passStyles} new`}>
            <div className="label">New Password *</div>
            <input type="password" value={newPassword} onChange={(e: any) => {setNewPassword(e.target.value)}} placeholder="******" />
          </div>
          <div className={`${passStyles} confirm`}>
            <div className="label">Confirm New Password *</div>
            <input type="password" value={confirmNewPassword} onChange={(e: any) => {setConfirmNewPassword(e.target.value)}} placeholder="******"  />
          </div>
          {currentPassword && passMatch && (
            <button className="btn rounded" onClick={updatePassword}>
              <span>Change Password</span>
            </button>
          )}
        </>
      )}
    </div>
  );
}

export default Password;
