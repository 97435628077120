var config = {};

config.web = {};
config.web.server_base = 'api.go-potion.com';
config.web.server_protocol = 'https';

config.stripe = {};
config.stripe.pk = 'pk_live_51NzrKmLnoazJ7SdspT7ARJtOeEmtuJqEdENxfbZF8wWTk6YMvjvfMhaHUM3bzrh6KodGm2VYEubY8ejVhZmtn6r0001JcSydiX';

config.google = {};
config.google.sk = 'AIzaSyBRDhuo72Y6fUYcIE2Of1FjnoJ_HnYeXQ0';

module.exports = config;
