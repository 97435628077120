import React from 'react';
import NavUtils from '../../utils/NavUtils';

import {ReactComponent as SignedIcon} from '../../assets/img/icons/signed.svg';
import {ReactComponent as TimeIcon} from '../../assets/img/icons/time.svg';
import {ReactComponent as DeclinedIcon} from '../../assets/img/icons/declined.svg';

const ContractDashMetrics = (props: {
  signed: number;
  pending: number;
  declined: number;
  showFull: boolean;
  setFilter?: (filter: string) => void;
}) => {
  const handleFilterClick = (filter: string) => {
    if (props.showFull) {
      return NavUtils.redirectToContractListing(filter);
    }
    if (props.setFilter) {
      return props.setFilter(filter);
    }
  }

  const handleCreateClick = () => {
    let currentPageSlug: any = window.location.pathname.replace('/', '');

    NavUtils.redirectToAddContract(currentPageSlug);
  };

  return (
    <div className="metricWrap">
      { props.showFull &&
        <div className="headerWrap">
          <h2>Contracts</h2>
          <button className="btn outline rounded icon add" onClick={handleCreateClick}>
            <span></span>
            <span></span>
          </button>
        </div>
      }
      <div className="statsWrap" id="contractStats">
        <div className="box" onClick={() => handleFilterClick('signed')}>
          <div className={props.showFull ? "top" : "topRaw"}>
            <div className="icon green">
              <SignedIcon />
            </div>
          </div>
          <div>
            <span className="label">Signed</span>
            <div className="metric">{props.signed}</div>
          </div>
        </div>
        <div className="box" onClick={() => handleFilterClick('pending')}>
          <div className={props.showFull ? "top" : "topRaw"}>
            <div className="icon yellow">
              <TimeIcon />
            </div>
          </div>
          <div>
            <span className="label">Pending</span>
            <div className="metric">{props.pending}</div>
          </div>
        </div>
        <div className="box warn" onClick={() => handleFilterClick('declined')}>
          <div className={props.showFull ? "top" : "topRaw"}>
            <div className="icon red">
              <DeclinedIcon />
            </div>
          </div>
          <div>
            <span className="label">Declined</span>
            <div className="metric">{props.declined}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContractDashMetrics;
