import {useState, useEffect} from 'react';

import {UserInfo} from '../../interfaces/user';
import {Invoice} from '../../interfaces/invoice';
import InvoiceViewItem from './PaymentViewItem';
import PaymentUtils from '../../utils/PaymentUtils';

import {ReactComponent as Logo} from '../../assets/img/logo.svg';

const InvoiceTemplate = (props: {
  userInfo: UserInfo | undefined;
  invoice: Invoice
}) => {

  return (
    <>

      <div className="invoiceTop">
        <div>
          <Logo />
        </div>

        <div className="fieldWrap paymentTotal">
          <div className="price">
            {PaymentUtils.formatPrice(props.invoice.total)}
          </div>
        </div>
      </div>

      <div className="fieldWrap items">
        <div className="label">Items</div>
        <div className="itemsWrap">
          {
          props.invoice &&
          props.invoice.items.map((item: any, i: number) => {
            return (
              <InvoiceViewItem 
                key={i}
                item={item}
                isProposal={props.invoice.isproposal}
              />
            )
          })}
        </div>
      </div>

    </>
  )
}

export default InvoiceTemplate;
