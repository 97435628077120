import React, {useState} from "react";
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import CardSection from "./CardSection";
import APIUtils from '../../utils/APIUtils';

const CheckoutForm = (props:{
  clientSecret: string,
  stripeConnectID: string,
  payoutInCents: number,
  closeModal: () => void,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [checkoutError, setCheckoutError] = useState('');
  const [checkoutLoading, setCheckoutLoading] = useState(false);

  const payoutChargeToUser = async () => {
    setCheckoutLoading(true);
    await APIUtils.callPost('api/payment/payout', {stripeConnectID: props.stripeConnectID, payoutInCents: props.payoutInCents})
		.then(response => {
      if(response.status !== 200) {
        return setCheckoutError(response.message);
      }
      return window.location.pathname = '/success';
    })
		.catch((err) => {
      console.log(err.message);
			return setCheckoutError('Server error. Unable to login.');
		})
    .finally(() => {
      return setCheckoutLoading(false);
    });
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setCheckoutLoading(true);
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    if (cardElement) {
      const result = await stripe.confirmCardPayment(props.clientSecret, {
        payment_method: {
          card: cardElement
        }
      });
      if (result.error) {
        if(typeof result.error.message === 'string') {
          setCheckoutError(result.error.message);
        }
        setCheckoutLoading(false);
        return console.log(result.error.message);
      }
      payoutChargeToUser();
    }
  };

  const closeModal = (event: any) => {
    event.preventDefault();
    props.closeModal();
  }

  return (
    <form className="paymentForm" onSubmit={handleSubmit}>
      <CardSection />
      {
        checkoutError && (
          <div className="alert error">
            {checkoutError}
          </div>
        )
      }
      <div className="btnWrap">
        <button type="submit" className="btn rounded" disabled={!stripe}>
          {checkoutLoading && (
            <span><div className="loader"></div></span>
          )}
          {!checkoutLoading && (
            <span>Submit</span>
          )}
        </button>
        <button className="btn outline rounded mobile" onClick={(e) => closeModal(e)}>Close</button>
      </div>
    </form>
  );
}

export default CheckoutForm;