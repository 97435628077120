import {useState, useEffect, useRef, useCallback} from 'react';
import {UserInfo} from '../../interfaces/user';
import {Modal} from '../../interfaces/modal';

const Intake = (props: {
  isLoading: boolean,
  isIntakeLoading: boolean,
  userInfo: UserInfo,
  prompt: string,
  update: (value: string) => void,
  submit: (NDA: boolean) => void,
  showModal: (details: Modal) => void
}) => {

  const handleTextAreaChange = (e: any) => {
    props.update(e.target.value);
  }

  // const handleShowNDAModal = () => {
  //   props.showModal({
  //     type: 'nda',
  //     icon: 'contract',
  //     title: `Non-Disclosure Agreement (NDA)`,
  //     message: 'Would you like an NDA for this project?',
  //     callback: (value: boolean) => handleSetNDA(value)
  //   });
  // }

  // const handleSetNDA = (value: boolean) => {
  //   props.submit(value);
  // }

  return (
    <>
    {props.isLoading && (
      <div className="loader"></div>
    )}
    {!props.isLoading && (
      <div className="contentWrap">
        <div className="stepHead">
          <h2>
            <span>Step 1 of 2</span>
            Create A Project Brief
          </h2>
          <p>Describe the client project in as much detail as possible.</p>
        </div>
        <div className="form">
          <textarea placeholder="Describe your project specifications…" onChange={(e) => handleTextAreaChange(e)}></textarea>
          <button className="btn rounded inline" onClick={() => props.submit(false)}>
            <span>Get Started</span>
          </button>
        </div>
      </div>
    )}
    </>
  );
}

export default Intake;
