import {useRef, useState, useEffect, useCallback} from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import Login from './Login/Login';
import Profile from './Account/Profile';
import Header from './Admin/Header';

import Intake from './Intake/Intake';
import AdminIntake from './AdminIntake/AdminIntake';

import Dashboard from './Admin/Dashboard';
import AdminDash from './SuperAdmin/AdminDash';

import EditAccount from './Account/EditAccount';

import ContractListing from './Contract/ContractListing';
import ContractPublicView from './Contract/ContractPublicView';
import ContractEditor from './Contract/ContractEditor';
import ContractProfileView from './Contract/ContractProfileView';

import InvoiceListing from './Payment/InvoiceListing';
import InvoiceProfileView from './Payment/InvoiceProfileView';
import InvoicePublicView from './Payment/InvoicePublicView';
import InvoiceEditor from './Payment/InvoiceEditor';

import AccountSettings from './AccountSettings/AccountSettings';
import ClientListing from './Client/ClientListing';
import ClientEditor from './Client/ClientEditor';
import SingleClient from './Client/SingleClient';

import Projects from './Project/Projects';
import SingleProject from './Project/SingleProject';

import CreateInvoiceWizard from './CreateInvoiceWizard/CreateInvoiceWizard';
import CreateContractWizard from './CreateContractWizard/CreateContractWizard';

import APIUtils from '../utils/APIUtils';
import NavUtils from '../utils/NavUtils';

import {Modal} from '../interfaces/modal';
import MainModal from './General/Modal/Modal';
import VerifyEmail from './VerifyEmail/VerifyEmail';

const App = () => {
  const verifyRef = useRef(false);
  const adminVerifyRef = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userInfo, setUserInfo] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isPublicPage, setIsPublicPage] = useState(false);
  const [isIntakePage, setIsIntakePage] = useState(false);
  const [modalDetails, setModalDetails] = useState<Modal>({
    type: '',
    icon: '',
    title: '',
    message: '',
    redirect: '',
  });

  const handleShowModal = (details: Modal) => {
    setModalDetails(details);
  }

  const handleCloseModal = (redirect: string | undefined) => {
    if (redirect) {
      setTimeout(() => {window.location.href = redirect}, 300);
    }
    return setShowModal(false);
  }

  const intakePageCheck = () => {
    let pageSlugArray = window.location.pathname.split('/').filter(v => v);
    if (pageSlugArray.includes('start-a-project')) {
      setIsIntakePage(true);
    }
  };

  const getPublicPages = () => {
    return [
      '/', 
      'register',
      'reset',
      'verify-email',
      'start-a-project',
    ];
  };
  
  const publicPageCheck = async () => {
    const query = new URLSearchParams(window.location.search);
    const publicPages = getPublicPages();

    let pageSlugArray = window.location.pathname.split('/').slice(1).filter(v => v);

    if (
      query.get('hash') &&
      pageSlugArray.length === 3 &&
      !pageSlugArray.includes('view') && 
      !pageSlugArray.includes('contracts') &&
      !pageSlugArray.includes('invoices')
      ) {
      return true;
    };

    if (!pageSlugArray.length) {
      return true;
    }

    for (const i in publicPages) {
      if (pageSlugArray.includes(publicPages[i])) {
        return true;
      }
    }

    return false;
  };

  const isUserAccountExpired = (response: any) => {
    return typeof response === 'object' &&
      response !== null &&
      response.data.expiredAccount &&
      !window.location.href.includes('/account-settings');
  };
  
  const verifyUserSession = useCallback(async () => {
    if (verifyRef.current) return setIsLoading(false);
    verifyRef.current = true;

    try {
      let response = await APIUtils.callGet('api/account/verify');

      if (typeof response === 'object' && response !== null && response.status === 420 && window.location.pathname !== '/verify-email') {
        return NavUtils.redirectToVerifyEmail();
      }

      if (isUserAccountExpired(response)) {
        return NavUtils.redirectToMyPlan();
      }
      
      let isPublic = await publicPageCheck();

      if (typeof response !== 'object' || response.status !== 200 || typeof response.data !== 'object') {
        if (isPublic) {
          setIsPublicPage(isPublic);
          setIsLoading(false);
          return;
        }

        return NavUtils.redirectToRoot();
      }

      isAdminUser();
      setUserInfo(response.data.userInfo);

      if(['/', '/login', '/register'].includes(window.location.pathname)) {
        return NavUtils.redirectToDashboard();
      }

      setIsLoading(false);
      
    } catch (err) {
      console.log(err);
		};
	}, []);

  const isAdminUser = () => {
    if (adminVerifyRef.current) return setIsLoading(false);
    adminVerifyRef.current = true;
    APIUtils.callGet('api/admin/verify')
		.then(response => {
      setIsAdmin(response.data);
		})
		.catch((err: any) => {
      console.log(err);
		})
    .finally(() => {
      setIsLoading(false);
    });
	};

  const logout = () => {
		APIUtils.callGet('api/account/logout')
		.then(() => {
			NavUtils.redirectToRoot();
		})
		.catch(err => {
      console.log(err);
			NavUtils.redirectToRoot();
		});
	}

  useEffect(() => {
    intakePageCheck();
    verifyUserSession();

  }, []);

  useEffect(() => {
    if (
      modalDetails.type !== ''
      && modalDetails.icon !== ''
      && modalDetails.title !== ''
      && modalDetails.message !== ''
    ) {
      setShowModal(true);
    }
  }, [modalDetails]);

  return (
    <>
    <Router>
      <div className={isIntakePage ? 'intakeWrap' : 'pageWrap'}>
        {
          userInfo &&
          !isPublicPage && (
            <Header 
              isAdmin={isAdmin}
              showModal={(details: Modal) => handleShowModal(details)}
              userInfo={userInfo}
              logout={() => logout()}
            />
          )
        }
        <Routes>
          <Route 
            path="/" 
            element={
              <Login
                isLoading={isLoading}
                showModal={(details: Modal) => handleShowModal(details)}
              />
            } 
          />
          <Route 
            path="/register" 
            element={
              <Login
                isLoading={isLoading}
                isRegister={true}
                showModal={(details: Modal) => handleShowModal(details)}
              />
            } 
          />
          <Route 
            path="/reset/*"
            element={
              <Login
                isLoading={isLoading}
                isReset={true}
                showModal={(details: Modal) => handleShowModal(details)}
              />
            } 
          />
          <Route 
            path="/dashboard" 
            element={
              <Dashboard
                userInfo={userInfo}
                isAdmin={isAdmin}
                isLoading={isLoading}
                showModal={(details: Modal) => handleShowModal(details)}
              />
            } 
          />
          <Route 
            path="/projects" 
            element={
              <Projects
                userInfo={userInfo}
                isLoading={isLoading}
                showModal={(details: Modal) => handleShowModal(details)}
              />
            } 
          />
          <Route 
            path="/projects/:id" 
            element={
              <SingleProject
                userInfo={userInfo}
                isLoading={isLoading}
                showModal={(details: Modal) => handleShowModal(details)}
              />
            } 
          />
          <Route 
            path="/project/create" 
            element={
              <AdminIntake
                userInfo={userInfo}
                isLoading={isLoading}
                showModal={(details: Modal) => handleShowModal(details)}
              />
            } 
          />
          <Route 
            path="/admin" 
            element={
              <AdminDash
                userInfo={userInfo}
                isLoading={isLoading}
              />
            } 
          />
          <Route 
            path="/account-settings" 
            element={
              <AccountSettings 
                userInfo={userInfo}
                isLoading={isLoading}
                showModal={(details: Modal) => handleShowModal(details)}
              />
            } 
          />
          <Route 
            path="/account/edit" 
            element={
              <EditAccount 
                userInfo={userInfo}
                isLoading={isLoading}
                showModal={(details: Modal) => handleShowModal(details)}
              />
            } 
          />
          <Route 
            path="/contracts" 
            element={
              <ContractListing
                userInfo={userInfo}
                isLoading={isLoading}
              />
            } 
          />
          <Route 
            path="/contract/:id"
            element={
              <ContractPublicView 
                userInfo={userInfo}
                isLoading={isLoading}
                showModal={(details: Modal) => handleShowModal(details)}
                isPublic={() => setIsPublicPage(true)}
              />
            } 
          />
          <Route 
            path="/contract/view/:id" 
            element={
              <ContractProfileView
                userInfo={userInfo}
                isLoading={isLoading}
                showModal={(details: Modal) => handleShowModal(details)}
              />
            } 
          />
          <Route 
            path="/contract/edit/:id" 
            element={
              <ContractEditor
                userInfo={userInfo}
                isLoading={isLoading}
                showModal={(details: Modal) => handleShowModal(details)}
              />
            } 
          />
          <Route 
            path="/contract/create" 
            element={
              <CreateContractWizard
                userInfo={userInfo}
                isLoading={isLoading}
                showModal={(details: Modal) => handleShowModal(details)}
              />
            } 
          />
          <Route 
            path="/invoices" 
            element={
              <InvoiceListing
                userInfo={userInfo}
                isLoading={isLoading}
              />
            } 
          />
          <Route 
            path="/:username/invoice/:id" 
            element={
              <InvoicePublicView
                userInfo={userInfo}
                isLoading={isLoading}
                showModal={(details: Modal) => handleShowModal(details)}
                isPublic={() => setIsPublicPage(true)}
              />
            } 
          />
          <Route 
            path="/:username/proposal/:id" 
            element={
              <InvoicePublicView
                userInfo={userInfo}
                isLoading={isLoading}
                showModal={(details: Modal) => handleShowModal(details)}
                isPublic={() => setIsPublicPage(true)}
              />
            } 
          />
          <Route 
            path="/invoice/view/:id" 
            element={
              <InvoiceProfileView
                userInfo={userInfo}
                isLoading={isLoading}
                showModal={(details: Modal) => handleShowModal(details)}
              />
            } 
          />
          <Route 
            path="/invoice/create"
            element={
              <CreateInvoiceWizard
                userInfo={userInfo}
                isLoading={isLoading}
                isProposal={false}
                showModal={(details: Modal) => handleShowModal(details)}
              />
            } 
          />
          <Route
            path="/proposal/create"
            element={
              <InvoiceEditor
                userInfo={userInfo}
                isLoading={isLoading}
                isProposal={true}
                showModal={(details: Modal) => handleShowModal(details)}
              />
            } 
          />
          <Route 
            path="/proposal/edit/:id"
            element={
              <InvoiceEditor
                userInfo={userInfo}
                isLoading={isLoading}
                isProposal={true}
                showModal={(details: Modal) => handleShowModal(details)}
              />
            } 
          />
          <Route 
            path="/invoice/edit/:id"
            element={
              <InvoiceEditor
                userInfo={userInfo}
                isLoading={isLoading}
                isProposal={false}
                showModal={(details: Modal) => handleShowModal(details)}
              />
            } 
          />
          <Route 
            path="/:username" 
            element={
              <Profile 
                isLoading={isLoading}
                isPublic={() => setIsPublicPage(true)}
              />
            } 
          />
          <Route 
            path="/:username/start-a-project" 
            element={
              <Intake 
                isLoading={isLoading}
                showModal={(details: Modal) => handleShowModal(details)}
                isPublic={() => setIsPublicPage(true)}
              />
            } 
          />
          <Route 
            path="/clients" 
            element={
              <ClientListing 
                userInfo={userInfo}
                isLoading={isLoading}
                showModal={(details: Modal) => handleShowModal(details)}
              />
            } 
          />
          <Route 
            path="/client/create" 
            element={
              <ClientEditor
                userInfo={userInfo}
                isLoading={isLoading}
                showModal={(details: Modal) => handleShowModal(details)}
              />
            } 
          />
          <Route 
            path="/client/edit/:id"
            element={
              <ClientEditor
                userInfo={userInfo}
                isLoading={isLoading}
                showModal={(details: Modal) => handleShowModal(details)}
              />
            } 
          />
          <Route 
            path="/client/:id" 
            element={
              <SingleClient
                userInfo={userInfo}
                isLoading={isLoading}
                showModal={(details: Modal) => handleShowModal(details)}
              />
            } 
          />
          <Route 
            path="/verify-email"
            element={
              <VerifyEmail
                showModal={(details: Modal) => handleShowModal(details)}
              />
            } 
          />
        </Routes>
      </div>
    </Router>
    <MainModal 
      modal={modalDetails}
      show={showModal}
      close={(redirect) => handleCloseModal(redirect)}
    />
    </>
  );
}

export default App;
