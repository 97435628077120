import _ from 'lodash';
import {Key, useState, useEffect} from 'react';
import APIUtils from '../../utils/APIUtils';
import NavUtils from '../../utils/NavUtils';

import ProjectListItem from './ProjectListItem';
import ProjectLoader from './ProjectLoader';
import Filters from '../General/Filters';

import {Project} from '../../interfaces/project';
import {Modal} from '../../interfaces/modal';

const Projects = (props: {
  showTitle?: boolean;
  showButton?: boolean;
  username?: string;
  showModal?: (details: Modal) => void;
}) => {

  const [projectLoading, setProjectLoading] = useState<boolean>(true);
  const [projects, setProjects] = useState<Project[]>([]);
  const [filter, setFilter] = useState<string>('');

  const retrieveProjects = async () => {
    try {
      let url = `api/project/all`;

      if (filter.trim().length) {
        url += `?filter=${filter}`
      }

      let response: any = await APIUtils.callGet(url);
      let projects: Project[] = response.data;
      
      setProjects(projects);
      setProjectLoading(false);
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const debounceRetrieveProjects = _.debounce(retrieveProjects, 500);

  const updateSelectedFilterText = (filter: string) => {
    if (filter === 'All') {
      filter = '';
    }
    setFilter(filter.toLowerCase());
  }

  const handleAddProjectBtnClick = () => {
    if (props.showModal) {
      props.showModal({
        type: 'project',
        icon: 'contract',
        title: `Start A New Project`,
        message: 'Choose how you would like to create a new project.',
        callback: (option) => handleProjectCreate(option),
      })
    }
  }

  const handleProjectCreate = (option: string) => {
    if (option === 'link' && props.showModal) {
      return props.showModal({
        type: 'potion',
        icon: 'user',
        title: `Send Potion`,
        message: 'Send a link to your client to complete the brief and your project will be generated automatically.',
        data: {username: props.username},
      })
    }
    if (option === 'manual') {
      return NavUtils.redirectToCreateProject();
    }
  }
  
  useEffect(() => {
    (async () => {
      await debounceRetrieveProjects();
    })();
  }, [filter]);

  return (
    <div id="projectListing">
      {props.showTitle && (
        <div className="metricWrap">
          <div className="headerWrap">
            <h2>Projects</h2>
            {props.showButton &&
              <button className="btn outline rounded icon add" onClick={handleAddProjectBtnClick}>
                <span></span>
                <span></span>
              </button>
            }
          </div>
        </div>
      )}
      <Filters 
        filterValues={['All', 'Active', 'Pending', 'Finished', 'Lost']}
        selectedFilter={filter}
        setSelectedFilter={(filter) => updateSelectedFilterText(filter)}
      />
      <div className="listing">
        {projectLoading && (
          <ProjectLoader />
        )}
        {!projectLoading && projects &&
          <>
            {
              projects.length > 0 ? 
              projects.map((item: Project, i: Key) => {
                return (
                  <ProjectListItem 
                    key={`project-${i}`}
                    project={item}
                  />
                ) 
              })
              :
              <p className="placeholder">No projects found.</p>
            }
          </>
        }
      </div>
    </div>
  );
}

export default Projects;
