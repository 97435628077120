import React, {useState, useEffect, useRef} from 'react';
import APIUtils from '../../utils/APIUtils';
import UserUtils from '../../utils/UserUtils';
import Header from '../Admin/Header';
import DashHead from '../Admin/DashHead';
import RecentActivity from '../Admin/RecentActivity';
import StripeConnectCTA from './StripeConnectCTA';
import {UserInfo} from '../../interfaces/user';
import {Modal} from '../../interfaces/modal';
import PhoneNumberInput from '@bebeau/phone-number-input';
import AddressInput from '../General/AddressInput';
import {ReactComponent as CopyIcon} from '../../assets/img/copy.svg';
import {ReactComponent as PreviewIcon} from '../../assets/img/preview.svg';

const EditAccount = (props: {
  userInfo: UserInfo | undefined,
  isLoading: boolean,
  showModal: (details: Modal) => void;
}) => {
  const unitRef = useRef<HTMLInputElement | null>(null);
  const [copied, setCopied] = useState<boolean>(false);
  const [isSaving, setisSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isActiveCardPayments, setIsActiveForCardPayments] = useState(false);
  const [updatedUserInfo, setUpdatedUserInfo] = useState<UserInfo>(UserUtils.getEmptyUserInfo());

  const updateAccount = () => {
    if(typeof props.userInfo !== 'undefined') {
      setisSaving(true);
      let payload = Object.fromEntries(Object.entries(updatedUserInfo).filter(([_, v]) => v.length !== 0));

      APIUtils.callPost(`api/account/update/${props.userInfo.id}`, payload)
      .then(response => {
        if (response.status !== 200) {
          return props.showModal({
            type: 'error',
            icon: 'user',
            title: `Updating Account`,
            message: response.message,
            redirect: '',
          })
        }
        setUpdatedUserInfo(response.data);
        if(typeof props.userInfo !== 'undefined') {
          return props.showModal({
            type: 'success',
            icon: 'user',
            title: `@${props.userInfo.username}`,
            message: 'Account updated.',
            redirect: '',
          })
        }
      })
      .catch((err) => {
        console.error(err);
        if(typeof props.userInfo !== 'undefined') {
          return props.showModal({
            type: 'error',
            icon: 'user',
            title: `Updating Account`,
            message: err.message,
            redirect: '',
          })
        }
      })
      .finally(() => setisSaving(false));
    }
  };

  const deleteAccount = () => {
    if(typeof props.userInfo !== 'undefined') {
      setIsDeleting(true);
      APIUtils.callDelete(`api/account/delete/${props.userInfo.id}`, updatedUserInfo)
      .then(response => {
        if(response.status !== 200) {
          return props.showModal({
            type: 'error',
            icon: 'user',
            title: `Deleting Account`,
            message: response.message,
            redirect: '',
          });
        }
        if(typeof props.userInfo !== 'undefined') {
          return props.showModal({
            type: 'success',
            icon: 'user',
            title: `@${props.userInfo.username}`,
            message: 'Account deleted.',
            redirect: '/',
          });
        }
      })
      .catch((err) => {
        console.error(err);
        return props.showModal({
          type: 'error',
          icon: 'user',
          title: `Deleting Account`,
          message: err.message,
          redirect: '',
        });
      })
      .finally(() => setIsDeleting(false));
    }
  };

  const handleChange = (e: any, key: string) => {
    e.preventDefault();
    let user: any = {...updatedUserInfo};
    user[key as keyof typeof user] = e.target.value;
    setUpdatedUserInfo(user);
  };
  
  const handlePhoneChange = (value: string) => {
    let user: any = {...updatedUserInfo};
    user['phone' as keyof typeof user] = value;
    setUpdatedUserInfo(user);
  }

  const handleAddressSelect = async (address: any) => {
    let user: any = {...updatedUserInfo};
    user['address'] = address.address;
    user['city'] = address.city;
    user['state'] = address.state;
    user['zip'] = address.zip;
    // user['country'] = address.country;
    setUpdatedUserInfo(user);
    if (unitRef.current) {
      unitRef.current.focus();
    }
  }

  const checkAccountEligibility = (stripeconnectid: string) => {
    APIUtils.callGet(`api/payment/eligibility/${stripeconnectid}`)
    .then(response => {
      if (response.data !== true) {
        return setIsActiveForCardPayments(false);
      }
      return setIsActiveForCardPayments(true);
    })
    .catch((err) => {
      return console.error(err);
    });
  };

  const copyProfileUrl = () => {
    if(props.userInfo) {
      navigator.clipboard.writeText(window.location.origin + '/' + props.userInfo.username);
      setCopied(true);
      setTimeout(() => setCopied(false), 500);
    }
  };

  useEffect(() => {
    if(props.userInfo) {
      setUpdatedUserInfo(props.userInfo);
      checkAccountEligibility(props.userInfo.stripeconnectid);
    }
  }, [props.userInfo]);

  return (
    <>
    {props.isLoading && (
      <div className="loader"></div>
    )}
    {!props.isLoading && props.userInfo && (
      <div className="componentWrap">
          <DashHead
            pageTitle="Account"
            userInfo={props.userInfo}
          />
          <div className="pageContent">
              <div id="editProfile">
                  <h2>Edit</h2>
                  <div className="box">

                    <div className="btnWrap top">
                      <div className="btnGroup left">
                        <a className="btn rounded outline" href="/account">
                          Back
                        </a>
                      </div>
                      <div className="btnGroup right">
                        <button className={`btn rounded outline icon ${copied ? 'copied' : ''}`} onClick={copyProfileUrl}>
                          <CopyIcon />
                        </button>
                        <a className="btn rounded outline icon preview" href={`/${props.userInfo.username}`} target='_blank'>
                          <PreviewIcon />
                        </a>
                      </div>
                    </div>

                      <div className='fieldWrap'>
                        <h4>Personal</h4>

                        <h5>Account</h5>
                        <div className="usernameInput">
                          <input placeholder="Username" value={updatedUserInfo.username} onChange={(e) => {handleChange(e, 'username')}}></input>
                        </div>
                        <h5>Contact</h5>
                        <div className="split">
                          <input placeholder="First Name" value={updatedUserInfo.firstname} onChange={(e) => {handleChange(e, 'firstname')}}></input>
                          <input placeholder="Last Name" value={updatedUserInfo.lastname} onChange={(e) => {handleChange(e, 'lastname')}}></input>
                        </div>
                        <div className="split">
                          <input placeholder="Email" value={updatedUserInfo.email} onChange={(e) => {handleChange(e, 'email')}}></input>
                          <PhoneNumberInput 
                            onInputChange={(data: {
                              number: string,
                              isValid: boolean,
                            }) => handlePhoneChange(data.number)}
                            value={props.userInfo.phone}
                            placeholder='Phone Number'
                          />
                        </div>

                        <h5>Address</h5>
                        <div className="split address">
                          {/* <input placeholder="Street" value={updatedUserInfo.address} onChange={(e) => {handleChange(e, 'address')}}></input> */}
                          <AddressInput 
                            updateAddress={(address) => handleAddressSelect(address)}
                            defaultValue={updatedUserInfo.address}
                            placeholder='Address'
                          />
                          <input ref={unitRef} placeholder="Unit" value={updatedUserInfo.unit} onChange={(e) => {handleChange(e, 'unit')}}></input>
                        </div>
                        <div className="split">
                          <input placeholder="City" value={updatedUserInfo.city} onChange={(e) => {handleChange(e, 'city')}}></input>
                          <input placeholder="State" value={updatedUserInfo.state} onChange={(e) => {handleChange(e, 'state')}}></input>
                          <input placeholder="Zip" value={updatedUserInfo.zip} onChange={(e) => {handleChange(e, 'zip')}}></input>
                        </div>

                      </div>
                      <div className='fieldWrap'>
                        <h4>Payments</h4>
                        <h5>Credit/Debit</h5>
                        <StripeConnectCTA 
                          isConnected={isActiveCardPayments}
                          stripeConnectID={props.userInfo.stripeconnectid}
                        />
                        <h5>Bank Info</h5>
                        <div className="split">
                          <input placeholder="Routing #" value={updatedUserInfo.bankrouting} onChange={(e) => {handleChange(e, 'bankrouting')}}></input>
                          <input placeholder="Account #" value={updatedUserInfo.bankaccount} onChange={(e) => {handleChange(e, 'bankaccount')}}></input>
                        </div>
                        <h5>Paypal</h5>
                        <input placeholder="PayPal" value={updatedUserInfo.paypal} onChange={(e) => {handleChange(e, 'paypal')}}></input>
                      </div>
                      <div className="btnWrap">
                        <button className="btn rounded" onClick={updateAccount}>
                          {isSaving && (
                            <span><div className="loader"></div></span>
                          )}
                          {!isSaving && (
                            <span>Save</span>
                          )}
                        </button>
                        <div className="or darker"><span>or</span></div>
                        <button className="btn outline rounded delete" onClick={deleteAccount}>
                          {isDeleting && (
                            <span><div className="loader"></div></span>
                          )}
                          {!isDeleting && (
                            <span>Delete</span>
                          )}
                        </button>
                      </div>
                  </div>
              </div>
              <RecentActivity 
                userID={props.userInfo.id}
              />
          </div>
      </div>
    )}
    </>
  );
}

export default EditAccount;
