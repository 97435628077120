import {useState, useEffect} from 'react';

import APIUtils from '../../utils/APIUtils';
import NavUtils from '../../utils/NavUtils';

import DashHead from'../Admin/DashHead';
import CreateContractStepOne from './CreateContractStepOne';
import CreateContractStepTwo from './CreateContractStepTwo';

import {UserInfo} from '../../interfaces/user';
import {Modal} from '../../interfaces/modal';
import {Contract} from '../../interfaces/contract';

const CreateContractWizard = (props: {
  userInfo: UserInfo | undefined,
  isLoading: boolean,
  showModal: (details: Modal) => void
}) => {
  const [contract, setContract] = useState<Contract>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);

  const [selectedClientId, setSelectedClientId] = useState<any>(-1);
  const [selectedContactId, setSelectedContactId] = useState<any>(-1);
  const [selectedProjectId, setSelectedProjectId] = useState<any>(-1);

  const updateField = (value: number | string | Date, key: string) => {
    let contractInfo: any = {...contract};
    contractInfo[key] = value;

    setContract(contractInfo);
  };

  const createContract = async () => {
    setIsLoading(true);

    let payload = {...contract};
    payload.contactid = selectedContactId;
    payload.projectid = selectedProjectId;

    console.log('FRONT-END PAYLOAD: ', payload);

    let response = await APIUtils.callPut('api/contract/create', payload);
    if (response.status === 200) {
      setIsLoading(false);
      return props.showModal({
        type: 'success',
        icon: 'contract',
        title: `Contract Created`,
        message: `Your contract has been successfully created. Upon closing this modal, you will be redirected to sign your contract.`,
        redirect: `/contract/${response.data.id}?hash=${response.data.hash}`,
      });
    }
    setIsLoading(false);
    return props.showModal({
      type: 'error',
      icon: 'contract',
      title: `Creating Contract`,
      message: response.message,
      redirect: '',
    });
  };

  const handleBackButtonPress = () => {
    if (step > 1) {
      return setStep(step - 1);
    }
    const search = window.location.search.substring(1);
    const params = search ? JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}') : {};

    if (params && params?.source === 'dashboard') {
      return NavUtils.redirectToDashboard();
    }
    
    NavUtils.redirectToContracts();
  }

  useEffect(() => {
    if (props.userInfo === undefined) return;

    if (props.userInfo.id) {
      updateField(props.userInfo.id, 'userid');
    }
  }, [props.userInfo]);

  return (
    <div className="componentWrap" id="contractEditor">
      {props.userInfo &&
        <>
          <DashHead
            pageTitle={`Create New Contract`}
            userInfo={props.userInfo}
            back={handleBackButtonPress}
          />
          { step === 1 && (
            <>
              <div className="stepHead">
                <h2>
                  <span>Step 1 of 2</span>
                  Select Contract Details
                </h2>
                <p>Assign or create a client, contact person, and project for this contract.</p>
              </div>
              <CreateContractStepOne
                userInfo={props.userInfo}
                contract={contract}
                selectedClientId={selectedClientId}
                setSelectedClientId={setSelectedClientId}
                selectedContactId={selectedContactId}
                setSelectedContactId={setSelectedContactId}
                selectedProjectId={selectedProjectId}
                setSelectedProjectId={setSelectedProjectId}
                updateContractField={(value, field) => updateField(value, field)}
                changeStep={() => setStep(2)}
                showModal={(details) => props.showModal(details)}
              />
            </>
          )}
          { step === 2 && (
            <>
              <div className="stepHead">
                <h2>
                  <span>Step 2 of 2</span>
                  Modify Contract Template
                </h2>
                <p>Review and modify the contract verbiage.</p>
              </div>
              <CreateContractStepTwo
                contract={contract}
                selectedContactId={selectedContactId}
                selectedProjectId={selectedProjectId}
                userInfo={props.userInfo}
                updateContractField={(value: number | string | Date, field: string) => updateField(value, field)}
                submit={createContract}
              />
            </>
          )}
        </>
      }
    </div>
  )
}

export default CreateContractWizard;