import _ from 'lodash';
import {useEffect, useState} from 'react';

import APIUtils from '../../utils/APIUtils';
import NavUtils from '../../utils/NavUtils';
import PaymentUtils from '../../utils/PaymentUtils';
import BankInfo from "../General/BankInfo";
import InvoiceViewItem from './PaymentViewItem';

import {Invoice} from '../../interfaces/invoice';
import {Modal} from '../../interfaces/modal';
import {UserInfo} from '../../interfaces/user';

import {ReactComponent as Logo} from '../../assets/img/logo.svg';

const PaymentPublicView = (props: {
  userInfo: UserInfo | undefined;
  isLoading: boolean;
  showModal: (details: Modal) => void,
  isPublic?: Function;
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [invoice, setInvoice] = useState<Invoice>({
    id: 0,
    userid: 0,
    clientid: '',
    contactid: '',
    billTo: '',
    description: '',
    discount: '0',
    tax: 0.0,
    total: '0.0',
    notes: '',
    createdat: '',
    updatedat: '',
    issuedat: '',
    dueat: '',
    hash: '',
    subtotal: 0,
    discountAmount: 0,
    taxAmount: 0,
    items: [],
    subject: '',
    isproposal: false,
    read_only: true,
    approvedDate: null,
    declinedDate: null,
  });
  const [createdDate, setCreatedDate] = useState({
    day: '',
    month: '',
    year: '',
  });
  const [issuedDate, setIssuedDate] = useState({
    day: '',
    month: '',
    year: '',
  });
  const [dueDate, setDueDate] = useState({
    day: '',
    month: '',
    year: '',
  });
  const [userInfo, setUserInfo] = useState({
    id: -1,
    username: '',
    firstname: '',
    lastname: '',
    address: '',
    unit: '',
    city: '',
    state: '',
    zip: '',
    bankrouting: '',
    bankaccount: '',
    stripeconnectid: '',
  });
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const [isActiveCardPayments, setIsActiveForCardPayments] = useState(false);
  const [hasBankInfo, setHasBankInfo] = useState(false);

  const fetchInvoice = _.debounce((id: string) => {
    APIUtils.callGet(`api/invoice/${id}`)
    .then(response => {
      if (response.status === 200) {
        setInvoice(response.data);
        setIsLoading(false);
      }
    })
    .catch((err) => {
      return console.error(err);
    });
  }, 300);

  const fetchUser = _.debounce((username: string) => {
    APIUtils.callGet(`api/account/profile/${username}`)
    .then(response => {
      setUserInfo(response.data.userInfo);
    })
    .catch((err) => {
      return console.error(err);
    });
  }, 300);

  const checkAccountEligibility = _.debounce((stripeconnectid: string) => {
    APIUtils.callGet(`api/payment/eligibility/${stripeconnectid}`)
    .then(response => {
      if (response.data !== true) {
        return setIsActiveForCardPayments(false);
      }
      return setIsActiveForCardPayments(true);
    })
    .catch((err) => {
      return console.error(err);
    });
  }, 300);

  const getInvoiceIdFromQueryParams = () => {
    return window.location.href.split("/").pop();
  };

  const getUsernameFromQueryParams = () => {
    return window.location.pathname.split("/")[1];
  };

  const getSubtotal = () => {
    let total = 0.0;

    invoice.items.forEach((lItem: any)  => {
        let qty = parseInt(lItem.qty);
        let price = parseFloat(lItem.price);

        total += (qty * price);
    });

    return total.toFixed(2);
  };

  // const getTaxTotal = () => {
  //   if (!invoice.items.length) {
  //       let total = 0.0;
  //       return total.toFixed(2);
  //   }
  //   if(typeof invoice.tax === 'undefined') {
  //     return 0;
  //   }
  //   let total: any = getSubtotal();
  //   let tax = total * parseFloat(Number(invoice.tax / 100).toString());

  //   return tax.toFixed(2);
  // };

  const getDiscountTotal = () => {
    if (!invoice.items.length) {
        let total = 0.0;
        return total.toFixed(2);
    }
    if(typeof invoice.discount === 'undefined') {
      return 0;
    }
    let total: any = getSubtotal();
    let discount = total * Number(parseInt(invoice.discount.toString()) / 100);
    return discount.toFixed(2);
  };

  const approveProposal = async () => {
    await PaymentUtils.approveProposal(invoice, props.showModal);
  };

  const declineProposal = async () => {
    await PaymentUtils.declineProposal(invoice, props.showModal);
  };

  useEffect(() => {
    if (!userInfo) return;

    if (!_.isEmpty(userInfo?.bankrouting)) {
      setHasBankInfo(true);
    }
    if(userInfo?.stripeconnectid) {
      checkAccountEligibility(userInfo.stripeconnectid);
    }
  }, [userInfo]);

  useEffect(() => {
    let invoiceId = getInvoiceIdFromQueryParams();
    if (typeof invoiceId === 'string' && invoiceId.length) {
      fetchInvoice(invoiceId);
    }

    let userId = getUsernameFromQueryParams();
    if (typeof userId === 'string' && userId.length) {
      fetchUser(userId);
    }
  }, []);

  useEffect(() => {
    if (invoice.createdat) {
      const created = new Date(invoice.createdat);
      const createdDate = {
        day: created.getDate().toString(),
        month: (created.getMonth() + 1).toString(),
        year: created.getFullYear().toString(),
      }
      setCreatedDate(createdDate);
    }
    if(invoice.issuedat) {
      const issued = new Date(invoice.issuedat);
      const issuedDate = {
        day: issued.getDate().toString(),
        month: (issued.getMonth() + 1).toString(),
        year: issued.getFullYear().toString(),
      }
      setIssuedDate(issuedDate);
    }
    if(invoice.dueat) {
      const due = new Date(invoice.dueat);
      const dueDate = {
        day: due.getDate().toString(),
        month: (due.getMonth() + 1).toString(),
        year: due.getFullYear().toString(),
      }
      setDueDate(dueDate);
    }
  }, [invoice]);

  useEffect(() => {
    if (props.isPublic) {
      props.isPublic();
    }
    // if (props.userInfo?.id === userInfo?.id) {
    //   setIsOwner(true);
    // }
  }, [props]);

  return (
    <>
    {isLoading && (
      <div className="loader"></div>
    )}
    {!isLoading && (
      <div id="viewInvoice" className="componentWrap publicView">

        {/* {isOwner && (
          <button className="btn rounded outline green listingBtn" onClick={() => NavUtils.redirectToInvoices()}>
            View All Invoices
          </button>
        )} */}
        
        <div className="box">
          <div className="logo">
            <Logo />
          </div>
          <div className="split">

            {!invoice.isproposal && (
              <div className="fieldWrap">
                <div>
                  <div className="label">Issue Date</div>
                  <div className="value">{issuedDate.month}/{issuedDate.day}/{issuedDate.year}</div>
                </div>
                <div>
                  <div className="label">Due Date</div>
                  <div className="value">{dueDate.month}/{dueDate.day}/{dueDate.year}</div>
                </div>
                {invoice.billTo && (
                  <div>
                    <div className="label">For</div>
                    <address>{invoice.billTo}</address>
                  </div>
                )}
              </div>
            )}

            {invoice.isproposal && (
              <div className="fieldWrap">
                <div className="label">Date Created</div>
                <div className="value">
                  {createdDate.month}/{createdDate.day}/{createdDate.year}
                </div>
              </div>
            )}

            <div className="fieldWrap">
              <div>
                <div className="label">From</div>
                {
                  userInfo !== undefined && (
                    <>
                    {
                      userInfo.username && (
                        <a href={`${window.location.origin}/${userInfo.username}`} target="_blank" rel="noreferrer">
                          @{userInfo.username}
                        </a>
                      )
                    }
                    {
                      userInfo.firstname &&
                      userInfo.lastname && (
                        <>
                          {userInfo.firstname} {userInfo.lastname}
                        </>
                      )
                    }
                      {
                      userInfo.address &&
                      userInfo.city && 
                      userInfo.state &&
                      userInfo.zip && (
                        <address>
                          {userInfo.address}
                          <br />
                          {userInfo.city} {userInfo.state}, {userInfo.zip}
                        </address>
                      )
                    }
                    </>
                  )
                }
                
              </div>
            </div>
          
          </div>

          {typeof invoice.subject === 'string' && invoice.subject.length && (
            <div className="fieldWrap">
              <div className="label">Subject</div>
              {invoice.subject}
            </div>
          )}

          <div className="fieldWrap items">
            <div className="label">Items</div>
            <div className="itemsWrap">
              {invoice.items.map((item: any, i: number) => {
                return (
                  <InvoiceViewItem 
                    key={i}
                    item={item}
                    isProposal={invoice.isproposal}
                  />
                )
              })}
            </div>
          </div>

          {invoice.notes && (
            <div className="fieldWrap">
              <div className="label">Notes / Payment Terms</div>
              {invoice.notes}
            </div>
          )}
          
          <div className="fieldWrap invoiceSummary">
            <div className="lineItem">
              <span>Subtotal</span>
              <span>{PaymentUtils.formatPrice(getSubtotal())}</span>
            </div>
            {invoice.discount !== '0' && (
              <div className="lineItem discount">
                <span>Discount</span>
                <span className="value">- {PaymentUtils.formatPrice(getDiscountTotal())}</span>
              </div>
            )}
          </div>
          
          <div className="fieldWrap paymentTotal pb-20">
            <div className="label">Total</div>
            <div className="price">
              {PaymentUtils.formatPrice(invoice.total)}
            </div>
          </div>
              
          <div className="btnWrap">
            {invoice.read_only && invoice.isproposal && (!invoice.approvedDate && !invoice.declinedDate) &&
              <div className="btnWrap">
                <button className="btn rounded" onClick={approveProposal}>
                  <span>Approve</span>
                </button>
                <div className="or darker"><span>or</span></div>
                <button className="btn rounded outline delete" onClick={declineProposal}>
                  <span>Decline</span>
                </button>
              </div>
            }
            {invoice.read_only && invoice.isproposal && invoice.approvedDate &&
              <div className="status green"><span>Approved</span></div>
            }
            {invoice.read_only && invoice.isproposal && invoice.declinedDate &&
              <div className="status red"><span>Declined</span></div>
            }
            {isActiveCardPayments && !invoice.isproposal && (
              <button className="btn rounded">
                <span>Pay Online</span>
              </button>
            )}
            {isActiveCardPayments && hasBankInfo && !invoice.isproposal && (
              <span className="or">
                <span>or</span>
              </span>
            )}
          </div>

          {hasBankInfo && !invoice.isproposal && (
            <BankInfo 
              routing={userInfo?.bankrouting ? userInfo.bankrouting : ''}
              account={userInfo?.bankaccount ? userInfo.bankaccount : ''}
            />
          )}

        </div>
      </div>
    )}
    </>
  );
}

export default PaymentPublicView;
