import {useState, useEffect} from 'react';
import APIUtils from '../../utils/APIUtils';
import DateUtils from '../../utils/DateUtils';
import {UserInfo} from '../../interfaces/user';

import {Modal} from '../../interfaces/modal';

const MyPlan = (props: {
  userInfo: UserInfo | undefined,
  showModal: (detials: Modal) => void;
}) => {
  const [planType, setPlanType] = useState('Free Trial');
  const [isTrial, setIsTrial] = useState<boolean>(true);
  const [premiumStartDate, setPremiumStartDate] = useState<String>('');
  const [daysLeft, setTrialDaysLeft] = useState<string>('7 days');

  const calculateDaysRemainingOnTrial = () => {
    if (!props.userInfo || !props.userInfo.trialExpiryDate) { return 0; }

    let today = new Date();
    let trialExpiryDate = new Date(props.userInfo.trialExpiryDate);

    if (today > trialExpiryDate) {
      return 0;
    }

    var timeBetweenDates = trialExpiryDate.getTime() - today.getTime();
    var daysBetweenDates = Math.floor(timeBetweenDates / (1000 * 3600 * 24));

    return daysBetweenDates;
  };

  
  const getDaysLeftText = () => {
    let remainingDays = calculateDaysRemainingOnTrial();

    if (remainingDays === 1) {
      return '1 day';
    }

    return `${remainingDays} days`;
  };

  const activatePremium = async () => {
    let response = await APIUtils.callPost('billing/create-checkout-session');
    if (response.status === 200) {
      return window.location = response.data.url;
    }

    return props.showModal({
      type: 'error',
      icon: 'user',
      title: `Activating Subscription`,
      message: 'Unable to activate subscription!',
      redirect: '',
    });
  }

  const deactivateAccount = async () => {
    let response = await APIUtils.callGet('billing/deactivate_subscription');

    if (response.status !== 200) {
      return props.showModal({
        type: 'error',
        icon: 'user',
        title: `Deactivating Subscription`,
        message: 'Unable to deactivate subscription!',
        redirect: '',
      });
    }

    props.showModal({
      type: 'success',
      icon: 'user',
      title: `Deactivating Subscription`,
      message: 'Your subscription has been deactivated!',
      redirect: '/account-settings?tab=plan',
    });
  };

  useEffect(() => {
    if (!props.userInfo) { return; }

    let isTrial = !!(typeof props.userInfo.premiumStartDate === 'string' && !props.userInfo.premiumStartDate.length);
    setIsTrial(isTrial);

    setPremiumStartDate(props.userInfo.premiumStartDate);
    setTrialDaysLeft(getDaysLeftText());
  }, [props.userInfo]);

  return (
    <div id="profilePlan">
      <div className="details">
        <h2>You're Using {planType}</h2>
        {isTrial && (
          <p>Your free trial expires after {daysLeft}. Subscribe and enjoy the unlimited features of our platform.</p>
        )}
      </div>
      <div id="tiers">
        <div className="tier">
          <h3>TRIAL</h3>
          <div className="price">
            Free
          </div>
          <div className="featureList">
            <div className="feature">
              <span className="bullet"></span>
              Unlimited access to the platform for 7 days.
            </div>
            <div className="feature">
              <span className="bullet"></span>
              Like what you see? Go premium for unlimited access to the platform.
            </div>
          </div>
          { !isTrial &&  <button className="btn rounded disabled"><span>Trial Period Has Ended</span></button> }
          { isTrial &&  <button className="btn link green disabled">{daysLeft} Left</button> }
        </div>
        <div className="tier">
          <h3>PREMIUM</h3>
          <div className="price">
            <span className="ticker">$</span>25<span className="permonth">/per month</span>
          </div>
          <div className="featureList">
            <div className="feature">
              <span className="bullet"></span>
              Manage all your projects in one platform.
            </div>
            <div className="feature">
              <span className="bullet"></span>
              Unlimited access to everything for one flat fee. No hidden fees, no suprises.
            </div>
            <div className="feature">
              <span className="bullet"></span>
              Easily cancel at anytime.
            </div>
            <div className="feature">
              <span className="bullet"></span>
              Try it for free for 7 days before you have to give us any payment info.
            </div>
          </div>
          { isTrial &&  <button className="btn rounded" onClick={activatePremium}><span>Go Premium</span></button> }
          { !isTrial &&  <button className="btn rounded"><span>Active Since {DateUtils.getPrettyDate(premiumStartDate)}</span></button> }
          { !isTrial &&  <button className="btn outline rounded delete" onClick={deactivateAccount}><span>Deactivate Subscription</span></button> }
        </div>
      </div> 
    </div>
  );
}

export default MyPlan;