import {Key, useState, useEffect} from 'react';

import APIUtils from '../../../utils/APIUtils'
import UserPotionLink from '../../Admin/UserPotionLink';

import {Modal} from '../../../interfaces/modal';

const PotionLinkModal = (props: {
  modal: Modal;
  close: () => void;
}) => {
  const [sendLoading, setSendLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  const handleSendEmail = async (data: object) => {
    setSendLoading(true);
    let url = 'api/send_email/potion_link';
    props.modal.data.email = email;
    let response = await APIUtils.callPost(url, data);
    if (props.modal.callback) {
      props.modal.callback(response);
    }
    props.close();
    setSendLoading(false);
  }

  useEffect(() => {
    if (props.modal.data.email) {
      setEmail(props.modal.data.email);
    }
  }, [props.modal]);

  return (
    <>
    <div className="modalCopy">
      <h3>{props.modal.title}</h3>
      <div className="message">
        {props.modal.message}
      </div>
      <UserPotionLink 
        username={props.modal.data.username}
      />
      <div className="or">
        <span>or</span>
      </div>
      <div className="fieldWrap">
        <h5>Send To Email</h5>
        <input type="email" value={email} placeholder="email@address..." onChange={(e) => setEmail(e.target.value)} />
      </div>
    </div>
    <button className="btn rounded" onClick={() => handleSendEmail(props.modal.data)}>
      {sendLoading && (
        <span><div className="loader"></div></span>
      )}
      {!sendLoading && (
        <span>Send</span>
      )}
    </button>
    </>
  )
}

export default PotionLinkModal;