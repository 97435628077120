import {useRef, useState, useEffect} from 'react';

import APIUtils from '../../utils/APIUtils';

import PhoneNumberInput from '@bebeau/phone-number-input';
import AddressInput from '../General/AddressInput';

import {UserInfo} from '../../interfaces/user';

import {ReactComponent as IndividualIcon} from '../../assets/img/individual.svg';
import {ReactComponent as CameraIcon} from '../../assets/img/icons/camera.svg';

const ProfileSettings = (props: {
    userInfo: UserInfo | undefined,
    showModal: Function,
    updateUserInfo: Function,
    setUpdatedUserInfo: Function,
}) => {
  const unitRef = useRef<HTMLInputElement | null>(null);
  const [profileImage, setProfileImage] = useState<any>(null);
  const [imageBlob, setImageBlob] = useState<any>(null);
  const [imageType, setImageType] = useState<String>('');

  const [defaultPhone, setDefaultPhone] = useState<string>('');

  const updateAccount = async () => {
    if(typeof props.userInfo !== 'undefined') {
      let payload = Object.fromEntries(Object.entries(props.userInfo).filter(([_, v]) => v.length !== 0));

      if (!props.userInfo.address) {
        payload.address = '';
        payload.unit = '';
        payload.city = '';
        payload.state = '';
        payload.zip = '';
      }

      delete payload.email;
      delete payload.trialExpiryDate;
      delete payload.premiumStartDate;
      delete payload.premiumSubId;
      if (imageBlob) {
        payload.image = imageBlob;
        payload.image_type = imageType;
      }

      let response: any = await APIUtils.callPost(`api/account/update/${props.userInfo.id}`, payload);
      if (response.status !== 200) {
        return props.showModal({
          type: 'error',
          icon: 'user',
          title: `Updating Account`,
          message: response.message,
          redirect: '',
        });
      }
      
      props.setUpdatedUserInfo(response.data);
      props.showModal({
        type: 'success',
        icon: 'user',
        title: `@${props.userInfo.username}`,
        message: 'Account updated.',
        redirect: '',
      });
    }
  }

  const handleAddressSelect = async (address: any) => {
    let user: any = {...props.userInfo};
    user['address'] = address.address;
    user['city'] = address.city;
    user['state'] = address.state;
    user['zip'] = address.zip;
    // user['country'] = address.country;
    props.setUpdatedUserInfo(user);
    if (unitRef.current && address.address) {
      unitRef.current.focus();
    }
  }

  const handleImageUploadClick = () => {
    props.showModal({
      type: 'image',
      icon: 'user',
      title: `Upload Profile Image`,
      message: 'Click or drag and drop an image in box below to upload a profile image.',
      callback: (imageBlob: any) => handleImageUpload(imageBlob)
    });
  }

  const blobToBase64 = (blob: any) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const handleImageUpload = async (imageBlob: any) => {
    setImageType(imageBlob.type);
    setProfileImage(URL.createObjectURL(imageBlob));

    let data = await blobToBase64(imageBlob);

    setImageBlob(data);
  }

  useEffect(() => {
    if (props.userInfo?.phone && !defaultPhone) {
      setDefaultPhone(props.userInfo.phone);
    }
  }, [props.userInfo, defaultPhone])
        
  return (
    <div id="profileSettings">

      <div id="user">
        <div className="image" style={{backgroundImage: props.userInfo?.image !== undefined && !profileImage ? `url(${props.userInfo?.image})` : `url(${profileImage})`}} onClick={() => handleImageUploadClick()}>
          {!props.userInfo?.image && !profileImage && (
            <IndividualIcon />
          )}
          <button className="btn rounded outline icon" onClick={() => handleImageUploadClick()}>
            <CameraIcon />
          </button>
        </div>
        <div>
          <div className="name">{props.userInfo?.firstname} {props.userInfo?.lastname}</div>
          <div className="username">@{props.userInfo?.username}</div>
        </div>
      </div>

      <div className="split">

        <div id="details">
          <h5>Personal Details</h5>
          <div className="fieldWrap">
            <div className="label">First Name</div>
            <input onChange={(e: any) => { props.updateUserInfo('firstname', e.target.value); }} value={props.userInfo?.firstname ? props.userInfo?.firstname : ''} placeholder="First Name" />
          </div>
          <div className="fieldWrap">
            <div className="label">Last Name</div>
            <input onChange={(e: any) => { props.updateUserInfo('lastname', e.target.value); }} value={props.userInfo?.lastname ? props.userInfo?.lastname : ''} placeholder="Last Name" />
          </div>
          <div className="fieldWrap">
            <div className="label">Email</div>
            <input type="email" value={props.userInfo?.email ? props.userInfo?.email : ''} disabled />
          </div>
          <div className="fieldWrap">
            <div className="label">Phone Number</div>
            <PhoneNumberInput 
              onInputChange={(data: {
                number: string,
                isValid: boolean,
              }) => props.updateUserInfo('phone', data.number)}
              value={defaultPhone}
              placeholder='Phone Number'
            />
          </div>
        </div>

        <div id="billing">
          <h5>Billing Address</h5>
          <div className="fieldWrap">
            <div className="label">Country</div>
            <input value="United States" disabled />
          </div>
          <div className="fieldWrap">
            <div className="split">
              <div className="address">
                <div className="label">Address</div>
                <AddressInput 
                  updateAddress={(address) => handleAddressSelect(address)}
                  defaultValue={props.userInfo?.address ? props.userInfo?.address : ''}
                  placeholder='Address'
                />
              </div>
              <div className="unit">
                <div className="label">Apt/Suite/Unit</div>
                <input ref={unitRef} placeholder="Unit" value={props.userInfo?.unit ? props.userInfo?.unit : ''} onChange={(e) => {props.updateUserInfo('unit', e.target.value)}} />
              </div>
            </div>
          </div>
          <div className="fieldWrap">
            <div className="label">City</div>
            <input onChange={(e: any) => { props.updateUserInfo('city', e.target.value); }} value={props.userInfo?.city ? props.userInfo?.city : ''} placeholder="City" />
          </div>
          <div className="fieldWrap">
            <div className="split">
              <div>
                <div className="label">State</div>
                <input onChange={(e: any) => { props.updateUserInfo('state', e.target.value); }} value={props.userInfo?.state ? props.userInfo?.state : ''} placeholder="State" />
              </div>
              <div>
                <div className="label">Zip</div>
                <input onChange={(e: any) => { props.updateUserInfo('zip', e.target.value); }} value={props.userInfo?.zip ? props.userInfo?.zip : ''} placeholder="Zip" />
              </div>
            </div>
          </div>
        </div>

      </div>

      <div id="banking">
        <h5>Banking Info</h5>
        <div className="split">
          <div className="fieldWrap">
            <div className="label">Account #</div>
            <input onChange={(e: any) => { props.updateUserInfo('bankaccount', e.target.value); }} value={props.userInfo?.bankaccount ? props.userInfo?.bankaccount : ''} placeholder="Account #" />
          </div>
          <div className="fieldWrap">
            <div className="label">Routing #</div>
            <input onChange={(e: any) => { props.updateUserInfo('bankrouting', e.target.value); }} value={props.userInfo?.bankrouting ? props.userInfo?.bankrouting : ''} placeholder="Routing #" />
          </div>
        </div>
      </div>

      <button className="btn rounded" onClick={updateAccount}>
        <span>Save Changes</span>
      </button>

    </div>
  );
}

export default ProfileSettings;
