import {useState, useEffect} from 'react';
import APIUtils from '../../utils/APIUtils';

const Notifications = () => {
  const [notificationSettings, setNotificationSettings] = useState<any>({
    signed_contract: {
      app: false,
      email: false,
      phone: false,
    },
    declined_contract: {
      app: false,
      email: false,
      phone: false,
    },
    paid_invoice: {
      app: false,
      email: false,
      phone: false,
    },
    overdue_invoice: {
      app: false,
      email: false,
      phone: false,
    },
    proposal_declined: {
      app: false,
      email: false,
      phone: false,
    },
    proposal_approved: {
      app: false,
      email: false,
      phone: false,
    },
  });

  const fetchSettings = async () => {
    let response = await APIUtils.callGet('api/notifications_enabled/');

    const permissions = response.data;

    let permissionsObj: any = {
      signed_contract: {
        app: false,
        email: false,
        phone: false,
      },
      declined_contract: {
        app: false,
        email: false,
        phone: false,
      },
      paid_invoice: {
        app: false,
        email: false,
        phone: false,
      },
      overdue_invoice: {
        app: false,
        email: false,
        phone: false,
      },
      proposal_declined: {
        app: false,
        email: false,
        phone: false,
      },
      proposal_approved: {
        app: false,
        email: false,
        phone: false,
      },
    };

    permissions.forEach((permission: any) => {
      permissionsObj[permission.notificationType][permission.deliveryMethod] = true;
    });

    setNotificationSettings(permissionsObj);
  };

  const hasInitialCheck = (deliveryMethod: string, type: string) => {
    return notificationSettings[type][deliveryMethod];
  };

  const handleCheckboxClick = async (e: any, deliveryMethod: string, type: string) => {
    let localSettings = {...notificationSettings};
    
    if (Array.from(e.target.classList).includes('checked')) {
      localSettings[type][deliveryMethod] = false;

      e.target.classList.remove('checked');
    } else {
      localSettings[type][deliveryMethod] = true;

      e.target.classList.add('checked');
    }

    let response = await APIUtils.callPost('api/notifications_enabled/', {notifications: localSettings});

    setNotificationSettings(localSettings);
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  return (
    <div id="profileNotifications">
      <div className="details">
        <h2>Set up Notifications</h2>
        <p>Configiure email and web app notifications.</p>
      </div>
      <div className="table">
        <div className="headers">
          <div className="title">Notify Me About</div>
          <div>Email</div>
          <div>Phone</div>
          {/* <div>App</div> */}
        </div>
        <div className="row">
          <div className="title">Signed Contract</div>
          <div><button className={`checkbox${hasInitialCheck('email', 'signed_contract') ? ' checked' : ''}`} onClick={(e) => handleCheckboxClick(e, 'email', 'signed_contract')}></button></div>
          <div><button className={`checkbox${hasInitialCheck('phone', 'signed_contract') ? ' checked' : ''}`} onClick={(e) => handleCheckboxClick(e, 'phone', 'signed_contract')}></button></div>
          {/* <div><button className={`checkbox${hasInitialCheck('app', 'signed_contract') ? ' checked' : ''}`} onClick={(e) => handleCheckboxClick(e, 'app', 'signed_contract')}></button></div> */}
        </div>
        <div className="row">
          <div className="title">Declined Contract</div>
          <div><button className={`checkbox${hasInitialCheck('email', 'declined_contract') ? ' checked' : ''}`} onClick={(e) => handleCheckboxClick(e, 'email', 'declined_contract')}></button></div>
          <div><button className={`checkbox${hasInitialCheck('phone', 'declined_contract') ? ' checked' : ''}`} onClick={(e) => handleCheckboxClick(e, 'phone', 'declined_contract')}></button></div>
          {/* <div><button className={`checkbox${hasInitialCheck('app', 'declined_contract') ? ' checked' : ''}`} onClick={(e) => handleCheckboxClick(e, 'app', 'declined_contract')}></button></div> */}
        </div>
        <div className="row">
          <div className="title">Paid Invoice</div>
          <div><button className={`checkbox${hasInitialCheck('email', 'paid_invoice') ? ' checked' : ''}`} onClick={(e) => handleCheckboxClick(e, 'email', 'paid_invoice')}></button></div>
          <div><button className={`checkbox${hasInitialCheck('phone', 'paid_invoice') ? ' checked' : ''}`} onClick={(e) => handleCheckboxClick(e, 'phone', 'paid_invoice')}></button></div>
          {/* <div><button className={`checkbox${hasInitialCheck('app', 'paid_invoice') ? ' checked' : ''}`} onClick={(e) => handleCheckboxClick(e, 'app', 'paid_invoice')}></button></div> */}
        </div>
        {/* <div className="row">
          <div className="title">Overdue Invoice</div>
          <div><button className={`checkbox${hasInitialCheck('email', 'overdue_invoice') ? ' checked' : ''}`} onClick={(e) => handleCheckboxClick(e, 'email', 'overdue_invoice')}></button></div>
          <div><button className={`checkbox${hasInitialCheck('phone', 'overdue_invoice') ? ' checked' : ''}`} onClick={(e) => handleCheckboxClick(e, 'phone', 'overdue_invoice')}></button></div>
          <div><button className={`checkbox${hasInitialCheck('app', 'overdue_invoice') ? ' checked' : ''}`} onClick={(e) => handleCheckboxClick(e, 'app', 'overdue_invoice')}></button></div>
        </div> */}
        <div className="row">
          <div className="title">Approved Proposal</div>
          <div><button className={`checkbox${hasInitialCheck('email', 'proposal_approved') ? ' checked' : ''}`} onClick={(e) => handleCheckboxClick(e, 'email', 'proposal_approved')}></button></div>
          <div><button className={`checkbox${hasInitialCheck('phone', 'proposal_approved') ? ' checked' : ''}`} onClick={(e) => handleCheckboxClick(e, 'phone', 'proposal_approved')}></button></div>
          {/* <div><button className={`checkbox${hasInitialCheck('app', 'proposal_approved') ? ' checked' : ''}`} onClick={(e) => handleCheckboxClick(e, 'app', 'proposal_approved')}></button></div> */}
        </div>
        <div className="row">
          <div className="title">Declined Proposal</div>
          <div><button className={`checkbox${hasInitialCheck('email', 'proposal_declined') ? ' checked' : ''}`} onClick={(e) => handleCheckboxClick(e, 'email', 'proposal_declined')}></button></div>
          <div><button className={`checkbox${hasInitialCheck('phone', 'proposal_declined') ? ' checked' : ''}`} onClick={(e) => handleCheckboxClick(e, 'phone', 'proposal_declined')}></button></div>
          {/* <div><button className={`checkbox${hasInitialCheck('app', 'proposal_declined') ? ' checked' : ''}`} onClick={(e) => handleCheckboxClick(e, 'app', 'proposal_declined')}></button></div> */}
        </div>
      </div>
    </div>
    );
  }
  
  export default Notifications;