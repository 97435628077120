import {Key, useState, useEffect} from 'react';

import ListItemLoading from '../Admin/ListItemLoading';
import APIUtils from '../../utils/APIUtils';
import Filters from '../General/Filters';
import ContractListItem from '../Contract/ContractListItem';

import {Contract} from '../../interfaces/contract';

const SingleProjectContracts = (props: {
  projectid: Key | undefined,
  contracts?: Contract[]
}) => {
  const [contractLoading, setContractLoading] = useState<boolean>(true);
  const [contracts, setContracts] = useState<Contract[]>();
  const [filter, setFilter] = useState<string>('');

  const retrieveProjectContracts = async (id: Key | undefined) => {
    try {
      let url = `api/project/contracts/${id}`;

      if (filter.trim().length) {
        url += `?filter=${filter}`
      }

      let response: any = await APIUtils.callGet(url);
      let contracts: Contract[] = response.data;
      
      setContracts(contracts);
      setContractLoading(false);
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const updateSelectedFilterText = (filter: string) => {
    if (filter === 'All') {
      filter = '';
    }
    setFilter(filter.toLowerCase());
  }

  useEffect(() => {
    if(props.contracts && props.contracts.length > 0) {
      setContracts(props.contracts);
    }
  }, [props.contracts]);

  useEffect(() => {
    retrieveProjectContracts(props.projectid);
  }, [filter]);

  return (
    <>
    <Filters 
      filterValues={['All', 'Drafts', 'Pending', 'Signed', 'Declined']}
      selectedFilter={filter}
      setSelectedFilter={(filter) => updateSelectedFilterText(filter)}
    />
    {contractLoading && (
      <ListItemLoading />
    )}
    { !contractLoading &&
      contracts &&
      contracts.length > 0 &&
      contracts.map((contract: Contract, index: number) => {
        return(
          <ContractListItem 
            key={`contract-${index}`}
            contract={contract}
            backPage={`/projects/${props.projectid}`}
          />
        )
      })
    }
    </>
  );
}

export default SingleProjectContracts;
