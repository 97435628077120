import React from 'react';

const RecentActivityLoading = () => {
  return (
    <>
      <div className="ListItem disabled loading">
        <div className="icon"></div>
        <div>
          <div className="message"></div>
          <div className="time"></div>
        </div>
      </div>
      <div className="ListItem disabled loading">
        <div className="icon"></div>
        <div>
          <div className="message"></div>
          <div className="time"></div>
        </div>
      </div>
      <div className="ListItem disabled loading">
        <div className="icon"></div>
        <div>
          <div className="message"></div>
          <div className="time"></div>
        </div>
      </div>
      <div className="ListItem disabled loading">
        <div className="icon"></div>
        <div>
          <div className="message"></div>
          <div className="time"></div>
        </div>
      </div>
      <div className="ListItem disabled loading">
        <div className="icon"></div>
        <div>
          <div className="message"></div>
          <div className="time"></div>
        </div>
      </div>
    </>
  )
}
export default RecentActivityLoading;
