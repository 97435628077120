import config from '../../config/config';

import React, {useState, useEffect} from 'react';
import APIUtils from '../../utils/APIUtils';
import CheckoutForm from "./CheckoutForm";
import {UserInfo} from '../../interfaces/user';
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from '@stripe/stripe-js';
const stripePromise = loadStripe(config.stripe.pk);

const Payment = (props: {
  userInfo: UserInfo,
  closeModal: () => void,
}) => {
  const [paymentAmount, setPaymentAmount] = useState('0.00');
  const [serviceCharge, setServiceCharge] = useState('0.00');
  const [totalCharge, setTotalCharge] = useState('0.00');
  const [payoutTotal, setPayoutTotal] = useState(0);
  const [clientSecret, setClientSecret] = useState('');
  const [paymentError, setPaymentError] = useState('');
  const [paymentLoading, setPaymentLoading] = useState(false);

  const handleMakePaymentBtnClick = () => {
    setPaymentLoading(true);
    const amount = (Number(totalCharge) * 100).toFixed(0);
    if(amount === '0') {
      return setPaymentError('Invalid value. Value must be greater than 0.');
    }
    APIUtils.callPost('api/payment/intent', {amount: amount })
    .then(response => {
      if(response.status !== 200) {
        return setPaymentError(response.message);
      }
      setClientSecret(response.data);
    })
    .catch((err) => {
      console.log(err.message);
      setPaymentError('Server error. Unable to login.');
    })
    .finally(() => {
      setPaymentLoading(false);
    });
  }

  const handlePaymentAmount = (value: string) => {
    if(value) {
      return setPaymentAmount(Number(value).toFixed(2).toString());
    }
    setPaymentAmount('0.00');
  }

  useEffect(() => {
    if(paymentAmount) {
      setServiceCharge(((Number(10) / Number(100)) * Number(paymentAmount)).toFixed(2).toString());
      const totalAmount = (Number(paymentAmount) + Number(serviceCharge)).toFixed(2).toString();
      setTotalCharge(totalAmount);
      setPayoutTotal((Number(paymentAmount) * 100));
      if(Number(totalAmount) > 0) {
        setPaymentError('');
      }
    }
  }, [paymentAmount, serviceCharge]);

  return (
    <div id="Payment">
      <div>
        <div className="price">
          <span className="ticker">$</span>
          <input type="number" min="0" onChange={(e) => handlePaymentAmount(e.currentTarget.value)} placeholder="0.00"/>
        </div>
        <div className="paymentSummary">
          <article className="lineItem">
            <span className="label">Recipient</span>
            <span className="value">@{props.userInfo.username}</span>
          </article>
          <article className="lineItem">
            <span className="label">Amount</span>
            <span className="value"><span className="ticker">$</span>{paymentAmount}</span>
          </article>
          <article className="lineItem">
            <span className="label">Service Charge (10%)</span>
            <span className="value"><span className="ticker">$</span>{serviceCharge}</span>
          </article>
        </div>
      </div>
      <div>
        <div className="paymentTotal">
          <article className="lineItem">
            <span className="label">Total</span>
            <span className="value"><span className="ticker">$</span>{totalCharge}</span>
          </article>
        </div>
        {
          paymentError && (
            <div className="alert error">
              {paymentError}
            </div>
          )
        }
        {!clientSecret && (
          <div className="btnWrap">
            <button className="btn payment rounded" onClick={handleMakePaymentBtnClick}><span>Make Payment</span></button>
            <button className="btn outline rounded mobile" onClick={props.closeModal}>Close</button>
          </div>
        )}
        {clientSecret && !paymentLoading && (
          <Elements stripe={stripePromise}>
            <CheckoutForm 
              clientSecret={clientSecret}
              stripeConnectID={props.userInfo.stripeconnectid}
              payoutInCents={payoutTotal}
              closeModal={props.closeModal}
            />
          </Elements>
        )}
      </div>
    </div>
  );
}

export default Payment;
