import React, {Key, useState, useRef, useEffect, useCallback} from 'react';
import {Modal} from '../../../interfaces/modal';

import MessageModalCopy from './MessageModalCopy';
import EmailModalCopy from './EmailModalCopy';
import ContactModalCopy from './ContactModalCopy';
import ImageUploadModal from './ImageUploadModal';
import PotionLinkModal from './PotionLinkModal';
import ProjectCreateModal from './ProjectCreateModal';
import NDAModal from './NDAModal';
import IntakeModal from './IntakeModal';
import CompanyContactModal from './CompanyContactModal';
import ClientModalCopy from './ClientModalCopy';

const MainModal = (props: {
  modal: Modal;
  show: boolean;
  close: (redirect?: string | undefined) => void;
}) => {
  const modalRef = useRef<any>();

  const handleClickOutside = useCallback((event: any) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      let redirect = '';
      if (typeof props.modal.redirect === 'string' && props.modal.redirect.length) {
        redirect = props.modal.redirect;
      }
      props.close(redirect);
    }
  }, [props]);

  useEffect(() => {
    window.addEventListener('mousedown', (e) => {handleClickOutside(e)});
    return () => {
      window.removeEventListener('mousedown', (e) => {handleClickOutside(e)});
    };
  }, [handleClickOutside]);

  const modalClasses = `
    modal
    ${props.show ? 'show' : ''}
    ${props.modal.type === 'error' || props.modal.type === 'delete' ? 'error' : ''}
    ${props.modal.type === 'project' ? 'project' : ''}
  `;

  return (
    <div className={modalClasses}>
      <div ref={modalRef} className="modalWrap">
      {
          props.modal.type === 'client' && (
            <ClientModalCopy 
              modal={props.modal}
              close={props.close}
            />
          )
        }
        {
          props.modal.type === 'changeBillTo' && (
            <CompanyContactModal 
              modal={props.modal}
              close={props.close}
            />
          )
        }
        {
          props.modal.type === 'intake' && (
            <IntakeModal 
              modal={props.modal}
              close={props.close}
            />
          )
        }
        {
          props.modal.type === 'nda' && (
            <NDAModal 
              modal={props.modal}
              close={props.close}
            />
          )
        }
        {
          props.modal.type === 'project' && (
            <ProjectCreateModal 
              modal={props.modal}
              close={props.close}
            />
          )
        }
        {
          props.modal.type === 'potion' && (
            <PotionLinkModal 
              modal={props.modal}
              close={props.close}
            />
          )
        }
        {
          props.modal.type === 'image' && (
            <ImageUploadModal 
              modal={props.modal}
              close={props.close}
            />
          )
        }
        {
          props.modal.type === 'contact' && (
            <ContactModalCopy 
              modal={props.modal}
              close={props.close}
            />
          )
        }
        {
          props.modal.type === 'email' && (
            <EmailModalCopy 
              modal={props.modal}
              close={props.close}
            />
          )
        }
        {
          (
            props.modal.type === 'success' ||
            props.modal.type === 'error' ||
            props.modal.type === 'delete'
          ) && (
            <MessageModalCopy 
              modal={props.modal}
            />
          )
        }
        <button className="btn outline rounded icon close" onClick={() => props.close(props.modal.redirect ? props.modal.redirect : '')}></button>
      </div>
    </div>
  );
}

export default MainModal;
