import {Key, useState, useRef, useEffect, createRef} from 'react';
import {useParams} from 'react-router-dom';
import APIUtils from '../../utils/APIUtils';
import NavUtils from '../../utils/NavUtils';

import DashHead from '../Admin/DashHead';
import ClientLoader from './ClientLoader';
import AddressInput from '../General/AddressInput';

import {UserInfo} from '../../interfaces/user';
import {Modal} from '../../interfaces/modal';
import {Contact} from '../../interfaces/client';

import {ReactComponent as DeleteIcon} from '../../assets/img/icons/trash.svg';

const ClientEditor = (props: {
  userInfo?: UserInfo | undefined;
  isLoading: boolean;
  showModal: (details: Modal) => void;
}) => {
  const contactDeleteRef = useRef<any>(null);
  const unitRef = useRef<HTMLInputElement | null>(null);
  const params = useParams();
  
  const [isCreate, setIsCreate] = useState(true);
  const [clientLoading, setClientLoading] = useState<boolean>(false);
  const [clientInfo, setClientInfo] = useState<any>({
    name: '',
    address: '',
    unit: '',
    city: '',
    state: '',
    zip: '',
    contacts: []
  });

  const updateField = (value: number | string, key: string) => {
    let client: any = {...clientInfo};
    client[key] = value;

    setClientInfo(client);
  }

  const handleAddressSelect = async (address: any) => {
    let client: any = {...clientInfo};
    client['address'] = address.address;
    client['city'] = address.city;
    client['state'] = address.state;
    client['zip'] = address.zip;
    // user['country'] = address.country;
    setClientInfo(client);
    if (unitRef.current) {
      unitRef.current.focus();
    }
  }

  const handleClientSubmit = async () => {
    let url = isCreate ? 'api/company/create' : 'api/company/update';

    delete clientInfo.projects;

    try {
      let response = await APIUtils.callPost(url, clientInfo);
      if (response.status !== 200) {
        return props.showModal({
          type: 'error',
          icon: 'user',
          title: isCreate ? 'Creating Client' : 'Updating Client',
          message: `There was an error saving client.`,
        });
      }

      if (isCreate) {
        saveContactsPostCreate(response.data);
      }

      props.showModal({
        type: 'success',
        icon: 'user',
        title: isCreate ? `Client Created` : 'Client Updated',
        message: isCreate ? `New client has been created.` : 'Client has been updated.',
        redirect: '/clients',
      });
    } catch(err) {
      console.log(err);
      props.showModal({
        type: 'error',
        icon: 'user',
        title: isCreate ? 'Creating Client' : 'Updating Client',
        message: `There was an error creating client.`,
      });
    }
  }

  const handleConfirmClientDelete = () => {
    if (isCreate) {
      return NavUtils.redirectToClientListing();
    }
    props.showModal({
      type: 'delete',
      icon: 'user',
      title: 'Are you sure?',
      message: `${clientInfo.name} will be permenantly deleted. This action cannot be undone.`,
      callback: handleClientDelete,
    });
  }

  const handleClientDelete = async () => {
    try {
      let url = `api/company/${clientInfo.id}`;
      let response = await APIUtils.callDelete(url);
      if (response.status !== 200) {
        return props.showModal({
          type: 'error',
          icon: 'user',
          title: 'Deleting Client',
          message: `There was an error deleting ${clientInfo.name}.`,
        });
      }
      props.showModal({
        type: 'success',
        icon: 'user',
        title: 'Client Deleted',
        message: `${clientInfo.name} has been deleted.`,
        redirect: '/clients',
      });
    } catch (err) {
      console.log(err);
      props.showModal({
        type: 'error',
        icon: 'user',
        title: isCreate ? 'Creating Client' : 'Updating Client',
        message: `There was an error creating client.`,
      });
    }
  }

  const fetchClientInfo = async () => {
    if (params.id) {
      try {
        let url = `api/company/${params.id}`;
        let response = await APIUtils.callGet(url);
        setClientInfo(response.data);
      } catch (err) {
        console.log(err);
      }
      setIsCreate(false);
    }
  }

  const handleAddContactBtnClick = (e: any, ref?: any, data?: Contact) => {
    if (ref && (ref == e.target || ref.contains(e.target))) return;
    props.showModal({
      type: 'contact',
      icon: 'user',
      title: 'Add Contact',
      message: `Fill out the information below to add/edit a contact person for ${clientInfo.name}`,
      callback: (contactInfo: Contact) => handleSaveContact(contactInfo),
      data: data
    });
  }

  const saveContactsPostCreate = async (companyid: any) => {
    if (!isCreate) {
      return;
    }

    if (!Array.isArray(clientInfo.contacts) || ! clientInfo.contacts.length) {
      return;
    }

    let url = 'api/contact/create';

    clientInfo.contacts.forEach(async (contact: any) => {
      contact.companyid = companyid;

      await APIUtils.callPost(url, contact);
    });
  }

  const handleSaveContact = async (data: Contact) => {
    if (isCreate) {
      let client: any = {...clientInfo};
      client.contacts.push(data);
      return updateField(client.contacts, 'contacts');
    }
    let existingContact = clientInfo.contacts.filter((contact: Contact) => contact.id === data.id);
    if (existingContact.length > 0) {
      return handleUpdateContact(data);
    }
    handleCreateContact(data);
  }

  const handleCreateContact = async (data: Contact) => {
    let url = 'api/contact/create';
    data.companyid = clientInfo.id;
    try {
      let response = await APIUtils.callPost(url, data);
      if (response.status !== 200) {
        return props.showModal({
          type: 'error',
          icon: 'user',
          title: 'Creating Contact',
          message: `There was an error creating contact.`,
        });
      }
      props.showModal({
        type: 'success',
        icon: 'user',
        title: 'Contact Created',
        message: `Contact successfully created.`,
      });
      fetchClientInfo();
    } catch(err) {
      console.log(err);
      return props.showModal({
        type: 'error',
        icon: 'user',
        title: 'Creating Contact',
        message: `There was an error creating contact.`,
      });
    }
  }

  const handleUpdateContact = async (data: Contact) => {
    let url = 'api/contact/update';
    data.companyid = clientInfo.id;
    try {
      let response = await APIUtils.callPost(url, data);
      if (response.status !== 200) {
        return props.showModal({
          type: 'error',
          icon: 'user',
          title: 'Updating Contact',
          message: `There was an error updating contact.`,
        });
      }
      props.showModal({
        type: 'success',
        icon: 'user',
        title: 'Contact Updated',
        message: `Contact successfully updated.`,
      });
      fetchClientInfo();
    } catch(err) {
      console.log(err);
      return props.showModal({
        type: 'error',
        icon: 'user',
        title: 'Updating Contact',
        message: `There was an error udpating contact.`,
      });
    }
  }

  const handleConfirmContactDelete = (i: Key) => {
    if (isCreate) {
      let client: any = {...clientInfo};
      client.contacts.splice(i, 1);
      updateField(client.contacts, 'contacts');
    }
    props.showModal({
      type: 'delete',
      icon: 'user',
      title: 'Are you sure?',
      message: `${clientInfo.contacts[i as number].email} will be permenantly deleted from ${clientInfo.name}. This action cannot be undone.`,
      callback: () => handleContactDelete(clientInfo.contacts[i as number].id, i),
    });
  }

  const handleContactDelete = async (contactid: Key, i: Key) => {
    let url = `api/contact/${contactid}`;
    try {
      let response = await APIUtils.callDelete(url);
      if (response.status !== 200) {
        return props.showModal({
          type: 'error',
          icon: 'user',
          title: 'Deleting Contact',
          message: `There was an error deleteing contact from ${clientInfo.name}.`,
        });
      }
      let client: any = {...clientInfo};
      client.contacts.splice(i, 1);
      updateField(client.contacts, 'contacts');
      props.showModal({
        type: 'success',
        icon: 'user',
        title: 'Contact Deleted',
        message: `Contact successfully deleted from ${clientInfo.name}.`,
      });
    } catch(err) {
      console.log(err);
      return props.showModal({
        type: 'error',
        icon: 'user',
        title: 'Deleting Contact',
        message: `There was an error deleting contact to ${clientInfo.name}.`,
      });
    }
  }

  const handleBackNav = () => {
    const search = window.location.search.substring(1);
    const params = search ? JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}') : {};

    if (params && params?.source && params.source.indexOf('project') !== -1) {
      window.location.href = params.source;
    } else {
      return NavUtils.redirectToClientListing();
    }
  }

  useEffect(() => {
    if (props.userInfo?.id) {
      updateField(props.userInfo.id, 'userid');
    }
  }, [props.userInfo]);

  useEffect(() => {
    fetchClientInfo();
  }, []);

  useEffect(() => {
    contactDeleteRef.current = clientInfo.contacts.map(() => createRef);
  }, [setClientInfo]);

  return (
    <div className="componentWrap" id="clientCreate">
      { props.userInfo &&
        <>
          <DashHead
            pageTitle={isCreate ? 'Create New Client' : `Edit Client - ${clientInfo.name}`}
            userInfo={props.userInfo}
            back={handleBackNav}
          />
          <div>
            {clientLoading && (
              <ClientLoader />
            )}
            {!clientLoading && (
              <div className="form">

                <div className="clientInfo">
                  <h3>Billing</h3>
                  <div className="fieldWrap">
                    <div className="label">Client</div>
                    <input type="text" value={clientInfo?.name} onChange={(e) => updateField(e.target.value, 'name')} placeholder="name"></input>
                  </div>
                  <div className="fieldWrap">
                    <div className="label">Address</div>
                    <div className="split address">
                      <AddressInput 
                        updateAddress={(address) => handleAddressSelect(address)}
                        defaultValue={clientInfo?.address}
                        placeholder='Address'
                      />
                      <input ref={unitRef} placeholder="Unit" value={clientInfo?.unit} onChange={(e) => {updateField(e.target.value, 'unit')}}></input>
                    </div>
                    <div className="split">
                      <input placeholder="City" value={clientInfo?.city} onChange={(e) => {updateField(e.target.value, 'city')}}></input>
                      <input placeholder="State" value={clientInfo?.state} onChange={(e) => {updateField(e.target.value, 'state')}}></input>
                      <input placeholder="Zip" value={clientInfo?.zip} onChange={(e) => {updateField(e.target.value, 'zip')}}></input>
                    </div>
                  </div>
                </div>

                <div className="contactsListing">
                  <h3>Contacts</h3>
                  <div className="fieldWrap">
                    <button className="btn rounded outline green" onClick={(e) => handleAddContactBtnClick(e)}>
                      <span>Add Contact</span>
                    </button>
                  </div>
                  {
                    clientInfo.contacts &&
                    clientInfo.contacts.length > 0 &&
                    (
                      clientInfo.contacts.map((contact: Contact, index: number) => {
                        let name = contact.email;
                        let initials = contact.email.charAt(0);
                        if (contact.firstname && contact.lastname) {
                          name = `${contact.firstname} ${contact.lastname}`;
                          initials = `${contact.firstname?.charAt(0)}${contact.lastname?.charAt(0)}`;
                        }
                        return (
                          <div key={`contact-${index}`} className="contact" onClick={(e) => handleAddContactBtnClick(e, contactDeleteRef.current[index], contact)}>
                            <div className="initials">{initials}</div>
                            <div className="name">{name}</div>
                            <button ref={ref => contactDeleteRef.current[index] = ref} className="btn rounded outline icon delete" onClick={() => handleConfirmContactDelete(index)}>
                              <DeleteIcon />
                            </button>
                          </div>
                        )
                      })
                    )
                  }
                </div>

                <div className="break"></div>

                <div className="fieldWrap">
                  <div className="split">
                    <button className={!isCreate ? "btn rounded outline red" : "btn rounded outline"} onClick={handleConfirmClientDelete}>
                      {isCreate && (
                        <span>Cancel</span>
                      )}
                      {!isCreate && (
                        <span><DeleteIcon /> Delete</span>
                      )}
                    </button>
                    <button className="btn rounded" onClick={handleClientSubmit}>
                      <span>{isCreate ? 'Create' : 'Update'}</span>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      }
    </div>
  )
}

export default ClientEditor;