import React from 'react';

const Filters = (props: {
  filterValues: string[],
  selectedFilter: string,
  setSelectedFilter: (filter: string) => void,
  showDraftsAsProposals?: boolean;
}) => {

  const handleFilterClick = (value: string) => {
    props.setSelectedFilter(value);
  }

  console.log(props.showDraftsAsProposals);

  return(
    <div className="filters">
      <div className="bar">
      { 
      props.filterValues.map( (value, index) => {
        return(
          <div 
            key={`filter-${index}`}
            className={`
              ${props.selectedFilter === value.toLowerCase() || (!props.selectedFilter && value === 'All')  ? 'filter active' : 'filter'}
            `}
            onClick={(e) => handleFilterClick(value)}
          >
            {props.showDraftsAsProposals && value == 'Drafts' ? 'Proposals' : value}
          </div>
        )
      }) 
      }
      </div>
    </div>
  )
}

export default Filters;