import {useState, useEffect, useCallback} from 'react';

import md5 from 'md5';
import APIUtils from '../../utils/APIUtils';
import NavUtils from '../../utils/NavUtils';

import {Modal} from '../../interfaces/modal';

const LoginForm = (props: {
  setShowForgot: Function;
  register: Function;
  showModal: (details: Modal) => void;
}) => {

  const [loginLoading, setLoginLoading] = useState(false);
  const [error, setError] = useState<string>('');

  const [loginEmail, setLoginEmail] = useState('');
  const [loginPass, setLoginPass] = useState('');

  const [isValidEmail, setIsValidEmail] = useState(false);

  const validateEmail = useCallback((email: string) => {
    const emailRegex = /\S+@\S+\.\S+/;
    if (emailRegex.test(email)) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
  }, []);

  const validateLogin = (email: string, pass: string) => {
    validateEmail(email);
    if(!loginEmail || !loginPass) {
      setError('Enter required fields.');
      return false;
    }
    if(!isValidEmail) {
      setError('Enter a valid email address.');
      return false;
    }
    return true;
  }

  const handleKeyPress = (event: any) => {
    if(event.key === 'Enter'){
      login(loginEmail, loginPass);
    }
  }

  const login = (email: string, pass: string) => {

    setLoginLoading(true);
    let isValidSubmission = validateLogin(email, pass);

    if(!isValidSubmission) {
      return setLoginLoading(false);
    }

		let params = {
			email: email,
			password: md5(pass)
		};

		APIUtils.callPost('api/account/login', params)
		.then(response => {
      if(response.status !== 200) {
        return props.showModal({
          type: 'error',
          icon: 'user',
          title: 'Login Error',
          message: response.message,
          redirect: '',
        });
      }
      return NavUtils.redirectToDashboard();
    })
		.catch((err) => {
      console.error(err.message);
      return props.showModal({
        type: 'error',
        icon: 'user',
        title: 'Login Error',
        message: err.message,
        redirect: '',
      });
		})
    .finally(() => {
      setLoginLoading(false);
    });
	}

  useEffect(() => {
    if(loginEmail) {
      validateEmail(loginEmail);
    }
    if(loginEmail && loginPass && isValidEmail) {
      setError('');
    }
  }, [loginEmail, loginPass, validateEmail, isValidEmail]);

  useEffect(() => {
    if(error) {
      setLoginLoading(false);
    }
  }, [error]);

  return (
    <>
    <div className="formHead">
      <p>Welcome back to Potion!</p>
      <h2>Sign in to your account</h2>
    </div>
    
    <div className="formBody">
      {
        error && (
          <div className="alert error">
            {error}
          </div>
        )
      }
      <div className="field">
        <div className="label">Email *</div>
        <input id="email" type="text" name="email" onChange={(e) => setLoginEmail(e.currentTarget.value)} placeholder="Email" autoComplete="off" autoCapitalize="off" onKeyDown={handleKeyPress} />
      </div>
      <div className="field">
        <div className="label">Password *</div>
        <input id="password" type="password" name="pass" onChange={(e) => setLoginPass(e.currentTarget.value)} placeholder="Password" autoComplete="off" autoCapitalize="off" onKeyDown={handleKeyPress} />
      </div>
      <div className="flexWrap">
        <button className="forgot" onClick={() => props.setShowForgot()}>Forgot password?</button>
      </div>
      <button id="login-btn" className="btn rounded inline" onClick={() => login(loginEmail, loginPass)}>
        {loginLoading && (
          <span><div className="loader"></div></span>
        )}
        {!loginLoading && (
          <span>Sign In</span>
        )}
      </button>
    </div>

    <div className="formLink">
      <p>Not registered yet? <button className="loginLink" id="registerBtn" onClick={() => props.register()}>Create An Account</button></p>
    </div>
    </>
  )
}

export default LoginForm;
