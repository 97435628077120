import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useParams} from 'react-router-dom';

import {UserInfo} from '../../interfaces/user';
import {IntakeClient} from '../../interfaces/client';
import {Modal} from '../../interfaces/modal';

import IntakePrompt from './IntakePrompt';
import IntakeLoader from './IntakeLoader';
import IntakeModal from './IntakeModal';
import IntakeProjectLoader from './IntakeProjectLoader';

import APIUtils from '../../utils/APIUtils';
import UserUtils from '../../utils/UserUtils';

import {ReactComponent as Logo} from '../../assets/img/logo.svg';

const Intake = (props: {
  isLoading: boolean,
  showModal: (details: Modal) => void;
  isPublic: () => void;
}) => {
  const userInfoRef = useRef<boolean>(false);

  const [isIntakeLoading, setIsIntakeLoading] = useState<boolean>(false);
  const [nameGenerated, setNameGenerated] = useState<boolean>(false);
  const [deliverablesGenerated, setDeliverablesGenerated] = useState<boolean>(false);
  const [proposalDraftGenerated, setProposalDraftGenerated] = useState<boolean>(false);

  const [isProjectLoading, setIsProjectLoading] = useState<boolean>(false);
  const [proposalCreated, setProposalCreated] = useState<boolean>(false);
  const [NDACreated, setNDACreated] = useState<boolean>(false);

  const [draftNDA, setDraftNDA] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const [projectInfo, setProjectInfo] = useState({
    userid: '',
    prompt: '',
    title: '',
    deliverables: [''],
  })

  const [NDAhtml, setNDAhtml] = useState<string | TrustedHTML>('');

  const {username} = useParams();
  const [userInfo, setUserInfo] = useState<UserInfo>(UserUtils.getEmptyUserInfo());

  const [retryTitleCount, setRetryTitleCount] = useState<number>(0);
  const [retryDeliverablesCount, setRetryDeliverablesCount] = useState<number>(0);

  const retrieveAccountInfo = useCallback((username: string) => {
    APIUtils.callGet(`api/account/profile/${username}`)
    .then(response => {
      if(response.status === 200) {
        setUserInfo(response.data.userInfo);
        projectInfo.userid = response.data.userInfo.id;
        setProjectInfo(projectInfo);
      }
      // TODO: add error message modal
    })
    .catch((err) => {
      return console.error(err);
      // TODO: add error message modal
    });
  }, [projectInfo]);

  // const fetchSOWTemplate = useCallback( async () => {
  //   let response = await APIUtils.callGet('api/contract/SOW_template')
  //   if (response.status !== 200 || !response.data) {
  //     return console.error(response);
  //   }
  //   return setSOWhtml(response.data);
  // }, []);

  const fetchNDATemplate = useCallback( async () => {
    let response = await APIUtils.callGet('api/contract/NDA_template')
    if (response.status !== 200 || !response.data) {
      return console.error(response);
    }
    return setNDAhtml(response.data);
  }, []);

  const generateProjectTitle = async () => {
    try {
      let subjectPrompt = 'Provide the type of technical project this is referring to in 5 words or less without punctuation or the use of the word project: "'+projectInfo.prompt+'"';
      let response = await APIUtils.callGet(`api/gpt?prompt=${subjectPrompt}`);

      if (response.data.retry && retryTitleCount < 4) {
        setRetryTitleCount(retryTitleCount + 1);
        generateProjectTitle();
        return;
      }

      if (response.status !== 200) {
        return props.showModal({
          type: 'error',
          icon: 'contract',
          title: `Generating Project`,
          message: 'There was an error generating your project request. Please, try again.',
          redirect: `/${userInfo.username}/start-a-project`,
        });
      }

      projectInfo.title = response.data.gpt_response;
      setProjectInfo(projectInfo);
      setNameGenerated(true);

    } catch (err) {
      console.error(err);
      return props.showModal({
        type: 'error',
        icon: 'contract',
        title: `Generating Project`,
        message: 'There was an error generating project request. Please, try again.',
        redirect: `/${userInfo.username}/start-a-project`,
      });
    }

  }

  const generateProjectDeliverables = async () => {
    try {
      let taskPrompt = 'Given this project descripton: "'+projectInfo.prompt+'", Provide one unformatted separated list of tasks needed to complete this project.';
      let response= await APIUtils.callGet(`api/gpt?prompt=${taskPrompt}`);

      if (response.data.retry && retryDeliverablesCount < 4) {
        generateProjectDeliverables();
        return setRetryDeliverablesCount(retryDeliverablesCount + 1);
      }

      if (response.status !== 200) {
        return props.showModal({
          type: 'error',
          icon: 'contract',
          title: `Generating Project`,
          message: 'There was an error generating your project request. Please, try again.',
          redirect: `/${userInfo.username}/start-a-project`,
        });
      }
      
      let deliverables: any = response.data.gpt_response.split("\n");
      let sortedDeliverables: any = [];
      let unknownDeliverables: any = [];

      for (let i = 0; i < deliverables.length; i++) {
        let deliverable = deliverables[i].replace('- ', '').trim();
        let deliverableIndex = deliverable.split('.')[0].trim();
        let number = Number(deliverableIndex);
        let isInteger = Number.isInteger(number);

        if (isInteger) {
          sortedDeliverables[number - 1] = deliverable.split('.')[1].trim();
        } else {
          unknownDeliverables.push(deliverable.trim())
        }
      }

      deliverables = sortedDeliverables.concat(unknownDeliverables);
      
      projectInfo.deliverables = deliverables;
      setProjectInfo(projectInfo);
      setDeliverablesGenerated(true);

    } catch (err) {
      console.error(err);
      // props.showModal({
      //   type: 'error',
      //   icon: 'contract',
      //   title: `Generating Project`,
      //   message: 'There was an error generating your project request. Please, try again.',
      //   redirect: `/${userInfo.username}/start-a-project`,
      // });
      return [];
    }
  }
  
  const handleIntakeSubmission = async (NDA: boolean) => {
    setIsIntakeLoading(true);
    window.history.pushState(null, '', window.location.pathname);
  
    await generateProjectTitle();
    await generateProjectDeliverables();

    if (NDA) {
      setDraftNDA(true);
      await fetchNDATemplate();
    }

    setTimeout(() => {setProposalDraftGenerated(true)}, 500);
    setTimeout(() => {setShowModal(true)}, 1000);

  }

  const handlePromptChange = useCallback((value: string) => {
    let project: any = {...projectInfo};
    project['prompt'] = value;
    setProjectInfo(project);
  }, [projectInfo]);

  const sendIntakeEmails = async (projectId: any, clientInfo: any) => {
    let recipientname = typeof userInfo.firstname === 'string' && userInfo.firstname.trim().length ? userInfo.firstname + ' ' + userInfo.lastname : userInfo.username;

    let payload = {
      projectId,
      email: userInfo.email,
      recipientname: recipientname,
      clientName: `${clientInfo.firstname} ${clientInfo.lastname}`,
      clientCompany: clientInfo.company,
      clientEmail: clientInfo.email,
    };

    let response = await APIUtils.callPost('api/send_email/intake_submission', payload);
  }

  const handleProjectSubmission = async (clientInfo: IntakeClient) => {
    setIsIntakeLoading(false);
    setIsProjectLoading(true);
    setShowModal(false);

    try {
      let clientID: any = await createClient(clientInfo, userInfo.id);
      let contactInfo: any = await createContact(clientInfo, clientID);
      let projectID = await createProject(clientID);
      
      let contactID = contactInfo.id;

      await createProposal(clientInfo, projectID, contactID, clientID);
      await sendIntakeEmails(projectID, clientInfo);

      let modalRedirect = `/${userInfo.username}`;
      let modalMessage = `Successfully submitted project request to ${userInfo.username}. You will be notified via email when a proposal is ready for review.`;
      if (draftNDA) {
        let contract = await createNDAContract(projectID, contactID);
        modalRedirect = `/contract/${contract?.id}?hash=${contract?.hash}`;
        modalMessage = `Successfully submitted project request to ${userInfo.username}. You will be notified via email when a proposal is ready for review. Upon closing this modal, you will be redirected to review and sign the NDA for this project.`;
      }

      return props.showModal({
        type: 'success',
        icon: 'invoice',
        title: 'Success!',
        message: modalMessage,
        redirect: modalRedirect,
      });
    } catch(err) {
      console.error(err);
      
      return props.showModal({
        type: 'error',
        icon: 'contract',
        title: `Oops`,
        message: 'There was an error submitting your project request. Please, try again.',
        redirect: `/${userInfo.username}/start-a-project`,
      });
    }
  }

  const createClient = async (clientInfo: IntakeClient, userID: any) => {
    let payload = {
      name: clientInfo.company,
      address: clientInfo.address,
      unit: clientInfo.unit,
      city: clientInfo.city,
      state: clientInfo.state,
      zip: clientInfo.zip,
      userid: userID,
    };
    
    let response = await APIUtils.callPost('api/company/create', payload);
    if(response.status === 200) {
      return response.data;
    }
    throw console.error('unable to create company');
  }

  const createContact = async (clientInfo: IntakeClient, clientID: any) => {
    let payload = {
      email: clientInfo.email,
      firstname: clientInfo.firstname ? clientInfo.firstname : null,
      lastname: clientInfo.lastname ? clientInfo.lastname : null,
      phone: clientInfo.phone ? clientInfo.phone : null,
      companyid: clientID,
    };
    
    const response: any = await APIUtils.callPost('api/contact/create', payload);
    if(response.status === 200) {
      return response.data;
    }

    throw console.error('unable to create contact');
  }

  const createProject = async (companyid: string) => {
    let payload = {
      userid: userInfo.id,
      companyid: companyid,
      name: projectInfo.title,
      prompt: projectInfo.prompt,
    };

    let response = await APIUtils.callPut('api/project/create', payload);
    
    if(response.status === 200) {
      return response.data.id;
    }
  }

  const createNDAContract = async (projectid: string, contactid: any) => {
    let date = new Date();
    date.setDate(date.getDate() + 360);

    let payload = {
      contactid,
      userid: userInfo.id,
      projectid: projectid,
      type: 'NDA',
      title: projectInfo.title,
      duration: '6 months',
      template: NDAhtml,
      isdraft: false,
      overuid: true,
      expiryDate: date,
    }
    let response = await APIUtils.callPut('api/contract/create', payload);
    if(response.status === 200) {
      setNDACreated(true);
      let contract = {
        id: response.data.id,
        hash: response.data.hash
      }
      return contract;
    }
  }

  // const createSOWContract = async (clientInfo: Client, projectid: string) => {
    // let payload = {
    //   userid: userInfo.id,
    //   projectid: projectid,
    //   type: 'SOW',
    //   title: projectInfo.title,
    //   duration: '6 months or until project completion',
    //   disclosurename: userInfo.firstname + ' ' + userInfo.lastname,
    //   disclosureaddress: userInfo.address,
    //   disclosureunit: userInfo.unit,
    //   disclosurecity: userInfo.city,
    //   disclosurestate: userInfo.state,
    //   disclosurezip: userInfo.zip,
    //   recipientname: clientInfo.firstname + ' ' + clientInfo.lastname,
    //   recipientemail: clientInfo.email,
    //   recipientphone: clientInfo.phone,
    //   recipientcompany: clientInfo.company,
    //   recipientaddress: clientInfo.address,
    //   recipientunit: clientInfo.unit,
    //   recipientcity: clientInfo.city,
    //   recipientstate: clientInfo.state,
    //   recipientzip: clientInfo.zip,
    //   template: SOWhtml,
    //   overuid: true,
    // }
  //   let response = await APIUtils.callPut('api/contract/create', payload);
  //   if(response.status === 200) {
  //     setSOWCreated(true);
  //     return response.data.id;
  //   }
  // }

  const createProposal = async (clientInfo: IntakeClient, projectid: string, contactID: string, clientID: string) => {
    let deliverables = projectInfo.deliverables;
    let invoiceItems: any = [];
    deliverables.forEach(deliverable => {
      invoiceItems.push({
        qty: 1,
        price: 0,
        description: deliverable
      });
    });

    let billTo = `${clientInfo.company}
      ${clientInfo.address} ${clientInfo.unit}
      ${clientInfo.city}, ${clientInfo.state} ${clientInfo.zip}
    `;

    let payload: any = {
      projectid: projectid,
      billTo: billTo,
      contactid: contactID,
      clientid: clientID,
      issuedat:  new Date(),
      total: 0.00,
      subject: projectInfo.title,
      description: projectInfo.prompt,
      notes: '',
      isproposal: true,
      userid: userInfo.id,
      intake: true,
    };
    payload.items = [...invoiceItems];

    let response = await APIUtils.callPut('api/invoice/create', payload);
    // TODO: Validate response
    if(response.status === 200) {
      setProposalCreated(true);
      return response.data.id;
    }
  }

  const onBackButtonEvent = useCallback((event: any) => {
    event.preventDefault();
    setIsIntakeLoading(false);
    setShowModal(false);
    handlePromptChange('');
  }, [handlePromptChange]);
  
  useEffect(() => {
    if (userInfoRef.current || !username) return;
    userInfoRef.current = true;
    retrieveAccountInfo(username);
	}, [username, retrieveAccountInfo]);

  useEffect(() => {
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);  
    };
  }, [onBackButtonEvent]);

  useEffect(() => {
    props.isPublic();
  }, [props]);

  return (
    
    <>
    {props.isLoading && (
      <div className="loader"></div>
    )}
    {!props.isLoading && (
      <>
      <IntakeModal 
        username={userInfo.username}
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        showDraftNDA={draftNDA}
        create={(clientInfo) => handleProjectSubmission(clientInfo)}
      />
      <div className="componentWrap" id="Intake">
        <a className="logo" href="/"><Logo /></a>
        {isIntakeLoading && (
          <IntakeLoader 
            username={userInfo.username}
            nameGenerated={nameGenerated}
            deliverablesGenerated={deliverablesGenerated}
            proposalDraftGenerated={proposalDraftGenerated}
            showModal={() => setShowModal(true)}
          />
        )}
        {isProjectLoading && (
          <IntakeProjectLoader 
            showDraftNDA={draftNDA}
            NDACreated={NDACreated}
            // SOWCreated={SOWCreated}
            proposalCreated={proposalCreated}
            username={userInfo.username}
          />
        )}
        {!isProjectLoading && !isIntakeLoading && (
          <IntakePrompt 
            isLoading={props.isLoading}
            isIntakeLoading={isIntakeLoading}
            userInfo={userInfo}
            prompt={projectInfo.prompt}
            update={(value: string) => handlePromptChange(value)}
            submit={(NDA) => handleIntakeSubmission(NDA)}
          />
        )}
      </div>
    </>
    )}
    </>
  );
}

export default Intake;
