import React from 'react';

const ClientLoader = () => {
  return (
    <>
      <div className="card loading">
        <div className="top">
          <div className="name"></div>
          <div className="icon"></div>
        </div>
        <div className="bottom">
          <div className="status draft"></div>
          <div className="status draft"></div>
        </div>
      </div>
      <div className="card loading">
        <div className="top">
          <div className="name"></div>
          <div className="icon"></div>
        </div>
        <div className="bottom">
          <div className="status draft"></div>
          <div className="status draft"></div>
        </div>
      </div>
      <div className="card loading">
        <div className="top">
          <div className="name"></div>
          <div className="icon"></div>
        </div>
        <div className="bottom">
          <div className="status draft"></div>
          <div className="status draft"></div>
        </div>
      </div>
      <div className="card loading">
        <div className="top">
          <div className="name"></div>
          <div className="icon"></div>
        </div>
        <div className="bottom">
          <div className="status draft"></div>
          <div className="status draft"></div>
        </div>
      </div>
    </>
  )
}

export default ClientLoader;
