import {useState, useEffect} from 'react';

import {ReactComponent as CopyIcon} from '../../assets/img/icons/copy.svg';

const UserPotionLink = (props: {
  username: String | undefined;
  callback?: (link: string) => void;
}) => {
  const [potionLink, setPotionLink] = useState<string>('');
  const [potionShortLink, setPotionShortLink] = useState<string>('');
  const [copied, setCopied] = useState<boolean>(false);

  const getUserProjectLink = () => {
    return `${window.location.origin}/${props.username}/start-a-project/`;
  };

  const copyLink = () => {
    navigator.clipboard.writeText(potionLink);
    setCopied(true);
    setTimeout(() => setCopied(false), 500);
  };

  useEffect(() => {
    if(props.username) {
      const link = getUserProjectLink();
      const prettyLink = link.substring(0, 20) + '...';

      setPotionLink(link);
      setPotionShortLink(prettyLink);
      if (props.callback) {
        props.callback(link);
      }
    }
  }, [props.username]);

  return (
    <button className="btn potion green" onClick={copyLink}>
      <div className="link">
        {potionShortLink}
      </div>
      <div className="copy">
        <CopyIcon /> {copied ? 'Copied' : 'Copy Link'}
      </div>
    </button>
  )
}

export default UserPotionLink;