import React, {useEffect, useState, useCallback} from 'react';
import {renderToStaticMarkup} from 'react-dom/server';

import DashHead from'../Admin/DashHead';
import APIUtils from '../../utils/APIUtils';
import NavUtils from '../../utils/NavUtils';
import ContractUtils from '../../utils/ContractUtils';

import {UserInfo} from '../../interfaces/user';
import {Contract} from '../../interfaces/contract';
import {Modal} from '../../interfaces/modal';

import {ReactComponent as Logo} from '../../assets/img/logo.svg';

const ContractEditor = (props: {
  userInfo: UserInfo | undefined,
  isLoading: boolean,
  showModal: (details: Modal) => void
}) => {
  const [contract, setContract] = useState<Contract | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isPublishable, setIsPublishable] = useState<boolean>(false);
  const [templateHTML, setTemplateHTML] = useState<any>();
  const [updatedTemplateHTML, setUpdatedTemplateHTML] = useState<any>();

  const [errorFields, setErrorFields] = useState<string[]>([]);

  const requiredFields = [
    'title',
    'expiryDate',
    'userid', 
    'projectid',
    'contactid'
  ]

  const getContractIdFromQueryParams = () => {
    let href = window.location.href.split("/").pop();
    if (href?.indexOf('?') !== -1) {
      href = href?.split('?')[0];
    }

    return href;
  };

  const fieldValidation = async (contract: Contract) => {
    let errors: string[] = [];
    requiredFields.map((field: string, index: number) => {
      if (!contract[`${field}` as keyof typeof contract]) {
        errors.push(field);
      }
    });
    setErrorFields(errors);
    if  (errors.length > 0) {
      return formatErrorList(errors);
    }
    setIsPublishable(true);
    return false;
  }

  const formatErrorList = (errors: string[]) => {
    let errorList = (
      <ul>
        {errors.map((field: string, index: number) => {
          return (
            <li key={`error-${index}`}>{field.replace('recipient', 'recipient_').replace('disclosure', 'disclosure_')}</li>
          )
        })}
      </ul>
    );
    return renderToStaticMarkup(errorList);
  }

  const updateContractData = async () => {

    setIsLoading(true);
    let contract_info: any = contract;

    delete contract_info.id;
    delete contract_info.userid;
    delete contract_info.updatedat;
    delete contract_info.signedDate;
    delete contract_info.isdraft;
    delete contract_info.recipientSignature;
    delete contract_info.disclosureSignature;
    delete contract_info.declinedDate;
    delete contract_info.createdat;
    delete contract_info.businessname;
    delete contract_info.project_prompt;
    delete contract_info.invoice_deliverables;
    delete contract_info.company;
    delete contract_info.previewTemplate;

    contract_info['template'] = updatedTemplateHTML;

    try {
      let response = await APIUtils.callPost('api/contract/' + getContractIdFromQueryParams(), contract_info);
      if (response.status === 200) {
        setIsLoading(false);
        return props.showModal({
          type: 'success',
          icon: 'contract',
          title: `Updating Contract`,
          message: 'Contract Successfully Updated.',
          redirect: `/contract/view/${response.data.id}`,
        });
      }
      setIsLoading(false);
      return props.showModal({
        type: 'error',
        icon: 'contract',
        title: `Editing Contract`,
        message: response.message,
        redirect: '',
      });
    } catch (err) {
      console.error(err);
      return props.showModal({
        type: 'error',
        icon: 'contract',
        title: `Editing Contract`,
        message: 'Unable to update contract.',
        redirect: '',
      });
    }
  };

  // const publishContract = async () => {
  //   try {
  //     let response = await APIUtils.callPost(`api/contract_publish/${getContractIdFromQueryParams()}`, {});
  //     if (response.status === 200) {
  //       return props.showModal({
  //         type: 'success',
  //         icon: 'contract',
  //         title: `Contract #${getContractIdFromQueryParams()}`,
  //         message: 'Contract published.',
  //         redirect: `/contract/view/${getContractIdFromQueryParams()}`,
  //       });
  //     }
  //     return props.showModal({
  //       type: 'error',
  //       icon: 'contract',
  //       title: `Publishing Contract}`,
  //       message: 'There was an error publishing the contract.'
  //     });
  //   } catch (err: any) {
  //     console.log(err);
  //     return props.showModal({
  //       type: 'error',
  //       icon: 'contract',
  //       title: `Publishing Contract}`,
  //       message: err.message,
  //       redirect: '',
  //     });
  //   }
  // };

  const handleContractSave = async () => {
    if (contract) {
      let errors = await fieldValidation(contract);
      if (errors) {
        return props.showModal({
          type: 'error',
          icon: 'contract',
          title: `Missing Info`,
          message: `Missing fields required to publish contract: ${errors}`,
          redirect: '',
        });
      }
      updateContractData();
    }
  }

  const getContract = useCallback(async () => {
    setIsLoading(true);

    let response = await APIUtils.callGet('api/contract/' + getContractIdFromQueryParams());
    if (response.status === 200) {
      setContract(response.data);
      setTemplateHTML({
        __html: response.data.previewTemplate
      });
      setUpdatedTemplateHTML(response.data.previewTemplate);
    }

    setIsLoading(false);
  }, []);

  const handleBackButtonPress = () => {
    const search = window.location.search.substring(1);
    const params = search ? JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}') : {};

    if (params && params?.source === 'dashboard') {
      return NavUtils.redirectToDashboard();
    }
    
    NavUtils.redirectToContracts();
  };

  useEffect(() => {
    let contractID = getContractIdFromQueryParams();
    if(!contractID?.includes('create')) {
      getContract();
    }
  }, [getContract]);

  return (
    <>
    {props.isLoading && (
      <div className="loader"></div>
    )}
    {!props.isLoading && props.userInfo && (
      <div className="componentWrap" id="contractEditor">
        <DashHead
          pageTitle={`${contract?.type} for ${contract?.company?.name}`}
          pageSubTitle={contract?.title}
          userInfo={props.userInfo}
          back={handleBackButtonPress}
        />
        <div className="box">
          <Logo />
          <div className="contractWrap" contentEditable={true} dangerouslySetInnerHTML={templateHTML} onInput={(e) => setUpdatedTemplateHTML(e.currentTarget.innerHTML)}></div>
        </div>
        <button className="btn rounded inline contractSaveBtn" onClick={handleContractSave}>
          {isLoading && (
            <div className="loader"></div>
          )}
          {!isLoading && (
            <span>Save Contract</span>
          )}
        </button>
      </div>
    )}
    </>
  );
}

export default ContractEditor;
