import _ from 'lodash';
import {UserInfo} from '../../interfaces/user';
import PaymentUtils from '../../utils/PaymentUtils'
import {Modal} from '../../interfaces/modal';

const CreateInvoiceStepTwo = (props: {
    userInfo?:  UserInfo | undefined,
    handleAddItemClick: any,
    handleContinueClick: any,
    handleItemInfoUpdate: any,
    invoiceItems: any[],
    showModal:  (details: Modal) => void,
}) => {
  const validateData = () => {
    if (!areInvoiceItemsValid()) {
        return props.showModal({
            type: 'error',
            icon: 'user',
            title: 'Invalid Item Data',
            message: 'Please ensure all item data is filled in and the price and quantity are positive numbers.',
            redirect: '',
        });
    }
    props.handleContinueClick();
  };

  const areInvoiceItemsValid = () => {
    for (let i = 0; i < props.invoiceItems.length; i++) {
        let invoice = props.invoiceItems[i];

        if (typeof invoice.description !== 'string' || !invoice.description.trim().length) {
            return false;
        }

        if (isNaN(invoice.qty) || isNaN(invoice.price)) {
            return false;
        }

        if (invoice.qty < 0 || invoice.price < 0) {
            return false;
        }
    }

    return true;
  };

  return (
    <>
        <div className="stepHead">
            <h2>
            <span>Step 2 of 4</span>
            Add Items
            </h2>
            <p>Add a list of your services.</p>
        </div>
        <div id="step2">
            {props.invoiceItems.map((item: any, index: number) => {
                return (
                    <div key={`item-${index}`}>
                        <div className="item">
                            <h3>Item {index+1}</h3>
                            <div className="fieldWrap">
                                <div className="label">Item Description</div>
                                <input value={item.description} onChange={(e: any) => { props.handleItemInfoUpdate(index, 'description', e.target.value)}} placeholder="" />
                            </div>
                            <div className="fieldWrap split three">
                                <div>
                                    <div className="label">Qty</div>
                                    <input type='number' value={item.qty} onChange={(e: any) => { props.handleItemInfoUpdate(index, 'qty', e.target.value)}} />
                                </div>
                                <div>
                                    <div className="label">Unit Cost</div>
                                    <input type='number' value={item.price} onChange={(e: any) => { props.handleItemInfoUpdate(index, 'price', e.target.value)}} />
                                </div>
                                <div>
                                    <div className="label">Amount</div>
                                    <div className="price">{PaymentUtils.formatPrice(item.qty * item.price)}</div>
                                </div>
                            </div>
                        </div>
                        <div className="break"></div>
                    </div>
                )
            })}
        </div>
        <div>
            <button className="btn rounded addItemBtn" onClick={props.handleAddItemClick}>
                <span>Add Item</span>
            </button>
        </div>
        <div>
            <button className='btn rounded stepsContinueBtn' onClick={validateData}>
                <span>Continue</span>
            </button>
        </div>
    </>
  );
};

export default CreateInvoiceStepTwo;
