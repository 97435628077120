import React, {useState, useEffect, useRef, useCallback} from 'react';
import APIUtils from '../../utils/APIUtils';
import {UserInfo} from '../../interfaces/user';
import {ReactComponent as IndividualIcon} from '../../assets/img/individual.svg';
import { ReactComponent as SearchIcon } from '../../assets/img/search.svg';

const AdminUserListItem = (props: {
  user: UserInfo,
}) => {
  const colors = ["#EBD882", "#5B82E5", "#B64ACF", "#C86D49", "#70D880", "#7351BF"];
  const color = colors[Math.floor(Math.random()*colors.length)];
  return(
    <div className="userListItem">
      <a className="userImage" style={{backgroundColor: color}} href={`/${props.user.username}`} target="_blank" rel="noreferrer">
        <IndividualIcon />
      </a>
      <div className="userInfo">
        {
          props.user.firstname && props.user.lastname ? (
            <>
              <p className="bold">{props.user.firstname} {props.user.lastname}</p>
              <a href={`/${props.user.username}`} target="_blank" rel="noreferrer"> 
                @{props.user.username}
              </a>
            </>
          )
          :
          (
            <a className="bold" href={`/${props.user.username}`} target="_blank" rel="noreferrer">
              @{props.user.username}
            </a>
          )
        }
        <a href={`mailto:${props.user.email}`}>
          {props.user.email}
        </a>
      </div>
    </div>
  )
}

const AdminUserList = () => {
  const userListRef = useRef(false);
  const [userList, setUserList] =  useState([]);
  const [searchText, setSearchText] = useState('');

  const retrieveUserList = () => {
    if(userListRef.current) return;
    userListRef.current = true;
    APIUtils.callGet('api/admin/get_all_users')
    .then(response => {
      setUserList(response.data);
    })
    .catch((err) => {
      return console.error(err);
    });
  }

  const filterSearchResults = useCallback(() => {
    if (typeof searchText !== 'string' || !searchText.length) {
      return retrieveUserList();
    }
    APIUtils.callGet(`api/account/search/${searchText}`)
    .then(response => {
      if (response.status !== 200 || response.data.searchResults.length === 0) {
        return retrieveUserList();
      }
      return setUserList(response.data.searchResults);
    })
    .catch((err) => {
      console.error(err);
    });
  }, [searchText]);

  useEffect(() => {
    retrieveUserList();
  }, []);

  useEffect(() => {
    filterSearchResults();
  }, [searchText, filterSearchResults]);

  return (
    <div className="metricWrap">
      <div className="headerWrap">
        <h2>User Directory</h2>
      </div>
      <div className="box">
        <div className="searchInput">
          <SearchIcon />
          <input type="text" onKeyUp={(e) => setSearchText(e.currentTarget.value)} placeholder="Search" />
        </div>
        <div className="directory">
          {
            userList.length > 0 ? 
            userList.map((user, i) => {
              return (
                <AdminUserListItem 
                  key = {i}
                  user = {user}
                />
              )
            })
            :
            (
              <p>There are no users.</p>
            )
          }
        </div>
      </div>
    </div>
  );
}

export default AdminUserList;
