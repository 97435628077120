import _ from 'lodash';
import {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';

import APIUtils from '../../utils/APIUtils';
import NavUtils from '../../utils/NavUtils';
import SignatureUtils from '../../utils/SignatureUtils';
import SignatureCanvas from 'react-signature-canvas';

import {UserInfo} from '../../interfaces/user';
import {Contract} from '../../interfaces/contract';
import {Modal} from '../../interfaces/modal';

import {ReactComponent as Logo} from '../../assets/img/logo.svg';

const ContractPublicView = ( props: {
  userInfo?: UserInfo | undefined;
  isLoading: boolean;
  showModal: (details: Modal) => void;
  isPublic?: Function;
}) => {
  const params = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const [isLoading, setIsLoading] = useState<Boolean>(true);
  const [isDisclosure, setIsDisclosure] = useState<boolean>(false);
  const [contract, setContract] = useState<Contract | undefined>();
  const [defaultContactEmail, setDefaultContactEmail] = useState<any>('');

  const [ndaHtml, setNdaHtml] = useState<any>({
    __html: ''
  });

  const [discloseSigPad, setDisclosedSigPad] = useState<any>();
  const [recipientSigPad, setRecipientSigPad] = useState<any>();

  const fetchContractTemplate = _.debounce(async () => {
    let response = await APIUtils.callGet(`api/view_contract/${params.id}?hash=${urlParams.get('hash')}`);
    if (response.status !== 200) {
      return props.showModal({
        type: 'error',
        icon: 'contract',
        title: `Viewing Contract`,
        message: 'The contract link is not valid. Contract cannot be viewed.',
        redirect: '/',
      });
    }
    setNdaHtml({
      __html: response.data.__html
    });
    setContract(response.data.contract);
    setIsLoading(false);
  }, 300);

  const saveSignatures = async () => {
    try {
      let discloseSigSet = false;
      if (discloseSigPad !== undefined && discloseSigPad !== null && discloseSigPad.toData().length > 0) {
        discloseSigSet = true;
      }
      
      let recipSigSet = false;
      if (recipientSigPad !== undefined && recipientSigPad !== null && recipientSigPad.toData().length > 0) {
        recipSigSet = true;
      }
      
      let payload = {
        disclosure: discloseSigSet ? discloseSigPad.toDataURL() : null,
        recipient: recipSigSet ? recipientSigPad.toDataURL() : null,
      }

      let response = await APIUtils.callPost(`api/save_contract_signatures/${params.id}?hash=${urlParams.get('hash')}`, payload);
      if (response.status !== 200) {
        return props.showModal({
          type: 'error',
          icon: 'contract',
          title: `Saving Signature`,
          message: 'There was an error saving the singature for this contract.',
          redirect: window.location.href,
        });
      }
      return props.showModal({
        type: 'success',
        icon: 'contract',
        title: `Contract #${params.id}`,
        message: 'Contract signature saved.',
        redirect: window.location.href,
      });
    } catch (err: any) {
      console.log(err);
      return props.showModal({
        type: 'error',
        icon: 'contract',
        title: `Saving Signature`,
        message: err.message,
        redirect: window.location.href,
      });
    }
  };

  const publishContract = async () => {
    try {
      let response = await APIUtils.callPost(`api/contract_publish/${contract?.id}`, {});
      if (response.status !== 200) {
        return props.showModal({
          type: 'error',
          icon: 'contract',
          title: `Publishing Contract`,
          message: 'There was an error publishing the contract and requesting recipient signature.',
        });
      }
      return props.showModal({
        type: 'success',
        icon: 'contract',
        title: `Contract Published`,
        message: 'Your contract has been successfully sent for signing.',
        redirect: '/contracts',
      });
    } catch (err: any) {
      console.log(err);
      return props.showModal({
        type: 'error',
        icon: 'contract',
        title: `Publishing Contract`,
        message: err.message,
      });
    }
  };

  const handleSendEmailBtnClick = () => {
    props.showModal({
      type: 'email',
      icon: 'email',
      title: `Share Contract`,
      message: `Send contract to be viewed and/or signed via email.`,
      data: {
        email: defaultContactEmail,
        type: 'contract',
        itemId: contract?.id,
        hash: contract?.hash,
        userInfo: props.userInfo
      },
      callback: (response) => handleSendEmailResponse(response)
    });
  }

  const handleSendEmailResponse = (response: {
    status: number,
    message: string,
    data: any,
  }) => {
    if (response.status !== 200) {
      props.showModal({
        type: 'error',
        icon: 'email',
        title: `Sending Email`,
        message: `There was an error sending email to recipient.`,
      });
    }
    props.showModal({
      type: 'success',
      icon: 'email',
      title: `Email Sent`,
      message: `An email with the contract link was sent to ${response.data.email}`,
    });
  }

  const declineContract = async () => {
    try {
      let url = `api/contract_decline/${params.id}`;
      if (contract?.hash) {
        url += `?hash=${contract.hash}`;
      }

      let response = await APIUtils.callPost(url, {});
      if(response.status !== 200) {
        return props.showModal({
          type: 'error',
          icon: 'contract',
          title: `Contract #${params.id}`,
          message: 'Issue updating contract.',
          redirect: '',
        });
      }
      return props.showModal({
        type: 'success',
        icon: 'contract',
        title: `Contract #${params.id}`,
        message: 'Contract declined.',
        redirect: `/${(window.location.pathname + window.location.search).substr(1)}`,
      });
    } catch (err: any) {
      console.log(err);
      return props.showModal({
        type: 'error',
        icon: 'contract',
        title: `Contract #${params.id}`,
        message: err.message,
        redirect: '',
      });
    }
  };

  useEffect(() => {
    if (typeof params.id !== 'string') return;
    fetchContractTemplate();
  }, []);
  
  useEffect(() => {
    if (props.userInfo?.id === contract?.userid) {
      setIsDisclosure(true);
    } else {
      setIsDisclosure(false);
    }
    if (contract?.contact?.email) {
      setDefaultContactEmail(contract.contact.email);
    }
  }, [contract]);

  useEffect(() => {
    if (props.isPublic) {
      props.isPublic();
    }
    // if (props.userInfo?.id === userInfo?.id) {
    //   setIsOwner(true);
    // }
  }, [props]);

  return (
    <>
    {isLoading && (
      <div className="loader"></div>
    )}
    {!isLoading && (
      <div className="contentWrap publicView" id="contractPublicView">
        {/* {isOwner && (
          <button className="btn rounded outline green listingBtn" onClick={() => NavUtils.redirectToInvoices()}>
            View All Contracts
          </button>
        )} */}
        <div className="box">
          <a className="logo" href="/"><Logo /></a>
          <div className="contractWrap" dangerouslySetInnerHTML={ndaHtml}></div>
          {contract?.declinedDate === null && (
          <div className="signaturesWrap">
            <h3>Signatures:</h3>

            <div className='fieldWrap'>
              {isDisclosure && contract.disclosureSignature === null &&
                <SignatureCanvas 
                  penColor='white'
                  canvasProps={{className: 'viewContractSignatureCanvas'}}
                  ref={(ref) => { setDisclosedSigPad(ref) }}
                />
              }
              {typeof contract.disclosureSignature === 'string' && contract.disclosureSignature.length > 0 &&
                <img src={SignatureUtils.getFullImagePath(contract.disclosureSignature)} alt='' />
              }
              {!isDisclosure && typeof contract.disclosureSignature !== 'string' &&
                <div className="awaiting">Awaiting Disclosure Signature</div>
              }
              <div>Disclosure</div>
            </div>
            
            <div className='fieldWrap'>
              {!isDisclosure && contract.recipientSignature === null &&
                <SignatureCanvas 
                  penColor='white'
                  canvasProps={{className: 'viewContractSignatureCanvas'}}
                  ref={(ref) => { setRecipientSigPad(ref) }}
                />
              }
              {typeof contract.recipientSignature === 'string' && contract.recipientSignature.length > 0 &&
                <img src={SignatureUtils.getFullImagePath(contract.recipientSignature)} alt='' />
              }
              {isDisclosure && contract.recipientSignature === null && (
                <div className="awaiting">Awaiting Recipient Signature</div>
              )}
              <div>Recipient</div>
            </div>
          </div>
          )}
          {/* {!contract?.isdraft && ( */}
            <div className="btnWrap">
              {
                isDisclosure && 
                contract?.disclosureSignature === null && (
                  <button className="btn rounded" onClick={saveSignatures}><span>Save Signature</span></button>
                )
              }
              {
                isDisclosure && 
                typeof contract?.disclosureSignature === 'string' && 
                contract.disclosureSignature.length > 0 && (
                  <button className="btn rounded" onClick={contract.issueDate ? handleSendEmailBtnClick : publishContract}>
                    <span>Request Signature</span>
                  </button>
                )
              }
              {
                !isDisclosure && 
                contract?.recipientSignature === null &&
                contract.declinedDate === null && (
                  <>
                  <button className="btn rounded" onClick={saveSignatures}><span>Sign</span></button>
                  <span className="or">
                    <span>or</span>
                  </span>
                  <button className="btn rounded outline red" onClick={declineContract}>Decline</button>
                  </>
                )
              }
            </div>
          {/* )} */}
        </div>
     </div>
    )}
    </>
  );
}

export default ContractPublicView;