import _ from 'lodash';
import moment from "moment";
import React, {useState, useEffect, useRef, useCallback} from 'react';
import APIUtils from '../../utils/APIUtils';
import ClientUtils from '../../utils/ClientUtils';

import DatePicker from "react-datepicker";
import Dropdown from "../General/Dropdown";

import {UserInfo} from '../../interfaces/user';
import {Contract} from '../../interfaces/contract';
import {Project} from '../../interfaces/project';
import {Contact, Client, Company} from '../../interfaces/client';
import {Modal} from '../../interfaces/modal';

const CreateContractStepOne = (props: {
  userInfo: UserInfo | undefined,
  contract: Contract | undefined,
  selectedClientId: any,
  setSelectedClientId: any,
  setSelectedContactId: any,
  selectedContactId: any,
  selectedProjectId: any,
  setSelectedProjectId: any,
  updateContractField: (value: number | string | Date, field: string) => void,
  changeStep: () => void,
  showModal: (details: Modal) => void
}) => {

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [clients, setClients] = useState<Client[]>([]);
  const [selectedClient, setSelectedClient] = useState<Client>();

  const [projects, setProjects] = useState<Project[]>([]);
  const [contacts, setContacts] = useState<Contact[]>([]);

  const projectInputRef = useRef<any>();
  const [showProjectCreate, setShowProjectCreate] = useState(false);
  const [projectName, setProjectName] = useState<string>('');

  const retrieveClients = async () => {
    let clients: Client[] = await ClientUtils.retrieveClients();
    
    setClients(clients);
    setIsLoading(false);
  };
  const debounceRetrieveClients = _.debounce(retrieveClients, 500);

  const handleClientSelect = (value: string | number) => {
    let client = clients.find(client => client.id === value);
    if (client) {
      setContacts(client.contacts as Contact[]);
      setSelectedClient(client as Client);
      props.setSelectedClientId(client.id);
    }
  }

  const handleAddNewClientBtnClick = () => {
    props.showModal({
      type: 'client',
      icon: 'user',
      title: 'Add Client',
      message: `Fill out the information below to add a new client.`,
      callback: (clientInfo: Company) => handleSaveNewClient(clientInfo)
    });
  }

  const handleSaveNewClient = async (clientInfo: Company) => {
    try {
      let response = await APIUtils.callPost('api/company/create', clientInfo);
      if (response.status !== 200) {
        return props.showModal({
          type: 'error',
          icon: 'user',
          title: 'Creating Client',
          message: `There was an error saving a new client.`,
        });
      }
      await retrieveClients();
      props.setSelectedClientId(response.data);
      return props.showModal({
        type: 'success',
        icon: 'user',
        title: 'Client Created',
        message: `Your new client has been created.`,
      });
    } catch (err) {
      console.error(err);
      return props.showModal({
        type: 'error',
        icon: 'user',
        title: 'Creating Client',
        message: `There was an error saving a new client.`,
      });
    }
  }

  const retrieveClientProjects =  async () => {
    try {
      let url = `api/company/projects/${props.selectedClientId}`;
      let response: any = await APIUtils.callGet(url);
      let projects: Project[] = response.data;

      setProjects(projects.length ? projects : []);
    } catch (err) {
      console.log(err);
      return [];
    }
  };
  
  const setTitleFromProjectId = async () => {
    let project = projects.find(project => project.id === props.contract?.projectid);
    props.updateContractField(project ? project.name : 'Contract', 'title');
  }

  const handleProjectSelect = async (value: string | number) => {
    props.setSelectedProjectId(value);
    // props.updateContractField(value, 'projectid');
  }

  const handleContactSelect = (value: string | number) => {
    props.setSelectedContactId(value);
    // props.updateContractField(value, 'contactid');
  }

  const handleAddNewContactBtnClick = () => {
    props.showModal({
      type: 'contact',
      icon: 'user',
      title: 'Add Contact',
      message: `Fill out the information below to add/edit a contact person for ${selectedClient?.name}`,
      callback: (contactInfo: Contact) => handleSaveNewContact(contactInfo)
    });
  }

  const handleSaveNewContact = async (contactInfo: Contact) => {
    let url = 'api/contact/create';
    contactInfo.companyid = props.selectedClientId;
    try {
      let response = await APIUtils.callPost(url, contactInfo);
      if (response.status !== 200) {
        return props.showModal({
          type: 'error',
          icon: 'user',
          title: 'Creating Contact',
          message: `There was an error creating contact.`,
        });
      }
      props.showModal({
        type: 'success',
        icon: 'user',
        title: 'Contact Created',
        message: `Contact successfully created.`,
      });
      if (selectedClient) {
        let currentContacts = [...selectedClient.contacts]
        currentContacts.push(response.data);
        setContacts(currentContacts);
      }
      handleContactSelect(response.data.id);
    } catch(err) {
      console.log(err);
      return props.showModal({
        type: 'error',
        icon: 'user',
        title: 'Creating Contact',
        message: `There was an error creating contact.`,
      });
    }
  }

  const handleAddProjectBtnClick = () => {
    setShowProjectCreate(true);
  }

  const handleAddProjectCancelBtnClick = () => {
    setShowProjectCreate(false);
    setProjectName('');
  }

  const createProject = async () => {
    let payload = {
      userid: props.userInfo?.id,
      companyid: props.selectedClientId,
      name: projectName
    };
    let response = await APIUtils.callPut('api/project/create', payload);
    
    if(response.status === 200) {
      return response.data.id;
    }
    return props.showModal({
      type: 'error',
      icon: 'user',
      title: 'Creating Project',
      message: `There was an error creating a new project.`,
    });
  }

  const handleContinueBtnClick = async () => {
    if (showProjectCreate) {
      let projectid = await createProject();
      if (!projectid) return;
      props.setSelectedProjectId(projectid);
    }
    setTitleFromProjectId();
    props.changeStep();
  }

  const BtnInput = ({ value, onClick } : {
    value: string; 
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  }) => (
    <button className="btn outline date" onClick={onClick}>
      {value ? value : <>MM/DD/YYYY</>}
    </button>
  );

  useEffect(() => {
    if (props.userInfo === undefined) return;

    debounceRetrieveClients();
  }, [props.userInfo]);

  useEffect(() => {
    if (selectedClient) {
      retrieveClientProjects();
    }
  }, [selectedClient]);

  useEffect(() => {
    if (showProjectCreate) {
      projectInputRef.current.focus();
    }
  }, [showProjectCreate]);

  useEffect(() => {
    if (clients && props.selectedClientId !== -1) {
      handleClientSelect(props.selectedClientId);
    }
  }, [clients, props.selectedClientId]);

  useEffect(() => {
    if (props.contract?.expiryDate) {
      console.log(props.contract?.expiryDate);
      const expire = new Date(props.contract?.expiryDate);

      const duration = `${expire.getMonth() + 1}/${expire.getDate()}/${expire.getFullYear()}`;
      props.updateContractField(duration, 'duration');
    }
  }, [props.contract?.expiryDate]);

  return (
    <>
    {isLoading && (
      <div className="loader">
      </div>
    )}
    {!isLoading && (
      <div id="step1">
        {clients && (
          <div className="fieldWrap">
            <div className="label">Client *</div>
            <Dropdown 
              placeholder={'Client'}
              default={props.selectedClientId}
              values={clients as Client[]}
              update={(value) => handleClientSelect(value)}
              add={handleAddNewClientBtnClick}
            />
          </div>
        )}
        {
          props.selectedClientId !== -1 &&
          contacts && (
            <div className="fieldWrap">
              <div className="label">Contact Person *</div>
              <Dropdown 
                placeholder={'Contact'}
                values={contacts as Contact[]}
                default={props.selectedContactId}
                update={(value) => handleContactSelect(value)}
                add={handleAddNewContactBtnClick}
              />
            </div>
          )
        }
        {
          props.selectedClientId !== -1 &&
          props.selectedContactId !== -1 &&
          projects && (
          <>
          <div className="break"></div>
          <div className="fieldWrap">
            <div className="label">Project *</div>
            {!showProjectCreate && projects && (
              <Dropdown 
                placeholder={'Project'}
                values={projects as Project[]}
                update={(value) => handleProjectSelect(value)}
                default={props.selectedProjectId}
                add={handleAddProjectBtnClick}
              />
            )}
            {showProjectCreate && (
              <>
              <input ref={projectInputRef} type="text" value={projectName} onChange={(e) => setProjectName(e.target.value)} />
              <div className="dropRemove">
                <button className="btn dropdownpRemove" onClick={handleAddProjectCancelBtnClick}>
                  <span className="remove"></span> Cancel
                </button>
              </div>
              </>
            )}
          </div>
          <div className="fieldWrap">
            <div className="label">Contract Type *</div>
            <Dropdown 
              placeholder={'Contract Type'}
              values={['NDA', 'Scope of Work']}
              default={props.contract?.type}
              update={(value) => props.updateContractField(value, 'type')}
            />
          </div>
          <div className="fieldWrap expiryDate">
            <div className="label">Expiry Date *</div>
            <DatePicker
              minDate={moment().toDate()}
              selected={props.contract?.expiryDate ? new Date(props.contract.expiryDate) : null}
              onChange={(date: Date) => props.updateContractField(date, 'expiryDate')}
              customInput={React.createElement(BtnInput)}
            />
          </div>
          </>
        )}
        {
          props.selectedClientId !== -1 && 
          props.selectedContactId !== -1 && 
          (props.selectedProjectId !== -1 || (showProjectCreate && projectName)) && (
            <button className="btn rounded inline" onClick={handleContinueBtnClick}>
              <span>
                Continue
              </span>
            </button>
          )
        }
      </div>
    )}
    </>
  )
}

export default CreateContractStepOne;
