import {useState, useRef, useEffect, createRef} from 'react';

import AddressInput from '../AddressInput';

import {Contact} from '../../../interfaces/client';
import {Modal} from '../../../interfaces/modal';

const ClientModalCopy = (props: {
  modal: Modal;
  close: () => void;
}) => {
  const unitRef = useRef<HTMLInputElement | null>(null);
  const [isCreate, setIsCreate] = useState<boolean>(true);

  const [clientInfo, setClientInfo] = useState<any>({
    name: '',
    address: '',
    unit: '',
    city: '',
    state: '',
    zip: ''
  });

  const updateField = (value: number | string, key: string) => {
    let client: any = {...clientInfo};
    client[key] = value;

    setClientInfo(client);
  };

  const handleAddressSelect = async (address: any) => {
    let client: any = {...clientInfo};
    client['address'] = address.address;
    client['city'] = address.city;
    client['state'] = address.state;
    client['zip'] = address.zip;
    // user['country'] = address.country;

    setClientInfo(client);

    if (unitRef.current) {
      unitRef.current.focus();
    }
  };

  const handleAddClientBtnClick = () => {
    if (props.modal.callback) {
      props.modal.callback(clientInfo);
    }
    props.close();
  };

  useEffect(() => {
    if (props.modal.data) {
      setIsCreate(false);
      setClientInfo(props.modal.data);
    }
  }, [props.modal.data]);

  return (
    <div className="modalCopy">
      <div className="form">
        <h3>
          {isCreate ? 'Add New Client' : 'Edit Client'}
        </h3>
        <div className="message">{props.modal.message}</div>
        <div className="fieldWrap">
          <div className="label">Name *</div>
          <input type="text" value={clientInfo?.name} onChange={(e) => updateField(e.target.value, 'name')} placeholder="Name"></input>
        </div>
        <div className="fieldWrap address">
          <div className="label">Address *</div>
          <div className="split">
            <AddressInput 
              updateAddress={(address) => handleAddressSelect(address)}
              defaultValue={clientInfo?.address}
              placeholder='Address'
            />
            <input ref={unitRef} placeholder="Unit" value={clientInfo?.unit} onChange={(e) => {updateField(e.target.value, 'unit')}}></input>
          </div>
        </div>
        <div className="fieldWrap">
          <div className="split">
            <input placeholder="City" value={clientInfo?.city} onChange={(e) => {updateField(e.target.value, 'city')}}></input>
            <input placeholder="State" value={clientInfo?.state} onChange={(e) => {updateField(e.target.value, 'state')}}></input>
            <input placeholder="Zip" value={clientInfo?.zip} onChange={(e) => {updateField(e.target.value, 'zip')}}></input>
          </div>
        </div>
        <button className="btn rounded" onClick={handleAddClientBtnClick}>
          <span>{isCreate ? 'Add' : 'Update'}</span>
        </button>
      </div>
    </div>
  )
}

export default ClientModalCopy;
