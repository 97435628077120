import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router';
import md5 from 'md5';
import APIUtils from '../../utils/APIUtils';
import NavUtils from '../../utils/NavUtils';
import { ReactComponent as Logo } from '../../assets/img/logo.svg';
import {Modal} from '../../interfaces/modal';

const PasswordReset = (props: {
  reset: Function,
  showModal: (details: Modal) => void,
}) => {
  const [key, setKey] = useState('');
  const [resetError, setResetError] = useState('');

  const [newPass, setNewPass] = useState('');
  const [confirmNewPass, setConfirmNewPass] = useState('');
  const [passMatch, setPassMatch] = useState<boolean>(false);

  const [resetLoading, setResetLoading] = useState(false);

  const getKeyFromQueryParams = () => {
    let params = window.location.pathname.split("/");
    params.shift();
    let filter = params.filter(param => param !== 'reset');
    return filter.join('/');
  }

  const handleResetPasswordEnter = (event: any) => {
    if(event.key === 'Enter'){
      resetPassword();
    }
  }

  const resetPassword = () => {
    setResetLoading(true);
    if(newPass !== confirmNewPass) {
      setResetLoading(false);
      return setResetError('Passwords do not match.');
    }
    const params = {
      key: key,
      password: md5(newPass),
    }
    APIUtils.callPost('api/account/reset', params)
		.then(response => {
      if(response.status !== 200) {
        return props.showModal({
          type: 'error',
          icon: 'user',
          title: 'Password Reset',
          message: response.message,
          redirect: '',
        });
      }
      return props.showModal({
        type: 'success',
        icon: 'user',
        title: 'Password Reset',
        message: 'Password successfully reset.',
        redirect: '/',
      });
    })
		.catch((err) => {
      console.error(err.message);
      return props.showModal({
        type: 'error',
        icon: 'user',
        title: 'Login Error',
        message: err.message,
        redirect: '',
      });
		})
    .finally(() => {
      setResetLoading(false);
    });
  }

  useEffect(() => {
    if (newPass !== '' && confirmNewPass !== '' && newPass === confirmNewPass) {
      setResetError('');
      setPassMatch(true);
    }
  }, [newPass, confirmNewPass]);

  useEffect(() => {
    let paramKey = getKeyFromQueryParams();
    if (paramKey) {
      setKey(paramKey);
    }
  }, []);

  const passStyles = `
    passWrap
    ${passMatch ? 'match' : 'diff'}
    ${newPass !== '' ? 'compare' : ''}
  `;

  return (
    <>
    <div className="formHead">
      <p>Password reset</p>
      <h2>Reset your password</h2>
    </div>

    <div className="formBody">
      {
        resetError && (
          <div className="alert error">
            {resetError}
          </div>
        )
      }
      <div className={`${passStyles} new`}>
        <div className="label">New Password</div>
        <input type="password" onChange={(e) => setNewPass(e.currentTarget.value)} placeholder="Password" autoComplete="off" />
      </div>
      <div className={`${passStyles} confirm`}>
        <div className="label">Confirm New Password</div>
        <input type="password" onChange={(e) => setConfirmNewPass(e.currentTarget.value)} placeholder="Confirm Password" autoComplete="off" onKeyDown={handleResetPasswordEnter} />
      </div>
      {passMatch && (
        <button className="btn rounded inline" onClick={() => resetPassword()}>
          {resetLoading && (
            <span><div className="loader"></div></span>
          )}
          {!resetLoading && (
            <span>Reset Password</span>
          )}
        </button>
      )}
    </div>
    
    <div className="formLink">
      <p>Have an account? <button className="loginLink" onClick={() => props.reset()}>Sign In</button></p>
    </div>
    </>
  );
}

export default PasswordReset;
