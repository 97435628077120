import {useEffect, useState, useCallback} from 'react';

import NavUtils from '../../utils/NavUtils';
import ContractStatus from './ContractStatus';

import {Contract} from '../../interfaces/contract';

import {ReactComponent as ContractIcon} from '../../assets/img/icons/contracts.svg';
import {ReactComponent as SignedIcon} from '../../assets/img/icons/signed.svg';
import {ReactComponent as TimeIcon} from '../../assets/img/icons/time.svg';
import {ReactComponent as DeclinedIcon} from '../../assets/img/icons/declined.svg';

const ContractListItem = (props: {
  contract: Contract;
  backPage?: any;
}) => {
  const [issuedDate, setIssuedDate] = useState('');
  const [issuedTime, setIssuedTime] = useState<string>('');
  const [dueDayCount, setDueDayCount] = useState<string>('');
  const [isExpired, setIsExpired] = useState<boolean>(false);
  const [signedDate, setSignedDate] = useState('');

  const treatAsUTC = (date: Date) => {
    let result = new Date(date);
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
    return Number(result);
  }

  const daysBetween = useCallback((startDate: Date, endDate: Date) => {
    let millisecondsPerDay = 24 * 60 * 60 * 1000;
    let value = Math.round((treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay);
    if(value < 0) {
      setIsExpired(true);
    }
    return value;
  }, []);

  useEffect(() => {
    const monthNames = [
      "Jan", "Feb", "March", "April", "May",
      "June","July", "Aug", "Sept", "Oct", "Nov", "Dec",
    ];
    if(props.contract.issueDate) {
      const issued = new Date(props.contract.issueDate);
      const issuedDate = `${monthNames[issued.getMonth()]} ${issued.getDate().toString()}`;
      setIssuedDate(issuedDate);
      const hour = (issued.getHours() % 12) !== 0 ? (issued.getHours() % 12) : 12;
      const minute = issued.getMinutes() < 10 ? `0${issued.getMinutes()}` : issued.getMinutes();
      const amorpm = (issued.getHours() >= 12) ? "pm" : "am";
      const time = `${hour}:${minute.toString()} ${amorpm}`;
      setIssuedTime(time);
    }
    if(props.contract.expiryDate) {
      const currentDate = new Date();
      const due = new Date(props.contract.expiryDate);
      setDueDayCount(daysBetween(currentDate, due).toString().replace("-",""));
    }
    if(props.contract.signedDate) {
      const paid = new Date(props.contract.signedDate);
      setSignedDate(`${paid.getMonth()}/${paid.getDate()}/${paid.getFullYear()}`)
    }
  }, [props.contract, daysBetween]);

  const handleBackButton = () => {
    NavUtils.redirectToViewContract(props.contract.id, props.backPage);
  }

  const statusClass = `
    ${props.contract.isdraft ? 'draft' : ''}
    ${!props.contract.isdraft && !props.contract.signedDate && !props.contract.declinedDate && !isExpired ? 'pending' : ''}
    ${!props.contract.isdraft && props.contract.signedDate ? 'signed' : ''}
    ${!props.contract.isdraft && !props.contract.signedDate && (props.contract.declinedDate || isExpired) ? 'declined' : ''}
  `;

  return (
    <div className="ListItem" key={props.contract.id} onClick={handleBackButton}>
        <div className="left">
          <div className={`icon ${statusClass}`}>
            {props.contract.isdraft && (
              <ContractIcon />
            )}
            {props.contract.signedDate && (
              <SignedIcon />
            )}
            {props.contract.declinedDate && isExpired && (
              <DeclinedIcon />
            )}
            {!props.contract.signedDate && !props.contract.declinedDate && !props.contract.isdraft && !isExpired && (
              <TimeIcon />
            )}
          </div>
          <div>
            <h4>
              <span className="company">
                {props.contract.clientName}
                {/* {props.contract.recipientcompany ? ` ${props.contract.recipientcompany}` : props.contract.recipientname ? ` ${props.contract.recipientname}` : 'No Recipient'} */}
              </span> 
              <span className="pill">{props.contract.title}</span>
              <span className="pill">{props.contract.type}</span>
            </h4>
            {issuedDate && issuedTime && (
              <div className="date">Sent on {issuedDate} at {issuedTime}</div>
            )}
          </div>
        </div>
        <div className="right">
          <div>
            <ContractStatus 
              contract={props.contract}
            />
          </div>
        </div>
    </div>
  );
}

export default ContractListItem;
