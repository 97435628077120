import _ from 'lodash';
import {UserInfo} from '../../interfaces/user';

import "react-datepicker/dist/react-datepicker.css";

const CreateInvoiceStepFour = (props: {
  userInfo?:  UserInfo | undefined,
  collectingAllInfo: boolean,
  creatingInvoice: boolean,
}) => {
  return (
    <>
        <div className="stepHead">
            <h2>
            <span>Step 4 of 4</span>
            Generating Invoice
            </h2>
            <p>Wait a sec, we're generating your invoice.</p>
        </div>
        <div className="stepsContainer" id="createProjectLoader">
            <div className="item">
            {!props.collectingAllInfo ? (
                <div className="check"></div>
            ) : (
                <div className="loader"></div>
            )}
            <h3>Submitting Info</h3>
            </div>
            <div className="item">
            {!props.creatingInvoice ? (
                <div className="check"></div>
            ) : (
                <div className="loader"></div>
            )}
            <h3>Generating Invoice</h3>
            </div>
        </div>
    </>
  );
}

export default CreateInvoiceStepFour;
