import _ from 'lodash';
import React, {useState, useEffect, useRef} from 'react';
import moment from "moment";
import APIUtils from '../../utils/APIUtils';
import ClientUtils from '../../utils/ClientUtils';

import Dropdown from "../General/Dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {UserInfo} from '../../interfaces/user';
import {Contact, Company} from '../../interfaces/client';
import {Project} from '../../interfaces/project';
import {Modal} from '../../interfaces/modal';

const CreateInvoiceStepOne = (props: {
  invoice: any,
  issueDate: any,
  setIssueDate: any,
  dueDate: any,
  setDueDate: any,
  userInfo?:  UserInfo | undefined,
  updateInvoiceInfo: any,
  handleContinueClick: any,
  selectedClientId: any,
  setSelectedClientId: any,
  setSelectedContactId: any,
  selectedContactId: any,
  selectedProjectId: any,
  setSelectedProjectId: any,
  setNewProject: (name: string) => void,
  projectName: string,
  showModal: (details: Modal) => void,
}) => {
  
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [clients, setClients] = useState<Company[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);

  const [selectedClientName, setSelectedClientName] = useState<string>('');
  const [clientContacts, setClientContacts] = useState<Contact[]>([]);

  const projectInputRef = useRef<any>();
  const [showProjectCreate, setShowProjectCreate] = useState(false);

  const BtnInput = (props : {
    value: string; 
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void}
    ) => (
    <button className="btn outline date" onClick={props.onClick}>
      {props.value ? props.value : <>MM/DD/YYYY</>}
    </button>
  );

  const retrieveClients = async () => {
    let clients: Company[] = await ClientUtils.retrieveClients();
    
    setClients(clients);
    setIsLoading(false);
  };

  const debounceRetrieveClients = _.debounce(retrieveClients, 500);

  const handleAddNewClientBtnClick = () => {
    props.showModal({
      type: 'client',
      icon: 'user',
      title: 'Add Client',
      message: `Fill out the information below to add a new client.`,
      callback: (clientInfo: Company) => handleSaveNewClient(clientInfo)
    });
  }

  const handleSaveNewClient = async (clientInfo: Company) => {
    try {
      let response = await APIUtils.callPost('api/company/create', clientInfo);
      if (response.status !== 200) {
        return props.showModal({
          type: 'error',
          icon: 'user',
          title: 'Creating Client',
          message: `There was an error saving a new client.`,
        });
      }
      await retrieveClients();
      props.setSelectedClientId(response.data);
      return props.showModal({
        type: 'success',
        icon: 'user',
        title: 'Client Created',
        message: `Your new client has been created.`,
      });
    } catch (err) {
      console.error(err);
      return props.showModal({
        type: 'error',
        icon: 'user',
        title: 'Creating Client',
        message: `There was an error saving a new client.`,
      });
    }
  }

  const clientChanged = (id: string | number) => {
    if (typeof id === 'string') {
      id = parseInt(id);
    }
    props.setSelectedClientId(id);
    // props.setSelectedContactId(-1);
    // setClientContacts([]);
  }

  const getClientNameFromId = () => {
    if (props.selectedClientId) {
      let selectedClient = clients.find(client => client.id === props.selectedClientId);
      if (selectedClient) {
        setSelectedClientName(selectedClient?.name);
      }
    }
  };

  const handleAddNewContactBtnClick = () => {
    props.showModal({
      type: 'contact',
      icon: 'user',
      title: 'Add Contact',
      message: `Fill out the information below to add/edit a contact person for ${selectedClientName}`,
      callback: (contactInfo: Contact) => handleSaveNewContact(contactInfo)
    });
  }

  const handleSaveNewContact = async (contactInfo: Contact) => {
    let url = 'api/contact/create';
    contactInfo.companyid = props.selectedClientId;
    try {
      let response = await APIUtils.callPost(url, contactInfo);
      if (response.status !== 200) {
        return props.showModal({
          type: 'error',
          icon: 'user',
          title: 'Creating Contact',
          message: `There was an error creating contact.`,
        });
      }
      props.showModal({
        type: 'success',
        icon: 'user',
        title: 'Contact Created',
        message: `Contact successfully created.`,
      });
      if (clientContacts) {
        let currentContacts = [...clientContacts]
        currentContacts.push(response.data);
        setClientContacts(currentContacts);
      }
      props.setSelectedContactId(response.data.id);
    } catch(err) {
      console.log(err);
      return props.showModal({
        type: 'error',
        icon: 'user',
        title: 'Creating Contact',
        message: `There was an error creating contact.`,
      });
    }
  }

  const retrieveClientProjects = async () => {
    try {
      let url = `api/company/projects/${props.selectedClientId}`;

      let response: any = await APIUtils.callGet(url);
      let projects: Project[] = response.data;
      setProjects(projects);
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const projectChanged = (id: string | number) => {
    props.setSelectedProjectId(id);
  }

  const retrieveClientContacts = async () => {
    try {
      let url = `api/contact/company/${props.selectedClientId}`;
      let response: any = await APIUtils.callGet(url);
      let contacts: Contact[] = response.data;

      setClientContacts(contacts);
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const clientContactChanged = (id: string | number) => {
    props.setSelectedContactId(id);
  }

  const handleAddProjectClick = () => {
    setShowProjectCreate(true);
  }

  const handleContinueClick = async () => {
    props.handleContinueClick();
  }

  useEffect(() => {
    if (showProjectCreate && projectInputRef.current) {
      projectInputRef.current.focus();
    }
  }, [showProjectCreate]);

  useEffect(() => {
    if (props.projectName) {
      setShowProjectCreate(true);
    }
  }, [props.projectName]);

  useEffect(() => {
    if (!props?.userInfo || props.selectedClientId === -1) return;

    retrieveClientContacts();
    retrieveClientProjects();
    getClientNameFromId();
  }, [props.selectedClientId]);

  useEffect(() => {
    if (!props?.userInfo) return;

    debounceRetrieveClients();
  }, [props.userInfo]);

  return (
    <>
      <div className="stepHead">
        <h2>
          <span>Step 1 of 4</span>
          Client & Contact
        </h2>
        <p>Set a client, contact person, and project info for this invoice.</p>
      </div>
      {isLoading && (
        <div className="loader"></div>
      )}
      {!isLoading && (
        <div id="step1">
          <div className="fieldWrap">
            <div className="label">Client *</div>
            <Dropdown 
              placeholder={'Client'}
              values={clients as Company[]}
              update={(value) => clientChanged(value)}
              default={props.selectedClientId}
              add={handleAddNewClientBtnClick}
            />
          </div>
          {
            props.selectedClientId !== -1 && (
              <>
              <div className="fieldWrap">
                <div className="label">Contact Person *</div>
                <Dropdown 
                  placeholder={'Contact Person'}
                  values={clientContacts as Contact[]}
                  update={(value) => clientContactChanged(value)}
                  default={props.selectedContactId}
                  add={handleAddNewContactBtnClick}
                />
              </div>
              </>
            )
          }
          { 
            props.selectedContactId !== -1 && 
            (
              <>
              <div className="break"></div>
              <div className="fieldWrap">
                <div className="label">Project</div>
                {!showProjectCreate && (
                  <Dropdown 
                    placeholder={'Project'}
                    values={projects as Project[]}
                    update={(value) => projectChanged(value)}
                    default={props.selectedProjectId}
                    add={handleAddProjectClick}
                  />
                )}
                {showProjectCreate && (
                  <>
                  <input ref={projectInputRef} type="text" value={props.projectName} onChange={(e) => props.setNewProject(e.target.value)} />
                  <div className="dropRemove">
                    <button className="btn dropdownpRemove" onClick={() => setShowProjectCreate(false)}>
                      <span className="remove"></span> Cancel
                    </button>
                  </div>
                  </>
                )}
              </div>
              <div className="split">
                <div className="fieldWrap">
                  <div className="label">Issue Date *</div>
                  <DatePicker
                    minDate={moment().toDate()}
                    selected={props.issueDate}
                    onChange={(date: Date) => props.setIssueDate(date)}
                    customInput={React.createElement(BtnInput)}
                  />
                </div>
                <div className="fieldWrap">
                  <div className="label">Due Date *</div>
                  <DatePicker
                    minDate={moment().toDate()}
                    selected={props.dueDate}
                    onChange={(date: Date) => props.setDueDate(date)}
                    customInput={React.createElement(BtnInput)}
                  />
                </div>
              </div>
              <button className={`btn rounded stepsContinueBtn`} onClick={handleContinueClick}>
                <span>Continue</span>
              </button>
              </>
            )
          }
        </div>
      )}
    </>
  );
}

export default CreateInvoiceStepOne;
