import {useState, useRef} from 'react';
import {Modal} from '../../../interfaces/modal';

import {ReactComponent as CameraIcon} from '../../../assets/img/icons/camera.svg';

const ImageUploadModal = (props: {
  modal: Modal;
  close: () => void;
}) => {

  const inputFileRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [blob, setBlob] = useState<any>();
  const [imageURL, setImageURL] = useState<string>('');
  const [dragHover, setDragHover] = useState<boolean>(false);

  const allowDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const dropHandler = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    handleImageFileUpload(event.dataTransfer.files);
  };

  const handleUploadBtnClick = () => {
    if(inputFileRef) {
      inputFileRef.current.click();
    }
  };

  const handleImageFileUpload = (filesObject: FileList | null) => {
    if(filesObject !== null) {
      const upload = filesObject[0];
      const fileBlob = new Blob([upload], {type: upload.type});
      // TODO: make server call to upload files to s3 bucket and reference URL
      setImageURL(URL.createObjectURL(fileBlob));
      setBlob(fileBlob);
    }
  };

  const handleSaveBtnClick = () => {
    props.close();
    if (props.modal.callback) {
      props.modal.callback(blob);
    }
  }

  return (
    <div className="modalCopy">
      <h3>{props.modal.title}</h3>
      <div className="message">
        {props.modal.message}
      </div>
      <div 
        className={dragHover ? "imageUpload dragHover" : "imageUpload"}
        onDragOver={allowDrop} 
        onDrop={dropHandler} 
        onClick={() => handleUploadBtnClick()}
        onDragEnter={() => setDragHover(true)}
        onDragLeave={() => setDragHover(false)}>
        <CameraIcon />
        Click or drop 
        <br />
        to upload image
        <input ref={inputFileRef} type="file" className="fileField" accept="image/*" onChange={(event) => handleImageFileUpload(event.target.files)} />
        {imageURL && (
          <div className="imagePreview" style={{backgroundImage: `url(${imageURL})`}}></div>
        )}
      </div>
      <button className="btn rounded" onClick={handleSaveBtnClick}>
        <span>Save</span>
      </button>
    </div>
  )
}

export default ImageUploadModal;