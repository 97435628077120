import {useState, useEffect, useCallback} from 'react';

import NavUtils from '../../utils/NavUtils';
import PaymentUtils from '../../utils/PaymentUtils';
import InvoiceStatus from './InvoiceStatus';

import {Invoice} from '../../interfaces/invoice';

import { ReactComponent as InvoiceIcon } from '../../assets/img/icons/invoices.svg';

const InvoiceListItem = (props: {
  invoice: Invoice;
  company?: string;
  backPage?: string;
}) => {
  const [issuedDate, setIssuedDate] = useState('');
  const [issuedTime, setIssuedTime] = useState<string>('');

  const [isLate, setIsLate] = useState<boolean>(false);

  useEffect(() => {
    const monthNames = [
      "Jan", "Feb", "March", "April", "May",
      "June","July", "Aug", "Sept", "Oct", "Nov", "Dec",
    ];
    if (props.invoice.createdat) {
      const issued = new Date(props.invoice.createdat);
      const issuedDate = `${monthNames[issued.getMonth()]} ${issued.getDate().toString()}`;
      setIssuedDate(issuedDate);
      const hour = (issued.getHours() % 12) !== 0 ? (issued.getHours() % 12) : 12;
      const minute = issued.getMinutes() < 10 ? `0${issued.getMinutes()}` : issued.getMinutes();
      const amorpm = (issued.getHours() >= 12) ? "pm" : "am";
      const time = `${hour}:${minute.toString()} ${amorpm}`;
      setIssuedTime(time);
    }
    if (props.invoice.dueat) {
      const currentDate = new Date();
      const due = new Date(props.invoice.dueat);
      setIsLate(due < currentDate);
    }

  }, [props.invoice]);

  const statusClass = `
    ${props.invoice.isproposal ? 'proposal' : ''}
    ${!props.invoice.isproposal && !props.invoice.issuedat ? 'unsent' : ''}
    ${!props.invoice.isproposal && props.invoice.total && !props.invoice.paidat && !isLate ? 'pending' : ''}
    ${!props.invoice.isproposal && props.invoice.paidat ? 'paid' : ''}
    ${!props.invoice.isproposal && !props.invoice.paidat && isLate ? 'late' : ''}
  `;

  return (
    <>
      <div className="ListItem" key={props.invoice.id} onClick={() => {NavUtils.redirectToEditInvoice(props.invoice.id)}}>
        <div className="left">
          <div className={`icon ${statusClass}`}>
            <InvoiceIcon />
          </div>
          <div>
            <h4>
              <span className="company">{props.company ? props.company : props.invoice?.clientName}</span>
              {props.invoice.subject && (
                <span className="pill">{props.invoice.subject}</span>
              )}
            </h4>
            <div className="date">Created on {issuedDate} - {issuedTime}</div>
          </div>
        </div>
        <div className="right">
          {props.invoice.total && (
            <div className="statusWrap">
              <div className="price">
                {PaymentUtils.formatPrice(props.invoice.total)}
              </div>
            </div>
          )}
          <div>
            <InvoiceStatus 
              invoice={props.invoice}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default InvoiceListItem;
