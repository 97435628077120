import _ from 'lodash';
import {useState, useEffect, useRef} from 'react';

import APIUtils from '../../../utils/APIUtils';
import ClientUtils from '../../../utils/ClientUtils';
import NavUtils from '../../../utils/NavUtils';
import Dropdown from '../../General/Dropdown';

import {Modal} from '../../../interfaces/modal';
import {Contact, Company} from '../../../interfaces/client';
import {Project} from '../../../interfaces/project';

const CompanyContactModal = (props: {
  modal: Modal;
  close: () => void;
}) => {

  const addNewRef = useRef<any>();

  const [clients, setClients] = useState<Company[]>([]);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);

  const [companyId, setCompanyId] = useState<number | undefined>();
  const [contactId, setContactId] = useState<number | undefined>();
  const [projectId, setProjectId] = useState<number | undefined>();

  const [showProjectCreate, setShowProjectCreate] = useState(false);
  const [projectName, setProjectName] = useState<string>('');

  const handleSaveBtnClick = async () => {
    let projectid = projectId;
    if (showProjectCreate) {
      projectid = await createProject();
      setShowProjectCreate(false);
      setProjectName('');
    }
    if (props.modal.callback) {
      let payload = {
        clientid: companyId,
        contactid: contactId,
        projectid: projectid
      }
      props.modal.callback(payload);
      props.close();
    }
  }

  const handleAddContactBtnClick = () => {
    NavUtils.redirectToClientEditor(companyId);
  }

  const retrieveClients = async () => {
    let clients: Company[] = await ClientUtils.retrieveClients();

    setClients(clients);
  };

  const debounceRetrieveClients = _.debounce(retrieveClients, 200);

  const retrieveClientContacts = async (companyid: string | number) => {
    try {
      let response: any = await APIUtils.callGet(`api/contact/company/${companyid}`);
      let contacts: Contact[] = response.data;
      setContacts(contacts);
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const retrieveClientProjects = async (companyid: number) => {
    try {
      let url = `api/company/projects/${companyid}`;

      let response: any = await APIUtils.callGet(url);
      let projects: Project[] = response.data;
      setProjects(projects);
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const handleCompanySelect = (value: string | number) => {
    setContactId(-1);
    setProjectId(-1);
    setCompanyId(value as number);
    retrieveClientContacts(value);
  }

  const createProject = async () => {
    let payload = {
      userid: props.modal.data.userid,
      companyid: companyId,
      name: projectName,
      status: 'draft'
    };

    let response = await APIUtils.callPut('api/project/create', payload);
    
    if(response.status === 200) {
      return response.data.id;
    }
  }

  useEffect(() => {
    debounceRetrieveClients();
    setContacts(props.modal.data.client.contacts);
    setCompanyId(props.modal.data.client.id);
    setContactId(props.modal.data.contactid);
    setProjectId(props.modal.data.projectid);
  }, [props.modal.data]);

  useEffect(() => {
    if (companyId) {
      retrieveClientContacts(companyId);
      retrieveClientProjects(companyId);
    }
  }, [clients, companyId]);

  useEffect(() => {
    if (showProjectCreate) {
      addNewRef.current.focus();
    }
  }, [showProjectCreate]);

  return (
    <>
    <div className="modalCopy">
      <h3>{props.modal.title}</h3>
      <div className="message">
        {props.modal.message}
      </div>
      <div className="fieldWrap">
        <div className="label">Client</div>
          <Dropdown 
            placeholder={'Client'}
            values={clients as Company[]}
            update={(value) => handleCompanySelect(value)}
            default={companyId}
          />
      </div>
      <div className="fieldWrap">
        <div className="label">Contact Person</div>
        {contacts.length > 0 && (
          <Dropdown 
            placeholder={'Contact Person'}
            values={contacts as Contact[]}
            update={(value) => setContactId(value as number)}
            default={contactId}
          />
        )}
        {contacts.length === 0 && (
          <button className="btn rounded outline green" onClick={handleAddContactBtnClick}>
            Add Contact
          </button>
        )}
      </div>
      <div className="fieldWrap">
        <div className="label">Project</div>
        {!showProjectCreate && projects && projects.length > 0 && (
          <Dropdown 
            placeholder={'Project'}
            values={projects as Project[]}
            update={(value) => setProjectId(value as number)}
            default={projectId}
            add={() => setShowProjectCreate(true)}
          />
        )}
        {showProjectCreate && (
          <input ref={addNewRef} type="text" value={projectName} onChange={(e) => setProjectName(e.target.value)} placeholder="Project Name" />
        )}
      </div>
      {companyId && contactId !== -1 && (
        <button className="btn rounded" onClick={handleSaveBtnClick}>
          <span>Save</span>
        </button>
      )}
    </div>
    </>
  )
}

export default CompanyContactModal;