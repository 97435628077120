import {useState, useEffect} from 'react';

import APIUtils from '../../utils/APIUtils';
// import ContractVariables from './ContractVariables';

import {Contract} from '../../interfaces/contract';
import {UserInfo} from '../../interfaces/user';

// import SignatureUtils from '../../utils/SignatureUtils';
// import SignatureCanvas from 'react-signature-canvas';

import {ReactComponent as Logo} from '../../assets/img/logo.svg';

const CreateContractStepTwo = (props: {
  userInfo: UserInfo | undefined,
  contract: Contract | undefined;
  selectedContactId: any,
  selectedProjectId: any,
  updateContractField: (value: Date | string, field: string) => void;
  submit: () => void;
}) => {

  const [updatedContract, setUpdatedContract] = useState<any>();
  const [seededTemplateHTML, setSeededTemplateHTML] = useState<any>();

  const getTemplate = async () => {
    let payload = {
      ...props.contract,
      contactid: props.selectedContactId,
      projectid: props.selectedProjectId
    }
    setUpdatedContract(payload);

    let response = await APIUtils.callPost('api/contract/template/generate', payload);

    if (response.status === 200) {
      updateTemplateHTML(response.data.previewTemplate);
      setSeededTemplateHTML({
        __html: response.data.previewTemplate
      });
    }
  }

  const updateTemplateHTML = (value: any) => {
    let contract = {...updatedContract}
    contract['template'] = value;
    setUpdatedContract(contract);
  }

  useEffect(() => {
    if (!props.contract) return;

    getTemplate();
  }, [props.contract]);

  return (
    <>
    <div className="box">
      <Logo />
      <div className="contractWrap" contentEditable={true} dangerouslySetInnerHTML={seededTemplateHTML} onInput={e => props.updateContractField(e.currentTarget.innerHTML, 'template')}></div>
    </div>
    <button className="btn rounded inline contractSaveBtn" onClick={() => props.submit()}>
      <span>
        Create Contract
      </span>
    </button>
    </>
  )
}

export default CreateContractStepTwo;
