import APIUtils from '../utils/APIUtils';

class PaymentUtils {
  static formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
  });

  static formatPrice(value) {
    let price = PaymentUtils.formatter.format(Number(value)).replace("$","");
    return (
      <>
        <span className="ticker">$</span>
        {price}
      </>
    );
  }

  static async declineProposal(invoice, showModal) {
    const payload = {
      hash: invoice.hash,
      decline: true,
    };

    const response = await APIUtils.callPost(`api/invoice_approve/${invoice.id}`, payload);
    if (response.status === 200) {
      return showModal({
        type: 'success',
        icon: 'invoice',
        title: `Proposal Declined`,
        message: 'This proposal has been declined.',
        // redirect: `/invoice/view/${response.data.id}?hash=${response.data.hash}`,
      });
    }

    showModal({
      type: 'error',
      icon: 'invoice',
      title: `Declining Proposal`,
      message: 'Unable to decline proposal.',
      // redirect: `/invoice/view/${response.data.id}?hash=${response.data.hash}`,
    });
  };

  static async approveProposal(invoice, showModal) {
    const payload = {
      hash: invoice.hash
    };

    const response = await APIUtils.callPost(`api/invoice_approve/${invoice.id}`, payload);
    if (response.status === 200) {
      return showModal({
        type: 'success',
        icon: 'invoice',
        title: `Proposal Approved`,
        message: 'This proposal has been approved.',
        // redirect: `/invoice/view/${response.data.id}?hash=${response.data.hash}`,
      });
    }

    showModal({
      type: 'error',
      icon: 'invoice',
      title: `Approving Proposal`,
      message: 'Unable to approve proposal.',
      // redirect: `/invoice/view/${response.data.id}?hash=${response.data.hash}`,
    });
  };
}

export default PaymentUtils;