import {useState, useEffect, useRef} from 'react';
import NavUtils from '../../utils/NavUtils';

import {Client} from '../../interfaces/client';

const ClientListItem = (props: {
  client: Client,
  source: any,
}) => {
  const editBtnRef = useRef<any>();
  const [client, setClient] = useState<Client>();

  const handleClientCardClick = (e: any) => {
    if (editBtnRef.current && editBtnRef.current !== e.target) {
      return NavUtils.redirectToSingleClient(client?.id, props.source)
    }
    return NavUtils.redirectToSingleClient(client?.id, props.source)
  }
  
  useEffect(() => {
    if (props.client) {
      setClient(props.client);
    }
  }, [props]);

  return (
    <div className="card" onClick={(e) => handleClientCardClick(e)}>
      <div className="top">
        {client?.name}
        <button ref={editBtnRef} className="btn rounded outline icon" onClick={(e) => handleClientCardClick(e)}>
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
      <div className="stats">
        <div className="status draft">{client?.projectCount} projects</div>
        <div className="status draft">{client?.contacts?.length} contacts</div>
      </div>
    </div>
  )
}

export default ClientListItem;
