import React from 'react';
import PaymentUtils from '../../utils/PaymentUtils';

const AdminInvoiceMetrics = (props: {
  count: number,
  total: number,
  pending: number,
  paid: number,
  late: number,
}) => {
  
  return (
    <div className="metricWrap">
      <div className="headerWrap">
        <h2>Invoices</h2>
      </div>
      <div className="adminStats" id="adminInvoiceStats">
        <div className="box">
          <div className="metric">{props.count}</div>
          <span className="label">Total</span>
        </div>
        <div className="box">
          <div className="metric price">{PaymentUtils.formatPrice(props.paid)}</div>
          <span className="label">Paid</span>
        </div>
        <div className="box">
          <div className="metric price">{PaymentUtils.formatPrice(props.pending)}</div>
          <span className="label">Pending</span>
        </div>
        <div className="box warn">
          <div className="metric price">{PaymentUtils.formatPrice(props.late)}</div>
          <span className="label">Outstanding</span>
        </div>
      </div>
    </div>
  );
}

export default AdminInvoiceMetrics;
