import {useState, useEffect} from 'react';
import PhoneNumberInput from '@bebeau/phone-number-input';

import {Modal} from '../../../interfaces/modal';
import {Contact} from '../../../interfaces/client';

const ContactModalCopy = (props: {
  modal: Modal,
  close: () => void;
}) => {
  const [isCreate, setIsCreate] = useState(true);
  const [contactInfo, setContactInfo] = useState<Contact>({
    email: '',
    firstname: '',
    lastname: '',
    phone: ''
  });
  const [contactPhone, setContactPhone] = useState<string>('');

  const updateField = (value: number | string, key: string) => {
    let contact: any = {...contactInfo};
    contact[key] = value;

    setContactInfo(contact);
  }

  const handleBtnClick = () => {
    let payload: any = {...contactInfo};
    payload['phone'] = contactPhone;

    if (props.modal.callback) {
      props.modal.callback(payload);
    }

    props.close();
  }

  useEffect(() => {
    setIsCreate(true);
    setContactInfo({
      email: '',
      firstname: '',
      lastname: '',
      phone: ''
    });

    if (typeof props.modal.data !== 'undefined') {
      setIsCreate(false);
      setContactInfo(props.modal.data);
    }
  }, [props.modal.data]);

  return (
    <>
    <div className="modalCopy">
      <div className="form">
        <h3>
          {isCreate ? 'Add Contact Person' : 'Edit Contact Person'}
        </h3>
        <div className="message">{props.modal.message}</div>
        <div className="fieldWrap">
          <div className="label">Email *</div>
          <input type="email" value={contactInfo.email} placeholder="email@address..." onChange={(e) => updateField(e.target.value, 'email')} />
        </div>
        <div className="break"></div>
        <div className="split">
          <div className="fieldWrap">
            <div className="label">First Name</div>
            <input type="email" value={contactInfo.firstname ? contactInfo.firstname : ''} placeholder="First Name" onChange={(e) => updateField(e.target.value, 'firstname')} />
          </div>
          <div className="fieldWrap">
            <div className="label">Last Name</div>
            <input type="email" value={contactInfo.lastname ? contactInfo.lastname : ''} placeholder="Last Name" onChange={(e) => updateField(e.target.value, 'lastname')} />
          </div>
        </div>
        <div className="fieldWrap">
          <div className="label">Phone</div>
          <PhoneNumberInput 
            onInputChange={(data: {
              number: string,
              isValid: boolean,
            }) => setContactPhone(data.number)}
            value={contactInfo.phone}
            placeholder='Phone Number'
          />
        </div>
        <button className="btn rounded" onClick={handleBtnClick}>
          {isCreate && (
            <span>Add</span>
          )}
          {!isCreate && (
            <span>Update</span>
          )}
        </button>
      </div>
    </div>
    </>
  )
}

export default ContactModalCopy;