class NavUtils {
	static redirectToRoot() {
		if (window.location.href !== '/') {
			window.location.href = '/';
		}
	}

	static redirectToProfile(username) {
		if (window.location.href !== `/${username}`) {
			window.location.href = `/${username}`;
		}
	}

	static redirectToDashboard() {
		window.location.href = '/dashboard';
	}

	static redirectToMyPlan() {
		window.location.href = '/account-settings?tab=plan';
	}

	static redirectToAdminDash() {
		if (window.location.href !== '/admin') {
			window.location.href = '/admin';
		}
	}

	static redirectToAccountSettings() {
		if (window.location.href !== '/account-settings') {
			window.location.href = '/account-settings';
		}
	}

	static redirectToClientListing() {
		if (window.location.href !== `/clients`) {
			window.location.href = `/clients`;
		}
	}

	static redirectToVerifyEmail() {
		if (window.location.href !== `/verify-email`) {
			window.location.href = `/verify-email`;
		}
	}

	static redirectToSingleClient(clientId, backPage='') {
		if (clientId) {
			if (window.location.href !== `/client/${clientId}`) {
				let url = `/client/${clientId}`;
				if (backPage) {
					url += `?return=${encodeURIComponent(backPage)}`;
				}
				window.location.href = url;
			}
		}
		if (!clientId) {
			if (window.location.href !== `/client/create`) {
				window.location.href = `/client/create`;
			}
		}
	}

	static redirectToClientEditor(clientId, source=null) {
		if (clientId) {
			if (window.location.href !== `/client/edit/${clientId}`) {
				if (!source) {
					window.location.href = `/client/edit/${clientId}`;
				} else {
					window.location.href = `/client/edit/${clientId}?source=${source}`;
				}
			}
		}
		if (!clientId) {
			if (window.location.href !== `/client/create`) {
				window.location.href = `/client/create`;
			}
		}
	}

	static redirectToContractListing(filter) {
		if (window.location.pathname !== `/contracts?filter=${filter}`) {
			let url = `/contracts?filter=${filter}`;
			window.open(url, '_self');
		}
	}

	static redirectToInvoiceListing(filter) {
		if (window.location.pathname !== `/invoices?filter=${filter}`) {
			let url = `/invoices?filter=${filter}`;
			window.open(url, '_self');
		}
	}

	static redirectToAddContract(source=null) {
		if (window.location.pathname !== '/contract/create') {
			let url = '/contract/create';
			if (source) {
				url += `?source=${source}`;
			}

			window.location.href = url;
		}
	}

	static redirectToViewContract(contractId, backPage=null) {
		let url = '/contract/view/' + contractId;

		if (backPage) {
			url += `?return=${encodeURIComponent(backPage)}`;
		}

		window.location.href = url;
	}

	static redirectToEditContract(contractId) {
		if (window.location.pathname !== '/contract/edit/' + contractId) {
			window.location.pathname = '/contract/edit/' + contractId;
		}
	}

	static redirectToPublicContract(contractId, hash='') {
		let url = '/contract/' + contractId;
		if (hash) {
			url = `${url}?hash=${hash}`;
		}
		if (window.location.pathname !== url) {
			window.open(url, '_blank');
		}
	}

	static redirectToPublicInvoice(username, invoiceId, hash) {
		if (window.location.pathname !== '/' + username + '/invoice/' + invoiceId) {
			let url = '/' + username + '/invoice/' + invoiceId;
			if (hash) {
				url = `${url}?hash=${hash}`;
			}
			window.open(url, '_blank');
		}
	}

	static redirectToCreateInvoice(backPage=null) {
		let url = '/invoice/create';
		if (backPage) {
			url += `?source=${backPage}`;
		}

		window.location.href = url;
	}

	static redirectToCreateProposal() {
		if (window.location.href !== '/proposal/create') {
			window.location.href = '/proposal/create';
		}
	}

	static redirectToPayments() {
		if (window.location.href !== '/invoices/') {
			window.location.href = '/invoices/';
		}
	}

	static redirectToContracts() {
		if (window.location.href !== '/contracts/') {
			window.location.href = '/contracts/';
		}
	}

	static redirectToInvoices() {
		if (window.location.href !== '/invoices/') {
			window.location.href = '/invoices/';
		}
	}

	static redirectToViewInvoice(invoiceId, hash, backPage='') {
		if (window.location.pathname !== '/invoice/view/' + invoiceId) {
			let url = '/invoice/view/' + invoiceId;
			if (hash) {
				url = `${url}?hash=${hash}`;
			}
			if (backPage) {
				if (hash) {
					url += '&';
				} else {
					url += '?';
				}

				url += `return=${encodeURIComponent(backPage)}`;
			}

			window.location.href = url;
		}
	}

	static redirectToProjects() {
		if (window.location.href !== '/projects/') {
			window.location.href = '/projects/';
		}
	}

	static redirectToViewProject(projectId, backPage='') {
		if (window.location.pathname !== '/projects/' + projectId) {
			let url = '/projects/' + projectId;
			if (backPage) {
				url += `?return=${encodeURIComponent(backPage)}`;
			}
			window.location.href = url;
		}
	}

	static redirectToCreateProject() {
		if (window.location.href !== '/project/create') {
			window.location.href = '/project/create';
		}
	}

	static redirectToEditInvoice(invoiceId) {
		if (window.location.href !== '/invoice/edit/' + invoiceId) {
			window.location.href = '/invoice/edit/' + invoiceId;
		}
	}

	static redirectToEditProposal(invoiceId) {
		if (window.location.href !== '/proposal/edit/' + invoiceId) {
			window.location.href = '/proposal/edit/' + invoiceId;
		}
	}

	static redirectToActivityItem(type, id, hash='', backPage='') {
		let url = '/' + type + '/view/' + id;

		if (type === 'invoice') {
			url = '/' + type + '/edit/' + id;
		}

		if (hash) {
			url = `${url}?hash=${hash}`;
		}

		if (backPage) {
			if (hash) {
				url += '&';
			} else {
				url += '?';
			}
			url += `return=${encodeURIComponent(backPage)}`;
		}

		window.location.href = url;
	}
}

export default NavUtils;