import _ from 'lodash';
import {Key, useEffect, useState} from 'react';
import ClientUtils from '../../utils/ClientUtils';

import DashHead from '../Admin/DashHead';
import ClientLoader from './ClientLoader';
import ClientListItem from './ClientListItem';

import {UserInfo} from '../../interfaces/user';
import {Modal} from '../../interfaces/modal';
import {Client} from '../../interfaces/client';

const ClientListing = (props: {
  userInfo?: UserInfo | undefined;
  isLoading: boolean;
  showModal: (details: Modal) => void;
}) => {
  const [clientLoading, setClientLoading] = useState<boolean>(true);
  const [clients, setClients] = useState<Client[]>([]);

  const retrieveClients = _.debounce(async () => {
    let clients: Client[] = await ClientUtils.retrieveClients();
    
    setClients(clients);
    setClientLoading(false);
  }, 500);

  useEffect(() => {
    if (props.userInfo === undefined) return;

    retrieveClients();
  }, [props.userInfo]);

  return (
    <>
    {props.isLoading && (
      <div className="loader"></div>
    )}
    {!props.isLoading && props.userInfo && (
      <div className="componentWrap" id="clients">
        <DashHead
          pageTitle="Clients"
          userInfo={props.userInfo}
          create={'/client/create'}
        />
        <div className="clientListing">
          {clientLoading && (
            <ClientLoader />
          )}
          {!clientLoading && clients &&
            <>
              {
                clients.length > 0 ? 
                clients.map((item: Client, i: Key) => {
                  return (
                    <ClientListItem
                      key={`client-${i}`}
                      client={item}
                      source="client"
                    />
                  ) 
                })
                :
                <p className="placeholder">No clients found.</p>
              }
            </>
          }
        </div>
      </div>
    )}
    </>
  )
}

export default ClientListing;