import _ from 'lodash';
import {useState, useEffect} from 'react';
import APIUtils from '../../utils/APIUtils';

import {Modal} from '../../interfaces/modal';

import {ReactComponent as EmailIcon} from '../../assets/img/icons/envelope.svg';
import bg from '../../assets/img/dashboard-bg.png';

const VerifyEmail = (props: {
    showModal: (details: Modal) => void;
}) => {
    const [code, setCode] = useState<string>('');
    const [userEmail, setUserEmail] = useState<any>();
    const [showValidating, setShowValidating] = useState<boolean>(false);

    const getUnverifiedUserInfo = _.debounce(async () => {
        let response = await APIUtils.callGet('api/account/verify_email');

        if (response.status === 200) {
            setUserEmail(response.data.email);
        }
    }, 500);

    const checkVerificationCode = _.debounce(async (code) => {
        let response = await APIUtils.callPost('api/account/verify_email', {code});

        if (response.status === 200 && response.data?.verified) {
            return props.showModal({
                type: 'success',
                icon: 'user',
                title: `Verification Complete`,
                message: 'Email Verified!',
                redirect: 'dashboard',
            });
        }

        return props.showModal({
            type: 'error',
            icon: 'user',
            title: `Verification Failed`,
            message: 'Unable to verify email!',
            redirect: '',
        });
    }, 500);

    const resendVerificationEmail = async () => {
        let response = await APIUtils.callGet('api/account/send_verification_email');
        if (response.status === 200 && response.data?.sent) {
            return props.showModal({
                type: 'success',
                icon: 'user',
                title: `Sending Verification`,
                message: 'Verification email sent!',
                redirect: '',
            });
        }
        return props.showModal({
            type: 'error',
            icon: 'user',
            title: `Sending Verification`,
            message: 'Unable to send verification email!',
            redirect: '',
        });
    };

    const updateCode = (code: string) => {
        if (code.length > 6) {
            code = code.substring(0, 6);
        }
        
        setCode(code);

        if (code.length === 6) {
            checkVerificationCode(code);
        }
    }

    useEffect(() => {
        getUnverifiedUserInfo();
    }, []);

  return (
    <div className="componentWrap" id="verifyEmail" style={{'backgroundImage': `url(${bg})`}}>
        <div className="box">
            <h1>Email Verification</h1>
            <div className="icon">
                <EmailIcon />
            </div>
            <h3>{userEmail}</h3>
            <p>Enter the 6-digit code sent to the email above to verify your email address and access your account.</p>
            <input className="emailVerifyInput" type="number" onChange={(e) => {updateCode(e.currentTarget.value)}} value={code} placeholder="Enter Code" />
            {showValidating && 
                <div>Verifying Verification Code...</div>
            }
            <button onClick={resendVerificationEmail} className='btn rounded inline'>
                <span>Resend</span>
            </button>
        </div>
    </div>
  );
}

export default VerifyEmail;
