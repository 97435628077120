import React from 'react';

const ListItemLoading = () => {
  return (
    <>
      <div className="ListItem disabled loading">
        <div className="left">
          <div className="icon"></div>
          <div>
            <div className="title"></div>
            <div className="sub"></div>
          </div>
        </div>
        <div className="right">
          <div className="pill"></div>
          <div className="pill"></div>
        </div>
      </div>
      <div className="ListItem disabled loading">
        <div className="left">
          <div className="icon"></div>
          <div>
            <div className="title"></div>
            <div className="sub"></div>
          </div>
        </div>
        <div className="right">
          <div className="pill"></div>
          <div className="pill"></div>
        </div>
      </div>
      <div className="ListItem disabled loading">
        <div className="left">
          <div className="icon"></div>
          <div>
            <div className="title"></div>
            <div className="sub"></div>
          </div>
        </div>
        <div className="right">
          <div className="pill"></div>
          <div className="pill"></div>
        </div>
      </div>
      <div className="ListItem disabled loading">
        <div className="left">
          <div className="icon"></div>
          <div>
            <div className="title"></div>
            <div className="sub"></div>
          </div>
        </div>
        <div className="right">
          <div className="pill"></div>
          <div className="pill"></div>
        </div>
      </div>
      <div className="ListItem disabled loading">
        <div className="left">
          <div className="icon"></div>
          <div>
            <div className="title"></div>
            <div className="sub"></div>
          </div>
        </div>
        <div className="right">
          <div className="pill"></div>
          <div className="pill"></div>
        </div>
      </div>
    </>
  )
}
export default ListItemLoading;
