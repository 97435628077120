import React from 'react';

const AdminUserMetrics = (props: {
  active: number,
  deleted: number,
  total: number,
  growth: number,
}) => {
  return (
    <div className="metricWrap">
      <div className="headerWrap">
        <h2>Users</h2>
      </div>
      <div className="adminStats" id="adminUserStats">
        <div className="box">
          <div className="metric">{props.total}</div>
          <span className="label">Total</span>
        </div>
        <div className="box">
          <div className="metric">{props.active}</div>
          <span className="label">Active</span>
        </div>
        <div className="box warn">
          <div className="metric">{props.deleted}</div>
          <span className="label">Deleted</span>
        </div>
        <div className="box">
          <div className="metric">{props.growth}%</div>
          <span className="label">Weekly Growth</span>
        </div>
      </div>
    </div>
  );
}

export default AdminUserMetrics;
