import {useState, useCallback, useEffect, useRef} from 'react';

import APIUtils from '../../utils/APIUtils';
import NavUtils from '../../utils/NavUtils';

import {UserInfo} from '../../interfaces/user';
import {Modal} from '../../interfaces/modal';

import {ReactComponent as IndividualIcon} from '../../assets/img/individual.svg';
import {ReactComponent as SettingsIcon} from '../../assets/img/icons/settings.svg';
import {ReactComponent as LogoutIcon} from '../../assets/img/icons/logout.svg';

const DashHead = (props: {
  pageTitle: string;
  pageSubTitle?: string;
  userInfo: UserInfo | undefined;
  create?: string;
  status?: string;
  back?: () => void;
  showModal?: (details: Modal) => void;
}) => {
  const navRef = useRef<any>();
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const redirectToCreate = () => {
    if (props.create === 'project') {
      if (props.showModal) {
        props.showModal({
          type: 'project',
          icon: 'contract',
          title: `Start A New Project`,
          message: 'Choose how you would like to create a new project.',
          callback: (option) => handleProjectCreate(option),
        })
      }
    }
    if (props.create && props.create !== 'project') {
      window.location.href = props.create;
    }
  }

  const handleProjectCreate = (option: string) => {
    if (option === 'link' && props.showModal) {
      return props.showModal({
        type: 'potion',
        icon: 'user',
        title: `Send Potion`,
        message: 'Send a link to your client to complete the brief and your project will be generated automatically.',
        data: {username: props.userInfo?.username},
      })
    }
    if (option === 'manual' && props.showModal) {
      return NavUtils.redirectToCreateProject();
    }
  }

  const handleProfileClick = () => {
    setShowMenu(!showMenu)
  }

  const handleClickOutside = useCallback((event: any) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  }, [props]);

  const logout = () => {
		APIUtils.callGet('api/account/logout')
		.then(() => {
			NavUtils.redirectToRoot();
		})
		.catch(err => {
      console.log(err);
			NavUtils.redirectToRoot();
		});
	}

  useEffect(() => {
    window.addEventListener('mousedown', (e) => {handleClickOutside(e)});
    return () => {
      window.removeEventListener('mousedown', (e) => {handleClickOutside(e)});
    };
  }, [handleClickOutside]);

  return (
    <div id="dashHead" className={showMenu ? 'showMenu' :  ''}>
      <div className="currentPageTitle">
        {props.back && (
          <button className="btn rounded outline icon back" onClick={props.back}></button>
        )}
        <h1>
          {props.pageTitle}
          {props.pageSubTitle && (
            <span className="subTitle">{props.pageSubTitle}</span>
          )}
        </h1>
        {props.status && (
          <div className="status">
            {props.status}
          </div>
        )}
        {props.create && (
          <button className="btn outline rounded icon add" onClick={() => redirectToCreate()}>
            <span></span>
            <span></span>
          </button>
        )}
      </div>
      <div ref={navRef} className="currentUser" onClick={handleProfileClick}>
        <div className="menu">
          <nav>
            <a href="/account-settings">
              <SettingsIcon /> Settings
            </a>
            <button onClick={logout}>
              <LogoutIcon /> Logout
            </button>
          </nav>
        </div>
        <div className="user">
          <div className="image" style={{backgroundImage: `url(${props.userInfo?.image})`}}>
            {!props.userInfo?.image && (
              <IndividualIcon />
            )}
          </div>
          <div>
            <div className="name">{props.userInfo?.firstname} {props.userInfo?.lastname}</div>
            <div className="username">@{props.userInfo?.username}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashHead;
