import React from 'react';

const AdminContractMetrics = (props: {
  count: number,
  pending: number,
  signed: number,
  declined: number,
}) => {
  
  return (
    <div className="metricWrap">
      <div className="headerWrap">
        <h2>Contracts</h2>
      </div>
      <div className="adminStats" id="adminInvoiceStats">
        <div className="box">
          <div className="metric">{props.count}</div>
          <span className="label">Total</span>
        </div>
        <div className="box">
          <div className="metric">{props.signed}</div>
          <span className="label">Signed</span>
        </div>
        <div className="box">
          <div className="metric">{props.pending}</div>
          <span className="label">Pending</span>
        </div>
        <div className="box warn">
          <div className="metric">{props.declined}</div>
          <span className="label">Declined</span>
        </div>
      </div>
    </div>
  );
}

export default AdminContractMetrics;
