import {Key, useState, useEffect} from 'react';

import ListItemLoading from '../Admin/ListItemLoading';
import APIUtils from '../../utils/APIUtils';
import Filters from '../General/Filters';
import InvoiceListItem from '../Payment/InvoiceListItem';

import {Invoice} from '../../interfaces/invoice';

const SingleProjectInvoices = (props: {
  projectid: Key | undefined,
  invoices: Invoice[],
  company?: string
}) => {
  const [invoiceLoading, setInvoiceLoading] = useState<boolean>(true);
  const [invoices, setInvoices] = useState<Invoice[]>();
  const [filter, setFilter] = useState<string>('');

  const retrieveProjectInvoices = async (id: Key | undefined) => {
    try {
      let url = `api/project/invoices/${id}`;

      if (filter.trim().length) {
        url += `?filter=${filter}`
      }

      let response: any = await APIUtils.callGet(url);
      let invoices: Invoice[] = response.data;
      
      setInvoices(invoices);
      setTimeout(() => setInvoiceLoading(false), 500);
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const updateSelectedFilterText = (filter: string) => {
    if (filter === 'All') {
      filter = '';
    }
    setFilter(filter.toLowerCase());
  }

  useEffect(() => {
    if(props.invoices && props.invoices.length > 0) {
      setInvoices(props.invoices);
      setInvoiceLoading(false);
    }
  }, [props.invoices]);

  useEffect(() => {
    setInvoiceLoading(true);
    retrieveProjectInvoices(props.projectid);
  }, [filter]);

  return (
    <>
    <Filters 
      filterValues={['All', 'Drafts', 'Pending', 'Paid', 'Overdue']}
      selectedFilter={filter}
      setSelectedFilter={(filter) => updateSelectedFilterText(filter)}
      showDraftsAsProposals={true}
    />
    {invoiceLoading && (
      <ListItemLoading />
    )}
    { !invoiceLoading &&
      invoices &&
      invoices.length > 0 &&
      invoices.map((invoice: Invoice, index: number) => {
        return(
          <InvoiceListItem 
            key={`invoice-${index}`}
            invoice={invoice}
            company={props.company}
            backPage={`/projects/${props.projectid}`}
          />
        )
      })
    }
    { invoices &&
      invoices.length === 0 && (
      <div className="placeholder">No {filter} invoices.</div>
    )}
    </>
  );
}

export default SingleProjectInvoices;
