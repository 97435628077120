import {useState, useEffect, useRef} from 'react';

import DashHead from '../Admin/DashHead';
import APIUtils from '../../utils/APIUtils';
import NavUtils from '../../utils/NavUtils';

import CreateInvoiceStepOne from './CreateInvoiceStepOne';
import CreateInvoiceStepTwo from './CreateInvoiceStepTwo';
import CreateInvoiceStepThree from './CreateInvoiceStepThree';
import CreateInvoiceStepFour from './CreateInvoiceStepFour';

import {UserInfo} from '../../interfaces/user';
import {Modal} from '../../interfaces/modal';

const CreateInvoiceWizard = (props: {
  userInfo?:  UserInfo | undefined,
  isLoading:  boolean,
  isProposal: boolean,
  showModal:  (details: Modal) => void,
}) => {

  const [invoice, setInvoice] = useState<any>({
    issueDate: '',
    dueDate: '',
    billTo: '',
    discount: '0',
    tax: '',
    notes: '',
    items: [],
    subject: '',
  });

  const [currentStep, setCurrentStep] = useState<number>(1);
  const [invoiceItems, setInvoiceItems] = useState<any[]>([{
    description: '',
    qty: 1,
    price: 1000
  }]);
  const wizardRef = useRef<any>();
  const [creatingInvoice, setCreatingInvoice] = useState<boolean>(true);
  const [collectingAllInfo, setCollectingAllInfo] = useState<boolean>(true);
  const [selectedClientId, setSelectedClientId] = useState<any>(-1);
  const [selectedContactId, setSelectedContactId] = useState<any>(-1);
  const [selectedProjectId, setSelectedProjectId] = useState<any>(-1);
  const [newProject, setNewProject] = useState<string>('');
  const [issueDate, setIssueDate] = useState<Date | null | undefined>(new Date());
  const [dueDate, setDueDate] = useState<Date | null | undefined>(null);

  const smartSetIssueDate = (issueDate: Date | null | undefined) => {
    setIssueDate(issueDate);

    if ((dueDate && issueDate) && (dueDate < issueDate)) {
      setDueDate(issueDate);
    }
  }

  const smartSetDueDate = (dueDate: Date | null | undefined) => {
    setDueDate(dueDate);
    if ((dueDate && issueDate) && (dueDate < issueDate)) {
      setDueDate(issueDate);
    }
  }

  const updateInvoiceInfo = (key: string, value: any) => {
    let localInvoice = {...invoice};

    localInvoice[key] = value;
    setInvoice(localInvoice);
  };

  const setInfoCollected = () => {
    setTimeout(() => {
      setCollectingAllInfo(false);
    }, 1000);
  };

  const createProject = async () => {
    let payload = {
      userid: props.userInfo?.id,
      companyid: selectedClientId,
      name: newProject,
      status: 'draft'
    };

    try {
      let response = await APIUtils.callPut('api/project/create', payload);
      
      if(response.status === 200) {
        return response.data.id;
      }

      return props.showModal({
        type: 'error',
        icon: 'invoice',
        title: `Creating ${props.isProposal ? 'Proposal' : 'Invoice'}`,
        message: `Unable to Create ${props.isProposal ? 'Proposal' : 'Invoice'}.`,
      });

    } catch (err) {
      console.error(err);
      return props.showModal({
        type: 'error',
        icon: 'invoice',
        title: `Creating ${props.isProposal ? 'Proposal' : 'Invoice'}`,
        message: `Unable to Create ${props.isProposal ? 'Proposal' : 'Invoice'}.`,
      });
    }
  }

  const createInvoice = async () => {
    let projectID = selectedProjectId;
    if (newProject) {
      projectID = await createProject();
    }

    let payload: any = {
      userid: props.userInfo?.id,
      dueat: dueDate,
      issuedat: issueDate,
      discount: parseFloat(invoice.discount),
      projectid: projectID,
      clientid: selectedClientId,
      contactid: selectedContactId,
      notes: invoice.notes,
    };

    payload.items = [...invoiceItems];
    if(invoiceItems.length === 0) {
      return props.showModal({
        type: 'error',
        icon: 'invoice',
        title: `Creating ${props.isProposal ? 'Proposal' : 'Invoice'}`,
        message: `Add ${props.isProposal ? 'proposal' : 'invoice'} item.`,
        redirect: '',
      });
    }
    let response = await APIUtils.callPut('api/invoice/create', payload);
    try {
      if (response.status === 200) {
        setCreatingInvoice(false);
        return props.showModal({
          type: 'success',
          icon: 'invoice',
          title: `${props.isProposal ? 'Proposal' : 'Invoice'} #${response.data.id}`,
          message: `${props.isProposal ? 'Proposal' : 'Invoice'} Created.`,
          redirect: `/invoice/edit/${response.data.id}`,
        });
      }
      return props.showModal({
        type: 'error',
        icon: 'invoice',
        title: `Creating ${props.isProposal ? 'Proposal' : 'Invoice'}`,
        message: `Unable to Create ${props.isProposal ? 'Proposal' : 'Invoice'}.`,
        redirect: '',
      });
    } catch(err) {
      console.error(err);
      return props.showModal({
        type: 'error',
        icon: 'invoice',
        title: `Creating ${props.isProposal ? 'Proposal' : 'Invoice'}`,
        message: `Unable to Create ${props.isProposal ? 'Proposal' : 'Invoice'}.`,
      });
    }
  };

  const validateInputs = async () => {
    if (currentStep === 1) {
      if (!selectedClientId) {
        return props.showModal({
          type: 'error',
          icon: 'invoice',
          title: 'Missing Information',
          message: 'A client must be assigned to invoice before proceeding. Please add client.',
        });
      }
      if (!selectedContactId) {
        return props.showModal({
          type: 'error',
          icon: 'invoice',
          title: 'Missing Information',
          message: 'A contact person must be assigned to invoice before proceeding. Please add contact person.',
        });
      }
      if (!issueDate) {
        return props.showModal({
          type: 'error',
          icon: 'invoice',
          title: 'Missing Information',
          message: 'The issue date cannot be left blank. Please assign an issue date.',
        });
      }
      if (!dueDate) {
        return props.showModal({
          type: 'error',
          icon: 'invoice',
          title: 'Missing Information',
          message: 'The due date cannot be left blank. Please assign an issue date.',
        });
      }
      if (selectedProjectId <= 0 && !newProject) {
        return props.showModal({
          type: 'error',
          icon: 'invoice',
          title: 'Missing Information',
          message: 'The invoice must be assigned to a project. Either create or select a project for this invoice.',
        });
      }
      if (issueDate > dueDate) {
        return props.showModal({
          type: 'error',
          icon: 'invoice',
          title: 'Due Date',
          message: 'The due date cannot be prior to the issue date.',
        });
      }
    }
    return true;
  }

  const handleContinueClick = async (e: any) => {
    let isValid = await validateInputs();
    if (!isValid) return;

    let step = currentStep;
    let nextStep = step + 1;

    setCurrentStep(nextStep);
    if (nextStep === 4) {
      createInvoice();
    }
  };

  const handleAddItemClick = (e: any) => {
    let items = [...invoiceItems];
    items.push({
      description: '',
      qty: 1,
      price: 1000
    });
    wizardRef.current.scrollIntoView(false, { behavior: "smooth" });
    setInvoiceItems(items);
  };

  const handleItemInfoUpdate = (index: any, key: any, value: any) => {
    let items = [...invoiceItems];

    let invoiceItem = {...items[index]};
    invoiceItem[key] = value;
    items[index] = invoiceItem;

    setInvoiceItems(items);
  }

  const handleBackButtonPress = () => {
    if (currentStep > 1) {
      return setCurrentStep(currentStep - 1);
    }
    const search = window.location.search.substring(1);
    const params = search ? JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}') : {};

    if (params && params?.source === 'dashboard') {
      return NavUtils.redirectToDashboard();
    }
    
    NavUtils.redirectToInvoices();
  }

  useEffect(() => {
    if (!props?.userInfo) return;

    setInfoCollected();
  }, [props.userInfo]);


  return (
    <>
    {props.isLoading && (
      <div className="loader"></div>
    )}
    {!props.isLoading && props.userInfo && (
      <div className="componentWrap" id="createInvoice" ref={wizardRef}>
        <DashHead
          pageTitle={`Create New Invoice`}
          userInfo={props.userInfo}
          back={handleBackButtonPress}
        />
        <div className="wizard">
          { currentStep === 1 &&
            <CreateInvoiceStepOne
              invoice={invoice}
              dueDate={dueDate}
              setDueDate={smartSetDueDate}
              issueDate={issueDate}
              setIssueDate={smartSetIssueDate}
              userInfo={props.userInfo}
              updateInvoiceInfo={updateInvoiceInfo}
              handleContinueClick={handleContinueClick}
              selectedClientId={selectedClientId}
              setSelectedClientId={setSelectedClientId}
              selectedContactId={selectedContactId}
              setSelectedContactId={setSelectedContactId}
              selectedProjectId={selectedProjectId}
              setSelectedProjectId={setSelectedProjectId}
              showModal={(details) => props.showModal(details)}
              setNewProject={(name) => setNewProject(name)}
              projectName={newProject}
            />
          }
          { currentStep === 2 &&
            <CreateInvoiceStepTwo
              userInfo={props.userInfo}
              // projectChanged={projectChanged}
              invoiceItems={invoiceItems}
              handleItemInfoUpdate={handleItemInfoUpdate}
              handleAddItemClick={handleAddItemClick}
              handleContinueClick={handleContinueClick}
              showModal={props.showModal}
            />
          }
          { currentStep === 3 &&
            <CreateInvoiceStepThree
              invoice={invoice}
              invoiceItems={invoiceItems}
              userInfo={props.userInfo}
              updateInvoiceInfo={updateInvoiceInfo}
              handleContinueClick={handleContinueClick}
              showModal={props.showModal}
            />
          }
          { currentStep === 4 &&
            <CreateInvoiceStepFour
              userInfo={props.userInfo}
              collectingAllInfo={collectingAllInfo}
              creatingInvoice={creatingInvoice}
            />
          }
        </div>
      </div>
    )}
    </>
  );
}

export default CreateInvoiceWizard;
