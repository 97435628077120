import {useState} from 'react';
import {NavLink} from 'react-router-dom';

import {Modal} from '../../interfaces/modal';
import {UserInfo} from '../../interfaces/user';

import {ReactComponent as Logo} from '../../assets/img/logo.svg';
import {ReactComponent as ArrowIcon} from '../../assets/img/icons/arrow.svg';
import {ReactComponent as HomeIcon} from '../../assets/img/icons/home.svg';
import {ReactComponent as ContractIcon} from '../../assets/img/icons/contracts.svg';
import {ReactComponent as ProjectIcon} from '../../assets/img/icons/projects.svg';
import {ReactComponent as InvoiceIcon} from '../../assets/img/icons/invoices.svg';
import {ReactComponent as ClientIcon} from '../../assets/img/icons/clients.svg';

import {ReactComponent as SettingsIcon} from '../../assets/img/icons/settings.svg';
import {ReactComponent as LogoutIcon} from '../../assets/img/icons/logout.svg';

const Header = (props: {
  userInfo?: UserInfo | undefined;
  isAdmin?: boolean,
  showModal: (detials: Modal) => void;
  logout: () => void;
}) => {
  const [displayMobileMenu, setDisplayMobileMenu] = useState(false);
  const [toggleHeader, setToggleHeader] = useState(false);

  const toggleMenu = () => {
    setDisplayMobileMenu(!displayMobileMenu);
  }

  const handleSendPotionBtnClick = () => {
    props.showModal({
      type: 'potion',
      icon: 'user',
      title: `Send Potion`,
      message: 'Send a link to your client to complete the brief and your project will be generated automatically.',
      data: props.userInfo,
      callback: (response) => handleSendPotionResponse(response)
    });
  }

  const handleSendPotionResponse = (response: {
    status: number,
    message: string,
    data: any,
  }) => {
    if (response.status !== 200) {
      props.showModal({
        type: 'error',
        icon: 'email',
        title: `Sending Email`,
        message: `There was an error sending email to recipient.`,
      });
    }
    props.showModal({
      type: 'success',
      icon: 'email',
      title: `Email Sent`,
      message: `An email with the potion link was sent to ${response.data.email}`,
    });
  }
  

  const headerClasses = `
    ${displayMobileMenu ? "headerContainer show" : "headerContainer"}
    ${toggleHeader ? "closed" : "open"}
  `;

  return (
    <div className={headerClasses}>
        
        <header>

          <div className="logo">
            <a href="/">
              <Logo />
              {/* <LogoIcon /> */}
            </a>
            <button className="btn outline rounded icon toggle" onClick={() => setToggleHeader(!toggleHeader)}></button>
          </div>

          <button className="btn outline rounded icon menu" onClick={() => toggleMenu()}>
            <div className="burger">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </button>

        </header>

        <div className="navWrap">

          <button className="btn rounded potionLink" onClick={handleSendPotionBtnClick}>
            <span className="wrap">
              <ArrowIcon />
              <span className="long">
                Send Potion
              </span>
            </span>
          </button>

          <nav>
            <section>
              <h4 className="long">Manage</h4>
              {props.isAdmin && (
                <NavLink
                  reloadDocument={true}
                  className="navLink"
                  to="/admin"
                >
                  <SettingsIcon />
                  <span className="long">Admin</span>
                </NavLink>
              )}
              <NavLink
                reloadDocument={true}
                className="navLink"
                to="/dashboard"
              >
                <HomeIcon />
                <span className="long">Home</span>
              </NavLink>
              <NavLink
                reloadDocument={true}
                className="navLink"
                to="/projects"
              >
                <ProjectIcon />
                <span className="long">Projects</span>
              </NavLink>
              <NavLink
                reloadDocument={true}
                className="navLink"
                to="/contracts"
              >
                <ContractIcon />
                <span className="long">Contracts</span>
              </NavLink>
              <NavLink
                reloadDocument={true}
                className="navLink"
                to="/invoices"
              >
                <InvoiceIcon />
                <span className="long">Invoices</span>
              </NavLink>
              <NavLink
                reloadDocument={true}
                className="navLink"
                to="/clients"
              >
                <ClientIcon />
                <span className="long">Clients</span>
              </NavLink>
            </section>
            <section className="showMobile">
              <h4 className="long">Account</h4>
              <NavLink
                reloadDocument={true}
                className="navLink"
                to="/account-settings"
              >
                <SettingsIcon />
                <span className="long">Settings</span>
              </NavLink>
              <div
                className="navLink"
                onClick={() => props.logout()}
              >
                <LogoutIcon />
                <span className="long">Logout</span>
              </div>
            </section>
          </nav>
          
        </div>
      </div>
  );
}

export default Header;
