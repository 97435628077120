import React, {useRef} from 'react';
import { ReactComponent as CopyIcon } from '../../assets/img/icons/copy.svg';

const BankInfo = (props: {
  routing: string,
  account: string,
  closeModal?: () => void,
}) => {
  const copyRef1 = useRef<any>();
  const copyRef2 = useRef<any>();
  const copyBankInfo = (numb: string, ref: any) => {
    navigator.clipboard.writeText(numb);
    ref.current.className = 'btn rounded outline copy copied';
    setTimeout(() => {
      ref.current.className = 'btn rounded outline copy';
    }, 500);
  };
  return (
    <div id="BankInfo">
      <div>
        <h2>Bank Info</h2>
        <div className="fieldWrap">
          <div>
            <div className="label">Routing #</div>
            <div className="number" >{props.routing}</div>
          </div>
          <button ref={copyRef1} className="btn rounded outline copy" onClick={()=>copyBankInfo(props.routing, copyRef1)}>
            <CopyIcon />
          </button>
        </div>
        <div className="fieldWrap">
          <div>
            <div className="label">Account #</div>
            <div className="number">{props.account}</div>
          </div>
          <button ref={copyRef2} className="btn rounded outline copy" onClick={()=>copyBankInfo(props.account, copyRef2)}>
            <CopyIcon />
          </button>
        </div>
      </div>
      <button className="btn outline rounded icon close" onClick={props.closeModal}></button>
    </div>
  );
}

export default BankInfo;
