import React from 'react';

const ProjectLoader = () => {
  return(
    <>
      <div className="projectCard loading">
        <div className="top">
          <div className="details">
            <span className="name"></span>
            <span className="client"></span>
          </div>
          <div className="status"></div>
        </div>
        <div className="bottom">
          <div className="progress"></div>
          <div className="details">
            <div className="left"></div>
            <div className="left"></div>
          </div>
        </div>
      </div>
      <div className="projectCard loading">
        <div className="top">
          <div className="details">
            <span className="name"></span>
            <span className="client"></span>
          </div>
          <div className="status"></div>
        </div>
        <div className="bottom">
          <div className="progress"></div>
          <div className="details">
            <div className="left"></div>
            <div className="left"></div>
          </div>
        </div>
      </div>
      <div className="projectCard loading">
        <div className="top">
          <div className="details">
            <span className="name"></span>
            <span className="client"></span>
          </div>
          <div className="status"></div>
        </div>
        <div className="bottom">
          <div className="progress"></div>
          <div className="details">
            <div className="left"></div>
            <div className="left"></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProjectLoader;