import _ from 'lodash';
import {useState, useEffect} from 'react';

import APIUtils from '../../utils/APIUtils';
import Dropdown from '../General/Dropdown';

import {Modal} from '../../interfaces/modal';
import {Client, Contact, Company} from '../../interfaces/client';

const AdminIntakeClientInfo = (props: {
  showModal: (details: Modal) => void;
  submit: (clientInfo: Client, contactId: any) => void
}) => {

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [clients, setClients] = useState<any[]>([]);
  const [selectedClientId, setSelectedClientId] = useState<string | number>('');
  const [selectedContactId, setSelectedContactId] = useState<number | undefined>();

  const [clientInfo, setClientInfo] = useState<any>({
    name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    contacts: []
  })

  const updateClientContacts = (contacts: Contact[]) => {
    let newClient = {...clientInfo};
    newClient.contacts = contacts;
    setClientInfo(newClient);
  }

  const fetchAllClients = async () => {
    let response = await APIUtils.callGet('api/clients/all');

    if (response.status === 200) {
      setClients(response.data);
    }
    setIsLoading(false);
  };

  const debounceRetrieveClients = _.debounce(fetchAllClients, 500);

  const handleClientSubmit = async () => {
    props.submit(clientInfo as Client, selectedClientId);
  };

  const handleClientSelect = (value: string | number) => {
    setSelectedClientId(value);
  }

  const handleAddNewClientBtnClick = () => {
    props.showModal({
      type: 'client',
      icon: 'user',
      title: 'Add Client',
      message: `Fill out the information below to add a new client.`,
      callback: (payload: Company) => handleSaveNewClient(payload)
    });
  }

  const handleSaveNewClient = async (payload: Company) => {
    if (!payload.name) {
      return props.showModal({
        type: 'error',
        icon: 'user',
        title: 'Creating Client',
        message: `Client name required.`,
      });
    }
    if (payload.name) {
      try {
        let response = await APIUtils.callPost('api/company/create', payload);
        if (response.status !== 200) {
          return props.showModal({
            type: 'error',
            icon: 'user',
            title: 'Creating Client',
            message: `There was an error saving a new client.`,
          });
        }
        await fetchAllClients();
        setSelectedClientId(response.data);
        return props.showModal({
          type: 'success',
          icon: 'user',
          title: 'Client Created',
          message: `Your new client has been created.`,
        });
      } catch (err) {
        console.error(err);
        return props.showModal({
          type: 'error',
          icon: 'user',
          title: 'Creating Client',
          message: `There was an error saving a new client.`,
        });
      }
    }
  }

  const handleContactSelect = (value: string | number) => {
    setSelectedContactId(value as number);
  }

  const handleAddContactBtnClick = () => {
    props.showModal({
      type: 'contact',
      icon: 'user',
      title: 'Add Contact',
      message: `Fill out the information below to add/edit a contact person for ${clientInfo?.name}`,
      callback: (contactInfo: Contact) => handleSaveNewContact(contactInfo)
    });
  };

  const handleSaveNewContact = async (contactInfo: Contact) => {
    if (!contactInfo.email) {
      return props.showModal({
        type: 'error',
        icon: 'user',
        title: 'Creating Contact',
        message: `Contact email address required.`,
      });
    }

    if (!selectedClientId) {
      return props.showModal({
        type: 'error',
        icon: 'user',
        title: 'Creating Contact',
        message: `No client selected.`,
      });
    }

    if (selectedClientId) {
      let url = 'api/contact/create';
      contactInfo.companyid = selectedClientId as number;
      try {
        let response = await APIUtils.callPost(url, contactInfo);
        if (response.status !== 200) {
          return props.showModal({
            type: 'error',
            icon: 'user',
            title: 'Creating Contact',
            message: response.message,
          });
        }
        props.showModal({
          type: 'success',
          icon: 'user',
          title: 'Contact Created',
          message: `Contact successfully created.`,
        });
        if (clientInfo) {
          let currentContacts = [...clientInfo.contacts]
          currentContacts.push(response.data);
          updateClientContacts(currentContacts);
        }
        setSelectedContactId(response.data.id);
      } catch(err) {
        console.log(err);
        return props.showModal({
          type: 'error',
          icon: 'user',
          title: 'Creating Contact',
          message: `There was an error creating contact.`,
        });
      }
    }
  }

  useEffect(() => {
    debounceRetrieveClients();
  }, []);

  useEffect(() => {
    if (clients) {
      let client = clients.find((client) => client.id === selectedClientId);
      if (client) {
        setClientInfo(client);
      }
    }
  }, [clients, selectedClientId]);

  useEffect(() => {
    if (clientInfo) {
      let contact = clientInfo.contacts.find((contact: Contact) => contact.id === selectedContactId);
      if (contact) {
        setSelectedContactId(contact.id);
      }
    }
  }, [clientInfo, selectedContactId]);

  return (
    <div className="form">

      <div className="stepHead">
        <h2>
          <span>Step 2 of 2</span>
          Add Client & Contact Info
        </h2>
        <p>Select or create a client and contact for this project.</p>
      </div>

      {isLoading && (
        <div className="loader"></div>
      )}

      {!isLoading && (
        <>
        {clients && (
          <div className="fieldWrap">
            <div className="label">Client *</div>
            <Dropdown 
              placeholder={'Client'}
              values={clients}
              default={selectedClientId}
              update={(value: string | number) => handleClientSelect(value)}
              add={() => handleAddNewClientBtnClick()}
            />
          </div>
        )}

        {selectedClientId && (
          <div className="fieldWrap">
            <div className="label">Contact Person *</div>
            <Dropdown 
              placeholder={'Contact'}
              values={clientInfo.contacts as Contact[]}
              default={selectedContactId}
              update={(value: string | number) => handleContactSelect(value)}
              add={handleAddContactBtnClick}
            />
          </div>
        )}
        
        {selectedClientId && selectedContactId && (
          <div className="fieldWrap">
            <button className="btn rounded inline" onClick={handleClientSubmit}>
              <span>Continue</span>
            </button>
          </div>
        )}
        </>
      )}

    </div>
  )
}

export default AdminIntakeClientInfo;