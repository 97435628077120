import React from 'react';

const IntakeProjectLoader = (props: {
  showDraftNDA: boolean,
  NDACreated: boolean,
  // SOWCreated: boolean,
  proposalCreated: boolean,
  username: string,
}) => {

  return (
    <div id="createProjectLoader">
      <div className="box">
        <h2>Submitting Your Request</h2>
        <p>We are drafting documents for <a href={`/${props.username}`}>@{props.username}</a> to review and provide you with a quote.</p>
        {/* <div className="item">
          {props.SOWCreated ? (
            <div className="check"></div>
          ) : (
            <div className="loader"></div>
          )}
          <h3>Draft Scope of Work</h3>
        </div> */}
        <div className="item">
          {props.proposalCreated ? (
            <div className="check"></div>
          ) : (
            <div className="loader"></div>
          )}
          <h3>Submit Proposal</h3>
        </div>
        {props.showDraftNDA && (
          <div className="item">
            {props.NDACreated ? (
              <div className="check"></div>
            ) : (
              <div className="loader"></div>
            )}
            <h3>Draft Non-Disclosure</h3>
          </div>
        )}
        <p>You will be notified via email when the documents are ready for review.</p>
      </div>
    </div>
  );
}

export default IntakeProjectLoader;
