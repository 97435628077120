import _ from 'lodash';
import {useState, useEffect, useRef, createRef} from 'react';
// import {useParams} from 'react-router-dom';

import APIUtils from '../../utils/APIUtils';
import NavUtils from '../../utils/NavUtils';
import DashHead from '../Admin/DashHead';
import QueryParamUtils from '../../utils/QueryParamUtils';

import ProjectListItem from '../Project/ProjectListItem';

import {Contact, Client, Company} from '../../interfaces/client';
import {UserInfo} from '../../interfaces/user';
import {Modal} from '../../interfaces/modal';
import {Project} from '../../interfaces/project';

import {ReactComponent as DeleteIcon} from '../../assets/img/icons/trash.svg';
import {ReactComponent as EmailIcon} from '../../assets/img/icons/envelope.svg';
import {ReactComponent as PhoneIcon} from '../../assets/img/icons/phone.svg';
import {ReactComponent as EditIcon} from '../../assets/img/icons/edit.svg';

const SingleClient = (props: {
  userInfo?: UserInfo | undefined;
  isLoading: boolean;
  showModal: (details: Modal) => void;
  clientId?: number;
  hideHeaders?: boolean;
}) => {
  // const params = useParams();

  const contactBtnRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [clientId, setClientId] = useState<any>();
  const [clientInfo, setClientInfo] = useState<Client>({
    id: -1,
    userid: 0,
    name: '',
    address: '',
    unit: '',
    city: '',
    state: '',
    zip: '',
    contacts: [],
    projects: []
  });

  const fetchClientInfo = _.debounce(async () => {
    try {
      let url = `api/company/${clientId}`;
      let response = await APIUtils.callGet(url);

      setClientInfo(response.data);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  }, 500);
  
  // const handleConfirmClientDelete = () => {
  //   props.showModal({
  //     type: 'delete',
  //     icon: 'user',
  //     title: 'Are you sure?',
  //     message: `${clientInfo.name} will be permenantly deleted. This action cannot be undone.`,
  //     callback: handleClientDelete,
  //   });
  // }

  // const handleClientDelete = async () => {
  //   try {
  //     let url = `api/company/${clientInfo.id}`;
  //     let response = await APIUtils.callDelete(url);
  //     if (response.status !== 200) {
  //       return props.showModal({
  //         type: 'error',
  //         icon: 'user',
  //         title: 'Deleting Client',
  //         message: `There was an error deleting ${clientInfo.name}.`,
  //       });
  //     }
  //     props.showModal({
  //       type: 'success',
  //       icon: 'user',
  //       title: 'Client Deleted',
  //       message: `${clientInfo.name} has been deleted.`,
  //       redirect: '/clients',
  //     });
  //   } catch (err) {
  //     console.error(err);
  //     props.showModal({
  //       type: 'error',
  //       icon: 'user',
  //       title: 'Deleting Client',
  //       message: `There was an error deleting ${clientInfo.name}.`,
  //     });
  //   }
  // }

  const handleClientEditBtnClick = async () => {
    props.showModal({
      type: 'client',
      icon: 'user',
      title: 'Edit Client',
      message: `Fill out the information below to edit client information.`,
      callback: (clientInfo: Company) => handleClientEdit(clientInfo),
      data: clientInfo
    });
  }

  const handleClientEdit = async (clientInfo: Company) => {
    try {
      let url = `api/company/update`;
      let response = await APIUtils.callPost(url, clientInfo);
      if (response.status !== 200) {
        return props.showModal({
          type: 'error',
          icon: 'user',
          title: 'Updating Client',
          message: `There was an error updating ${clientInfo.name}.`,
        });
      }
      props.showModal({
        type: 'success',
        icon: 'user',
        title: 'Client Updated',
        message: `${clientInfo.name} has been updated.`,
      });
    } catch (err) {
      console.error(err);
      props.showModal({
        type: 'error',
        icon: 'user',
        title: 'Updating Client',
        message: `There was an error updating ${clientInfo.name}.`,
      });
    }
  }

  const handleAddContactBtnClick = (e: any, ref?: any, data?: Contact) => {
    if (ref && (ref == e.target || ref.contains(e.target))) return;
    props.showModal({
      type: 'contact',
      icon: 'user',
      title: 'Add Contact',
      message: `Fill out the information below to add/edit a contact person for ${clientInfo.name}`,
      callback: (contactInfo: Contact) => handleSaveContact(contactInfo),
      data: data
    });
  }

  const handleSaveContact = async (data: Contact) => {
    let existingContact = clientInfo.contacts.filter((contact: Contact) => contact.id === data.id);
    if (existingContact.length > 0) {
      return handleUpdateContact(data);
    }
    handleCreateContact(data);
  }

  const handleCreateContact = async (data: Contact) => {
    let url = 'api/contact/create';
    data.companyid = clientInfo.id;
    try {
      let response = await APIUtils.callPost(url, data);
      if (response.status !== 200) {
        return props.showModal({
          type: 'error',
          icon: 'user',
          title: 'Creating Contact',
          message: `There was an error creating contact.`,
        });
      }
      props.showModal({
        type: 'success',
        icon: 'user',
        title: 'Contact Created',
        message: `Contact successfully created.`,
      });
      fetchClientInfo();
    } catch(err) {
      console.error(err);
      return props.showModal({
        type: 'error',
        icon: 'user',
        title: 'Creating Contact',
        message: `There was an error creating contact.`,
      });
    }
  }

  const handleUpdateContact = async (data: Contact) => {
    let url = 'api/contact/update';
    data.companyid = clientInfo.id;
    try {
      let response = await APIUtils.callPost(url, data);
      if (response.status !== 200) {
        return props.showModal({
          type: 'error',
          icon: 'user',
          title: 'Updating Contact',
          message: `There was an error updating contact.`,
        });
      }
      props.showModal({
        type: 'success',
        icon: 'user',
        title: 'Contact Updated',
        message: `Contact successfully updated.`,
      });
      fetchClientInfo();
    } catch(err) {
      console.error(err);
      return props.showModal({
        type: 'error',
        icon: 'user',
        title: 'Updating Contact',
        message: `There was an error udpating contact.`,
      });
    }
  }

  const handleConfirmContactDelete = (i: number) => {
    props.showModal({
      type: 'delete',
      icon: 'user',
      title: 'Are you sure?',
      message: `${clientInfo.contacts[i as number].email} will be permenantly deleted from ${clientInfo.name}. This action cannot be undone.`,
      callback: () => handleContactDelete(clientInfo.contacts[i as number].id, i),
    });
  }

  const handleContactDelete = async (contactid: number | undefined, i: number) => {
    let url = `api/contact/${contactid}`;
    try {
      let response = await APIUtils.callDelete(url);
      if (response.status !== 200) {
        return props.showModal({
          type: 'error',
          icon: 'user',
          title: 'Deleting Contact',
          message: `There was an error deleteing contact from ${clientInfo.name}.`,
        });
      }
      let client: any = {...clientInfo};
      client.contacts.splice(i, 1);
      props.showModal({
        type: 'success',
        icon: 'user',
        title: 'Contact Deleted',
        message: `Contact successfully deleted from ${clientInfo.name}.`,
      });
    } catch(err) {
      console.error(err);
      return props.showModal({
        type: 'error',
        icon: 'user',
        title: 'Deleting Contact',
        message: `There was an error deleting contact to ${clientInfo.name}.`,
      });
    }
  }

  const handleNewProjectBtnClick = () => {
    props.showModal({
      type: 'project',
      icon: 'contract',
      title: `Start A New Project`,
      message: 'Choose how you would like to create a new project.',
      callback: (option) => handleProjectCreate(option),
    })
  }

  const handleProjectCreate = (option: string) => {
    if (option === 'link' && props.showModal) {
      return props.showModal({
        type: 'potion',
        icon: 'user',
        title: `Send Potion`,
        message: 'Send a link to your client to complete the brief and your project will be generated automatically.',
        data: {username: props.userInfo?.username},
      })
    }
    if (option === 'manual') {
      return NavUtils.redirectToCreateProject();
    }
  }

  const handleBackButtonPress = () => {
    let backPage = QueryParamUtils.getParamValue('return');
    if (typeof backPage === 'string' && backPage.trim().length && backPage !== 'client') {
      return window.location.pathname = backPage;
    }

    NavUtils.redirectToClientListing();
  }

  const getCliendIdFromQueryParams = () => {
    if (window.location.pathname.includes('project')) return;
    let clientId = window.location.href.split("/").pop();
    setClientId(clientId);
  }

  useEffect(() => {
    if (props.clientId && clientInfo.id === -1) {
      setClientId(props.clientId);
    }
  }, [props.clientId]);

  useEffect(() => {
    if (clientId) {
      fetchClientInfo();
    }
  }, [clientId]);

  useEffect(() => {
    contactBtnRef.current = clientInfo.contacts.map(() => createRef);
  }, [setClientInfo]);

  useEffect(() => {
    getCliendIdFromQueryParams();
  }, []);

  return (
    <>
    {isLoading && (
      <div className="loader"></div>
    )}
    { !isLoading &&
      clientInfo.id !== -1 && (
      <div className="componentWrap" id="singleClient">
        {!props.hideHeaders && (
          <DashHead
            pageTitle={clientInfo.name}
            userInfo={props.userInfo}
            back={handleBackButtonPress}
          />
        )}

        <div className="clientInfo">
          {!props.hideHeaders && (
            <h2>Client</h2>
          )}
          <div className="box">
            <address>
            <span className="name">{clientInfo.name}</span>
            <br />
            {clientInfo.address}
            <br />
            {clientInfo.city}, {clientInfo.state} {clientInfo.zip}
            </address>
            <div className="btnWrap">
              <button className="btn rounded outline green inline" onClick={handleClientEditBtnClick}>
                <EditIcon /> Edit
              </button>
              {/* <button className="btn rounded outline red inline" onClick={handleConfirmClientDelete}>
                <DeleteIcon /> Delete
              </button> */}
            </div>
          </div>
        </div>

        {!props.hideHeaders && (
          <div className="projectsListing">
            <h2>
              Projects
              <button className="btn outline rounded icon add" onClick={() => handleNewProjectBtnClick()}>
                <span></span>
                <span></span>
              </button>
            </h2>
            <div className="listing">
              { 
                clientInfo.id !== -1 &&
                clientInfo.projects &&
                clientInfo.projects.length > 0 ? 
                clientInfo.projects.map((item: Project, i: number) => {
                  return (
                    <ProjectListItem 
                      key={`project-${i}`}
                      project={item}
                    />
                  ) 
                })
                :
                <p className="placeholder">No projects found.</p>
              }
            </div> 
          </div>
        )}

        <div className="contactsListing">
          <h2>
            Contacts
            <button className="btn outline rounded icon add" onClick={(e) => handleAddContactBtnClick(e)}>
              <span></span>
              <span></span>
            </button>
          </h2>
          { 
            clientInfo &&
            clientInfo.contacts &&
            clientInfo.contacts.length > 0 &&
            (
              clientInfo.contacts.map((contact: Contact, index: number) => {
                let name;
                let initials = contact.email.charAt(0);
                if (contact.firstname && contact.lastname) {
                  name = `${contact.firstname} ${contact.lastname}`;
                  initials = `${contact.firstname?.charAt(0)}${contact.lastname?.charAt(0)}`;
                }
                return (
                  <div key={`contact-${index}`} className="contact" onClick={(e) => handleAddContactBtnClick(e, contactBtnRef.current[index], contact)}>
                    <div className="contactInfo">
                      <div className="initials">{initials}</div>
                      {name && (
                        <div className="name">{name}</div>
                      )}
                      {!name && (
                        <div className="email">
                          {contact.email}
                        </div>
                      )}
                    </div>
                    <div className='contactOptions' ref={ref => contactBtnRef.current[index] = ref}>
                      {contact.phone && (
                        <a className="btn rounded outline green" href={`tel:${contact.phone}`}>
                          <PhoneIcon />
                          <span className="text">Call</span>
                        </a>
                      )}
                      <a className="btn rounded outline green" href={`mailto:${contact.email}`}>
                        <EmailIcon />
                        <span className="text">Email</span>
                      </a>
                      <button className="btn rounded outline icon delete" onClick={() => handleConfirmContactDelete(index)}>
                        <DeleteIcon />
                      </button>
                    </div>
                  </div>
                )
              })
            )
          }
          {
            clientInfo.contacts.length === 0 && (
              <div className="placeholder">No contacts.</div>
            )
          }
        </div>

      </div>
    )}
    </>
  )
}

export default SingleClient;
