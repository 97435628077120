import {useState, useEffect } from 'react';
import NavUtils from '../../utils/NavUtils';
import ProjectUtils from '../../utils/ProjectUtils';
import PaymentUtils from '../../utils/PaymentUtils';
import ProgressBar from '../General/ProgressBar';

import {Project} from '../../interfaces/project';

const ProjectListItem = (props: {
  project: Project;
}) => {
  const [percentPaid, setPercentPaid] =  useState<number>(0);
  const [amountPaid, setAmountPaid] =  useState<string>('0');
  const [totalValue, setTotalValue] =  useState<string>('0');
  const [badgeClass, setBadgeClass] =  useState<string>('status draft');

  const calculatePercentagePaid = () => {
    if (amountPaid !== '0' && totalValue !== '0') {
      let percent = ((Number(amountPaid) / Number(totalValue)) * 100).toFixed();
      setPercentPaid(Number(percent));
    }
  }

  const calculateProjectTotalValue = () => {
    let totalValue = 0;
    for (let i = 0; i < props.project.invoices.length; i++) {
      if(props.project.invoices[i].total !== null) {
        totalValue = Number(totalValue) + Number(props.project.invoices[i].total);
      }
    }
    setTotalValue(totalValue?.toString());
  }

  const calculateProjectTotalPaid = () => {
    if (!props?.project?.invoices) {
      return;
    }

    let totalPaid = ProjectUtils.getInvoicesPaidTotal(props.project.invoices);
    setAmountPaid(totalPaid?.toString());
  }

  useEffect(() => {
    let badgeClass = `status ${props.project.status}`;
    if (props.project.status === 'finished') {
      badgeClass = `status draft`;
    }
    setBadgeClass(badgeClass);

    calculateProjectTotalValue();
    calculateProjectTotalPaid();
  }, [props.project]);

  useEffect(() => {
    calculatePercentagePaid();
  }, [amountPaid, totalValue]);

  return (
    <div className="projectCard" key={props.project.id} onClick={() => {NavUtils.redirectToViewProject(props.project.id)}}>
      <div className="top">
        <div className="left">
          <div className='projectName'>
            {props.project.name}
          </div>
          <div className='projectClient'>
            {props.project.company_name}
          </div>
        </div>
        <div className={badgeClass}>
          {props.project.status}
        </div>
      </div>
      <div className="bottom">
        <ProgressBar 
          percent={percentPaid}
        />
        <div className="paid">
          {percentPaid}% <span className="label">Paid</span>
        </div>
        <div className="value">
          {PaymentUtils.formatPrice(amountPaid)} / <span className="label">{PaymentUtils.formatPrice(totalValue)}</span>
        </div>
      </div>
    </div>
  );
}

export default ProjectListItem;
