class ProjectUtils {
    static getInvoicesPaidTotal(invoices) {
        let totalPaid = 0;
        
        if (!Array.isArray(invoices) || !invoices.length) {
            return totalPaid;
        }

        invoices.forEach(invoice => {
            if (!invoice.paidat) {
                return;
            }
            totalPaid += Number(invoice.total);
        });

        return totalPaid;
    }
}

export default ProjectUtils;