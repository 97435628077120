import {Modal} from '../../../interfaces/modal';

import {ReactComponent as CopyIcon} from '../../../assets/img/icons/copy.svg';
import {ReactComponent as EditIcon} from '../../../assets/img/icons/edit.svg';

const ProjectCreateModal = (props: {
  modal: Modal;
  close: () => void;
}) => {

  const handleOptionClick = (option: string) => {
    if (props.modal.callback) {
      props.modal.callback(option);
      props.close();
    }
  }

  return (
    <>
    <div className="modalCopy projectCreate">
      <h3>{props.modal.title}</h3>
      <div className="message">
        {props.modal.message}
      </div>
      <div className="split">
        <div className="option" onClick={() => handleOptionClick('link')}>
          <CopyIcon />
          <h4>Client Intake</h4>
          <p>Send link to your client to generate project.</p>
        </div>
        <div className="option" onClick={() => handleOptionClick('manual')}>
          <EditIcon />
          <h4>Manual Intake</h4>
          <p>Generate a project on your own from the project brief.</p>
        </div>
      </div>
    </div>
    </>
  )
}

export default ProjectCreateModal;