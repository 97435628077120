import {useState, useEffect, useCallback} from 'react';
import {Invoice} from '../../interfaces/invoice';

const InvoiceStatus = (props: {
  invoice: Invoice;
}) => {

  const [isDraft, setIsDraft] = useState<boolean>(true);
  const [isLate, setIsLate] = useState<boolean>(false);
  const [dueDayCount, setDueDayCount] = useState<string>('');
  const [paidDate, setPaidDate] = useState<string>('');

  const checkIsDraft = () => {
    if (props.invoice.isproposal) return true;

    let items = props.invoice.deliverables;
    let missingPrice = [];

    for (const i in items) {
      if (!items[parseInt(i)].price) {
        missingPrice.push(items[parseInt(i)]);
      }
    }

    if (missingPrice.length > 0) {
      return true;
    }

    return false;
  }

  const treatAsUTC = (date: Date) => {
    let result = new Date(date);
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
    return Number(result);
  }

  const daysBetween = useCallback((startDate: Date, endDate: Date) => {
    let millisecondsPerDay = 24 * 60 * 60 * 1000;
    let value = Math.round((treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay);
    if(value < 0) {
      setIsLate(true);
    }
    return value;
  }, []);

  useEffect(() => {
    if (props.invoice) {
      let isDraft = checkIsDraft();

      setIsDraft(isDraft);
    }
    if (props.invoice.dueat) {
      const currentDate = new Date();
      const due = new Date(props.invoice.dueat);
      if (due < currentDate) setIsLate(true);

      setDueDayCount(daysBetween(currentDate, due).toString().replace("-",""));
    }
    if (props.invoice.paidat) {
      const paid = new Date(props.invoice.paidat);

      setPaidDate(`${paid.getMonth()}/${paid.getDate()}/${paid.getFullYear()}`)
    }
  }, [props.invoice]);

  const statusClass = `
    ${isDraft && props.invoice.emailSent ? 'draft' : ''}
    ${isDraft && !props.invoice.emailSent ? 'unsent' : ''}
    ${!isDraft && !props.invoice.paidat && !isLate ? 'pending' : ''}
    ${!isDraft && props.invoice.paidat ? 'paid' : ''}
    ${!isDraft && isLate && !props.invoice.paidat ? 'late' : ''}
  `;
  
  return (
    <>
    <div className={`status ${statusClass}`}>
      {statusClass}
    </div>
    {props.invoice.paidat && (
      <div className="details secondary"><span className="paid">Paid</span> on {paidDate}</div>
    )}
    {isLate && !props.invoice.paidat && (
      <div className="details late secondary">
        {dueDayCount} {dueDayCount === '1' ? 'day' : 'days'} late
      </div>
    )}
    {!props.invoice.paidat && dueDayCount && !isLate && (
      <div className="details secondary">
        {parseInt(dueDayCount) === 1 && (
          <>Due in {dueDayCount} day</>
        )}
        {parseInt(dueDayCount) === 0 && (
          <>Due today</>
        )}
        {parseInt(dueDayCount) > 1 && (
          <>Due in {dueDayCount} days</>
        )}
      </div>
    )}
    </>
  )
}

export default InvoiceStatus;
