import APIUtils from './APIUtils';

class ClientUtils {
    static fetchAllClientsUrl = 'api/client/';

    static async retrieveClients() {
        try {
          let response = await APIUtils.callGet(ClientUtils.fetchAllClientsUrl);

          if (!Array.isArray(response.data) || !response.data.length) {
            return [];
          }
    
          return response.data;
        } catch (err) {
          console.log(err);

          return [];
        }
      }
}

export default ClientUtils;