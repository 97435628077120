import React, {useState, useEffect, useRef} from 'react';
import { Loader } from "@googlemaps/js-api-loader";
import config from '../../config/config';

const AddressInput = (props: {
  updateAddress: (address: {
    address?: string,
    city?: string,
    state?: string,
    zip?: string | unknown,
    country?: string,
  }) => void,
  errorFields?: string[],
  defaultValue?: string,
  className?: string,
  placeholder?: string,
}) => {

  const loadRef = useRef<boolean>(false);
  const [predictions, setPredictions] = useState<any>([]);
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = async (e: any) => {
    if (e.target.value.length > 0) {
      let response = await autoComplete(e.target.value);
      setPredictions(response);
    }
    if (e.target.value.length === 0) {
      setInputValue('');
      props.updateAddress({
        address: '',
        city: '',
        state: '',
        zip: ''
      })
      setPredictions([]);
    }
  }

  const autoComplete = (text: string) => {
    setInputValue(text);
    return new Promise( async (resolve, reject) => {
      if (!text) {
        return
      }

      try {
        const googleAutocomplete = new google.maps.places.AutocompleteService();
        const results = await googleAutocomplete.getPlacePredictions(
          { input: text, componentRestrictions: { country: "us" } },
          resolve
        )
        resolve(results);
      } catch (e) {
        reject(e)
      }
    })
  }

  const getPlacesPostCodeById = (placeId: string) => {
    return new Promise((resolve, reject) => {
      if (!placeId) return;
      try {
        new window.google.maps.places.PlacesService(
          document.createElement("div")
        ).getDetails(
          {
            placeId,
            fields: ["address_components"],
          },
          details => {
            let postcode;
            details?.address_components?.forEach(entry => {
              if (entry.types?.[0] === 'postal_code') {
                postcode = entry.long_name
              }
            })
            resolve(postcode)
          }
        )
      } catch (e) {
        reject(e)
      }
    })
  }

  const handleAddressSelect = async (item: any) => {
    const zip = await getPlacesPostCodeById(item.place_id);
    const primary = item.structured_formatting.main_text;
    const secondary = item.structured_formatting.secondary_text.replace(', ', ',').split(',');
    let address = {
      address: primary,
      city: secondary[0],
      state: secondary[1].split(' ')[0],
      zip: zip,
      country: secondary[2],
    }

    props.updateAddress(address);
    setPredictions([]);
  }

  useEffect(()=> {
    if(loadRef.current) return;
    loadRef.current = true;

    const loader = new Loader({
      apiKey: config.google.sk,
      version: "weekly",
      libraries: ["maps", "places"]
    });

    loader
    .load()
    .then(async () => {
      await google.maps.importLibrary("maps");
      await google.maps.importLibrary("places");
    })
    .catch((e) => {
      console.log(e);
    });
  }, []);

  useEffect(() => {
    if (props.defaultValue) {
      setInputValue(props.defaultValue);
    }
  }, [props.defaultValue]);

  return (
    <div className="addressSearch">
      <input 
      className={props.errorFields ? props.errorFields.includes('address') ? `${props.className} error`:  props.className : props.className} 
      placeholder={props.placeholder ? props.placeholder : 'Address'} 
      value={inputValue}
      onChange={(e) => {handleInputChange(e)}}
      ></input>
      <div className="searchResults">
        {Array.isArray(predictions) ? predictions.map((item: any, i: number) => {
          return(
            <div key={i} onClick={() => handleAddressSelect(item)}>
              {item.description}
            </div>
          )
        }): null}
      </div>
    </div>
  );
}

export default AddressInput;
