import {useState, useEffect} from 'react';
import APIUtils from '../../utils/APIUtils';
import UserUtils from '../../utils/UserUtils';
import QueryParamUtils from '../../utils/QueryParamUtils';
import DashHead from '../Admin/DashHead';
import {UserInfo} from '../../interfaces/user';
import {Modal} from '../../interfaces/modal';

import ProfileSettings from './ProfileSettings';
import MyLink from './MyLink';
import Password from './Password';
import MyPlan from './MyPlan';
import Notifications from './Notifications';
import { AnyARecord } from 'dns';

import {ReactComponent as DeleteIcon} from '../../assets/img/icons/trash.svg';

const AccountSettings = (props: {
  userInfo: UserInfo | undefined,
  isLoading: boolean,
  showModal: (details: Modal) => void,
}) => {
  const [selectedTab, setSelectedTab] = useState('profile');
  const [updatedUserInfo, setUpdatedUserInfo] = useState<UserInfo>(UserUtils.getEmptyUserInfo());
  const validTabs = ['profile', 'link', 'plan', 'password' ,'notifications'];

  const updateUserInfo = (key: string, value: AnyARecord) => {
    let userCopy: any = {...updatedUserInfo};
    userCopy[key] = value;
    setUpdatedUserInfo(userCopy);
  };

  const confirmDeleteAccount = () => {
    return props.showModal({
      type: 'delete',
      icon: 'user',
      title: `Are you sure?`,
      message: 'Your account will be permenantly deleted. This action cannot be undone.',
      callback: deleteAccount,
    });
  }

  const deleteAccount = async () => {
    if (!props.userInfo || !props.userInfo.id) {
        return;
    }

    let response: any = await APIUtils.callDelete(`api/account/delete/${props.userInfo.id}`);

    if (response.status !== 200) {
        return props.showModal({
          type: 'error',
          icon: 'user',
          title: `Deleting Account`,
          message: 'Unable to delete account!',
          redirect: '',
        });
      }

      props.showModal({
        type: 'success',
        icon: 'user',
        title: `Account Deleted`,
        message: 'Account deleted successfully!',
        redirect: '/',
      });
  };

  useEffect(() => {
    if(props.userInfo) {
      setUpdatedUserInfo(props.userInfo);
    }

    

    const tab = QueryParamUtils.getParamValue('tab');
    if (tab && validTabs.includes(tab)) {
      setSelectedTab(tab);
    }
  }, [props.userInfo]);

  return (
    <>
    {props.isLoading && (
      <div className="loader"></div>
    )}
    {!props.isLoading && props.userInfo && (
      <div className="componentWrap" id="account">
        <DashHead
          pageTitle="Account Settings"
          userInfo={props.userInfo}
        />
        
        <div className="filters">
          <div className="bar">
            <div className={selectedTab === 'profile' ? 'filter active' : 'filter'} onClick={() => setSelectedTab('profile')}>My Profile</div>
            <div className={selectedTab === 'link' ? 'filter active' : 'filter'} onClick={() => setSelectedTab('link')}>My Link</div>
            <div className={selectedTab === 'plan' ? 'filter active' : 'filter'} onClick={() => setSelectedTab('plan')}>My Plan</div>
            <div className={selectedTab === 'password' ? 'filter active' : 'filter'} onClick={() => setSelectedTab('password')}>Password</div>
            <div className={selectedTab === 'notifications' ? 'filter active' : 'filter'} onClick={() => setSelectedTab('notifications')}>Notifications</div>
          </div>
        </div>

        <div className="box">

          <nav>
            <div className="menu">
              <div className="tabs">
                <button className={selectedTab === 'profile' ? 'btn link tab active' : 'btn link tab'} onClick={() => setSelectedTab('profile')}>My Profile</button>
                <button className={selectedTab === 'link' ? 'btn link tab active' : 'btn link tab'} onClick={() => setSelectedTab('link')}>My Link</button>
                <button className={selectedTab === 'plan' ? 'btn link tab active' : 'btn link tab'} onClick={() => setSelectedTab('plan')}>My Plan</button>
                <button className={selectedTab === 'password' ? 'btn link tab active' : 'btn link tab'} onClick={() => setSelectedTab('password')}>Password</button>
                <button className={selectedTab === 'notifications' ? 'btn link tab active' : 'btn link tab'} onClick={() => setSelectedTab('notifications')}>Notifications</button>
              </div>
              <button className="btn link tab red" onClick={confirmDeleteAccount}><DeleteIcon /> Delete Account</button>
            </div>
          </nav>

          <div className="content">
            { selectedTab === 'profile' && updatedUserInfo.id &&
              <ProfileSettings
                showModal={props.showModal}
                userInfo={updatedUserInfo}
                updateUserInfo={updateUserInfo}
                setUpdatedUserInfo={setUpdatedUserInfo}
              />
            }
            { selectedTab === 'link' &&
              <MyLink userInfo={updatedUserInfo} />
            }
            { selectedTab === 'password' &&
              <Password
                showModal={props.showModal}
                email={props.userInfo.email}  
              />
            }
            { selectedTab === 'plan' &&
              <MyPlan
                userInfo={updatedUserInfo} 
                showModal={props.showModal}
              />
            }
            { selectedTab === 'notifications' &&
              <Notifications />
            }
          </div>

        </div>
        <button className="btn rounded outline red deleteBtn" onClick={confirmDeleteAccount}><DeleteIcon /> Delete Account</button>
      </div>
    )}
    </>
  );
}

export default AccountSettings;
