import _ from 'lodash';
import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';

import DashHead from '../Admin/DashHead';
import ContractListItem from './ContractListItem';
import APIUtils from '../../utils/APIUtils';
import Filters from '../General/Filters';
import ContractDashMetrics from '../Admin/ContractDashMetrics';
import ListItemLoading from '../Admin/ListItemLoading';

import {UserInfo} from '../../interfaces/user';
import {Contract} from '../../interfaces/contract';

const ContractListing = (props: {
  userInfo?: UserInfo | undefined;
  isLoading: boolean;
}) => {
  const [contractLoading, setContractLoading] = useState(true);
  const [filter, setFilter] = useState<string>('');
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const retrieveContracts = _.debounce(async () => {
    try {
      let url = `api/contract/all`;

      if (filter.trim().length) {
        url += `?filter=${filter}`;
      }

      let response: any = await APIUtils.callGet(url);

      let contracts: Contract[] = response.data;
      setContracts(contracts);
      setContractLoading(false);
    } catch (err) {
      console.log(err);
    }
  }, 500);

  const [contractStats, setContractStats] = useState<any>({
    pending: 0,
    declined: 0,
    signed: 0,
  });

  const retrieveContractStats = _.debounce(() => {
    APIUtils.callGet(`api/contract_stats`)
    .then(response => {
      setContractStats(response.data);
    })
    .catch((err) => {
      return console.error(err);
    });
  }, 300);

  const updateSelectedFilterText = (filter: string) => {
    if (filter === 'All') {
      filter = '';
    }
    setFilter(filter.toLowerCase());
  }

  useEffect(() => {
    if (props.userInfo === undefined) return;
    
    retrieveContracts();
    retrieveContractStats();
  }, [props.userInfo, filter]);

  useEffect(() => {
    let defaultFiltlerValue = searchParams.get('filter');
    if (defaultFiltlerValue && defaultFiltlerValue !== null) {
      setFilter(defaultFiltlerValue);
    }
  }, []);

  return (
    <>
    {props.isLoading && (
      <div className="loader"></div>
    )}
    {!props.isLoading && props.userInfo && (
        <div className="componentWrap" id="contracts">
          <DashHead
            pageTitle="Contracts"
            userInfo={props.userInfo}
            create={'/contract/create'}
          />
          <div className="pageContent">
            <ContractDashMetrics 
              signed={contractStats.signed}
              pending={contractStats.pending}
              declined={contractStats.declined}
              showFull={false}
              setFilter={(value) => updateSelectedFilterText(value)}
            />
            <Filters 
              filterValues={['All', 'Drafts', 'Pending', 'Signed', 'Declined']}
              selectedFilter={filter}
              setSelectedFilter={(filter) => updateSelectedFilterText(filter)}
            />
            <div className="main">
              {
                contractLoading && (
                  <ListItemLoading />
                )
              }
              {
                !contractLoading &&
                contracts.length > 0 && 
                contracts.map((contract, i) => {
                  return (
                    <ContractListItem 
                      key={i}
                      contract={contract}
                    />
                  ) 
                })
              }
              {
                !contractLoading &&
                contracts.length == 0 && (
                  <p className="placeholder">No contracts found.</p>
                )
              }
            </div>

          </div>
        </div>
    )}
    </>
  );
}

export default ContractListing;