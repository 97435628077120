import {useState, useEffect} from 'react';
import {Modal} from '../../../interfaces/modal';

import {ReactComponent as IndividualIcon} from '../../../assets/img/individual.svg';
import {ReactComponent as ContractIcon} from '../../../assets/img/contract.svg';
import {ReactComponent as InvoiceIcon} from '../../../assets/img/invoice.svg';
import {ReactComponent as EmailIcon} from '../../../assets/img/icons/envelope.svg';

import {ReactComponent as DeleteIcon} from '../../../assets/img/icons/trash.svg';

const MessageModalCopy = (props: {
  modal: Modal;
}) => {
  const [title, setTitle] = useState('');
  const [confirm, setConfirm] = useState(false);

  const handleConfirmation = () => {
    setConfirm(true);
    if (props.modal.callback) {
      props.modal.callback();
    }
  }

  useEffect(() => {
    if (props.modal.type === 'success') {
      setTitle('success');
      setConfirm(true);
    }
    if (props.modal.type === 'error') {
      setTitle('error');
      setConfirm(true);
    }
    if (props.modal.type === 'delete') {
      setTitle('warning');
      setConfirm(false);
    }
  }, [props.modal]);

  return (
    <>
      <div className="modalCopy">
        {!confirm && (
          <>
            <h1>warning</h1>
            <div className="icon">
              {
                props.modal.icon === 'email' && (
                  <EmailIcon />
                )
              }
              {
                props.modal.icon === 'user' && (
                  <IndividualIcon />
                )
              }
              {
                props.modal.icon === 'contract' && (
                  <ContractIcon />
                )
              }
              {
                props.modal.icon === 'invoice' && (
                  <InvoiceIcon />
                )
              }
            </div>
            <h3>{props.modal.title}</h3>
            <div className="message" dangerouslySetInnerHTML={{__html: props.modal.message}} />
            <button className="btn rounded outline red" onClick={() => handleConfirmation()}><DeleteIcon /> Permanently Delete</button>
          </>
        )}
        {confirm && (
          <>
            <h1>{title}</h1>
            <div className="icon">
              {
                props.modal.icon === 'email' && (
                  <EmailIcon />
                )
              }
              {
                props.modal.icon === 'user' && (
                  <IndividualIcon />
                )
              }
              {
                props.modal.icon === 'contract' && (
                  <ContractIcon />
                )
              }
              {
                props.modal.icon === 'invoice' && (
                  <InvoiceIcon />
                )
              }
            </div>
            <h3>{props.modal.title}</h3>
            <div className="message" dangerouslySetInnerHTML={{__html: props.modal.message}} />
          </>
        )}
      </div>
    </>
  )
}

export default MessageModalCopy;
