import _ from 'lodash';
import {useState, useEffect, useCallback} from 'react';

import APIUtils from '../../utils/APIUtils';
import NavUtils from '../../utils/NavUtils';
import EmailUtils from '../../utils/EmailUtils';

import {Modal} from '../../interfaces/modal';

const Register = (props: {
  register: Function
  showModal: (details: Modal) => void;
}) => {

  const [registerLoading, setRegisterLoading] = useState(false);
  const [registerError, setRegisterError] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [pass, setPass] = useState('');

  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isUsernameInUse, setIsUsernameInUse] = useState(false);
  const [isEmailInUse, setIsEmailInUse] = useState(false);

  const handleKeyPress = (event: any) => {
    if(event.key === 'Enter'){
      register();
    }
  }

  const checkUsername = async (username: string) => {
    if (!username.trim().length) {
      return setIsUsernameInUse(false);
    }

    let response = await APIUtils.callPost('api/account/username_check', {username});
    
    let in_use = response?.data?.in_use !== undefined ? response.data.in_use : true;

    setIsUsernameInUse(in_use);
  }

  const debounceCheckUsername = _.debounce(checkUsername, 300);

  const updateAndCheckUsername = useCallback((username: string) => {
    setUsername(username);

    debounceCheckUsername(username);
  }, [debounceCheckUsername]);

  const checkEmail = async (email: string) => {
    if (!email.trim().length) {
      return setIsEmailInUse(false);
    }

    let response = await APIUtils.callPost('api/account/email_check', {email});
    
    let in_use = response?.data?.in_use !== undefined ? response.data.in_use : true;

    setIsEmailInUse(in_use);
  }

  const debounceCheckEmail = _.debounce(checkEmail, 300);

  const updateCurrentEmail = useCallback((email: string) => {
    setEmail(email);

    if (EmailUtils.isValid(email)) {
      debounceCheckEmail(email);
    }
  }, [debounceCheckEmail]);

  const validateEmail = useCallback(() => {
    if (EmailUtils.isValid(email)) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
  }, [email]);

  const registerValidation = () => {
    if(!email || !username || !pass || !firstName || !lastName) {
      setRegisterError('Enter all information.');
      return false;
    }
    if(!isValidEmail) {
      setRegisterError('Enter a valid email address.');
      return false;
    }
    if (pass.trim().length < 8) {
      setRegisterError('Password must be at least 8 characters long.');
      return false;
    }

    return true;
  }

  const register = () => {

    setRegisterLoading(true);

    let isValidSubmission = registerValidation();
    if(!isValidSubmission) {
      return setRegisterLoading(false);
    }

    const userData = {
      'username': username,
      'email': email,
      'password': pass,
      'first': firstName,
      'last': lastName,
    }

    APIUtils.callPost('api/account/create', userData)
    .then((res) => {
      if (res.status !== 200) {
        return props.showModal({
          type: 'error',
          icon: 'user',
          title: 'Register Error',
          message: res.message,
        });
      }
      window.gtag('event', 'RegisterNewUser');
      NavUtils.redirectToVerifyEmail();
    })
    .catch((err) => {
      console.error(err);
      return props.showModal({
        type: 'error',
        icon: 'user',
        title: 'Register Error',
        message: 'There was an error registering new user.',
      });
    })
    .finally( () => {
      setRegisterLoading(false);
    });
  }

  useEffect(() => {
    if(email) {
      validateEmail();
    }

    if (!email && isEmailInUse) {
      setIsEmailInUse(false);
    }

    if(username && email && pass && isValidEmail) {
      setRegisterError('');
    }
  }, [username, email, pass, validateEmail, isValidEmail, isEmailInUse]);

  return (
    <>

      <div className="formHead">
        <p>Get started</p>
        <h2>Create an account</h2>
      </div>

      <div className="formBody">

        {
          registerError && (
            <div className="alert error">
              {registerError}
            </div>
          )
        }

        {
          isUsernameInUse && (
            <div className="alert error">Username in use.</div>
          )
        }

        {
          isEmailInUse && (
            <div className="alert error">Email in use.</div>
          )
        }

        <div className="split">
          <div>
            <div className="label">First Name *</div>
            <input type="text" id="firstname" name="firstname" onChange={(e) => setFirstName(e.currentTarget.value)} placeholder="First Name" autoComplete="off" onKeyDown={handleKeyPress} />
          </div>
          <div>
            <div className="label">Last Name *</div>
            <input type="text" name="lastname" id="lastname" onChange={(e) => setLastName(e.currentTarget.value)} placeholder="Last Name" autoComplete="off" onKeyDown={handleKeyPress} />
          </div>
        </div>

        <div>
          <div className="label">Email *</div>
          <input type="text" id="email" name="email" onChange={(e) => updateCurrentEmail(e.currentTarget.value)} placeholder="Email" autoComplete="off" autoCapitalize="off" onKeyDown={handleKeyPress} />
        </div>
        
        <div className='username'>
          <div className="label">Username *</div>
          <input type="text" id="username" name="username" onChange={(e) => updateAndCheckUsername(e.currentTarget.value)} placeholder="Username" autoComplete="off" autoCapitalize="off" onKeyDown={handleKeyPress} />
        </div>

        <div>
          <div className="label">Password *</div>
          <input type="password" id="pass" name="pass" onChange={(e) => setPass(e.currentTarget.value)} placeholder="Password" autoComplete="off" autoCapitalize="off" onKeyDown={handleKeyPress} />
        </div>

        <button id="registerSubmitBtn" className="btn rounded inline" onClick={() => register()}>
          {registerLoading && (
            <span><div className="loader"></div></span>
          )}
          {!registerLoading && (
            <span>Start My Free Trial</span>
          )}
        </button>
      </div>

      <div className="formLink">
        <p>Have an account? <button className="loginLink" onClick={() => props.register()}>Sign In</button></p>
      </div>
      
    </>
  );
}

export default Register;
