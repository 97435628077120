import {useState, useEffect} from 'react';

const IntakeLoader = (props: {
  username: string | undefined,
  nameGenerated: boolean,
  deliverablesGenerated: boolean,
  proposalDraftGenerated: boolean,
  showModal: () => void,
}) => {
  const [showSubmit, setShowSubmit] = useState<boolean>(false);
  useEffect(() => {
    if (
      props.nameGenerated &&
      props.deliverablesGenerated &&
      props.proposalDraftGenerated
    ) {
      props.showModal();
      setShowSubmit(true);
    }
  }, [props.nameGenerated, props.deliverablesGenerated, props.proposalDraftGenerated]);

  return (
    <div id="createProjectLoader">
      <div className="box">
        <h2>Generating Request...</h2>
        <p>Generating project info to provide to @{props.username} based on your request.</p>
        <div className="item">
          {props.nameGenerated ? (
            <div className="check"></div>
          ) : (
            <div className="loader"></div>
          )}
          <h3>Project Filing</h3>
        </div>
        <div className="item">
          {props.deliverablesGenerated ? (
            <div className="check"></div>
          ) : (
            <div className="loader"></div>
          )}
          <h3>Deliverables</h3>
        </div>
        <div className="item">
          {props.proposalDraftGenerated ? (
            <div className="check"></div>
          ) : (
            <div className="loader"></div>
          )}
          <h3>Drafting Proposal</h3>
        </div>
        {showSubmit && (
          <button className="btn rounded outline" onClick={() => props.showModal()}>
            <span>Get A Quote</span>
          </button>
        )}
      </div>
    </div>
  );
}

export default IntakeLoader;
