import {useState, useEffect} from 'react';
import {UserInfo} from '../../interfaces/user';

import UserPotionLink from '../Admin/UserPotionLink';

import {ReactComponent as IndividualIcon} from '../../assets/img/individual.svg';
import {ReactComponent as CopyIcon} from '../../assets/img/icons/copy.svg';

const MyLink = (props: {
    userInfo: UserInfo | undefined,
}) => {
    return (
        <div id="profileLink">

          <div id="user">
            <div className="image" style={{backgroundImage: props.userInfo?.image ? `url(${props.userInfo?.image})`: ''}}>
              {!props.userInfo?.image && (
                  <IndividualIcon />
              )}
            </div>
            <div>
              <div className="name">{props.userInfo?.firstname} {props.userInfo?.lastname}</div>
              <div className="username">@{props.userInfo?.username}</div>
            </div>
          </div>

          <div className="details">
            <p>Send a link to your client to complete this brief and the project will be generated automatically</p>
            <UserPotionLink 
              username={props.userInfo?.username}
            />
          </div>

        </div>
    );
}

export default MyLink;
