import {useState, useEffect, useRef, useCallback} from 'react';

import {ReactComponent as SearchIcon} from '../../assets/img/icons/search.svg';

const Dropdown = (props: {
  placeholder: string;
  default?: string | number;
  values: any[];
  update: (value: string | number) => void;
  add?: () => void;
}) => {
  const dropRef = useRef<any>();
  const selectRef = useRef<any>();
  const searchRef = useRef<any>();

  const [values, setValues] = useState<any[]>([]);
  const [results, setResults] = useState<any[]>([]);
  const [selected, setSelected] = useState<string | number>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClickOutside = (event: any) => {
    if (
      searchRef.current &&
      searchRef.current === event.target
    ) {
      return;
    }
    if (
      dropRef.current && 
      !dropRef.current.contains(event.target) &&
      selectRef.current &&
      selectRef.current !== event.target
      ) {
      setIsOpen(false);
    }
  }

  const handleSelect = useCallback((id: string) => {
    setIsOpen(false);
    getNameFromId(id);
    props.update(id);
  }, []);

  const handleToggleClick = (event: any) => {
    // if (searchRef.current && searchRef.current === event.target) return;
    setIsOpen(!isOpen);
  }

  const getNameFromId = (id: string | number) => {
    const selected = values.filter(value => {
      return value.id === id;
    });
    setSelected(selected[0]?.value);
  }

  const getNameValue = (item: any) => {
    if (item.name) {
      return item.name;
    }
    if (item.firstname && item.lastname) {
      return `${item.firstname} ${item.lastname}`;
    }
    if (item.email) {
      return item.email;
    }
    return item;
  }

  const formatValues = (values: any[]) => {
    let items: any[] = [];
    for (let i in values) {
      let newValue = {
        id: values[i].id ? values[i].id : getNameValue(values[i]),
        value: getNameValue(values[i])
      }
      items.push(newValue);
    }
    
    setValues(items);
    setResults(items);
  }

  const searchValues = (searchValue: string) => {
    let search = searchValue.charAt(0).toUpperCase() + searchValue.slice(1);
    let results = values.filter(item => item.value.includes(search));
    if (results) {
      return setResults(results);
    }
    setResults(values);
  }

  useEffect(() => {
    if (props.values) {
      formatValues(props.values);
    }
  }, [props]);

  useEffect(() => {
    if (values && values.length > 0 && props.default && props.default !== -1) {
      getNameFromId(props.default);
    }
  }, [props, values]);

  useEffect(() => {
    if (isOpen) {
      setResults(values);
    }
  }, [isOpen]);

  useEffect(() => {
    window.addEventListener('mousedown', (e) => {handleClickOutside(e)});
    return () => {
      window.removeEventListener('mousedown', (e) => {handleClickOutside(e)});
    };
  }, [handleClickOutside]);

  return (
    <>
    {
      props.values &&
      props.values.length > 0 && (
        <div className={isOpen ? 'dropdown open' : 'dropdown'}>
          <div className="selected" onClick={(e) => handleToggleClick(e)} ref={selectRef}>
            {selected ? selected : props.placeholder}
          </div>
          {isOpen && (
            <div className="search">
              <SearchIcon />
              <input ref={searchRef} type="text" onChange={(e) => searchValues(e.target.value)} placeholder="Search..." />
            </div>
          )}
          <div className="drop" ref={dropRef}>
            { 
              results &&
              results.map((item: any) => {
                return (
                  <button className="value" key={`dd-${props.placeholder}-${item.id}`} onClick={(e) => handleSelect(item.id)}>
                    {item.value}
                  </button>
                )
              })
            }
          </div>
        </div>
      )
    }
    {
      props.add &&
      values.length > 0 && (
        <div className="dropAdd">
          <button className="btn dropdownAdd" onClick={props.add}>
            <span className="plus"></span> Add New {props.placeholder}
          </button>
        </div>
      )
    }
    {
      props.add &&
      values.length === 0 && (
        <button className="btn rounded outline green" onClick={props.add}>
          Add New {props.placeholder}
        </button>
      )
    }
    </>
  )
}

export default Dropdown;