import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useParams} from 'react-router-dom';

import {UserInfo} from '../../interfaces/user';
import {Client} from '../../interfaces/client';
import {Modal} from '../../interfaces/modal';

import DashHead from '../Admin/DashHead';
import IntakePrompt from './AdminIntakePrompt';
import IntakeClientInfo from './AdminIntakeClientInfo';
import IntakeLoader from '../Intake/IntakeLoader';
import IntakeProjectLoader from '../Intake/IntakeProjectLoader';

import APIUtils from '../../utils/APIUtils';
import NavUtils from '../../utils/NavUtils';
import UserUtils from '../../utils/UserUtils';

const Intake = (props: {
  userInfo: UserInfo | undefined,
  isLoading: boolean,
  showModal: (details: Modal) => void;
}) => {
  const userInfoRef = useRef<boolean>(false);

  const [isIntakeLoading, setIsIntakeLoading] = useState<boolean>(false);
  const [nameGenerated, setNameGenerated] = useState<boolean>(false);
  const [deliverablesGenerated, setDeliverablesGenerated] = useState<boolean>(false);
  const [proposalDraftGenerated, setProposalDraftGenerated] = useState<boolean>(false);

  const [isProjectLoading, setIsProjectLoading] = useState<boolean>(false);
  const [proposalCreated, setProposalCreated] = useState<boolean>(false);
  
  const [NDACreated, setNDACreated] = useState<boolean>(false);

  const [draftNDA, setDraftNDA] = useState<boolean>(false);
  const [showClientInfo, setShowClientInfo] = useState<boolean>(false);

  const [projectInfo, setProjectInfo] = useState({
    userid: '',
    prompt: '',
    title: '',
    deliverables: [''],
  })

  const [NDAhtml, setNDAhtml] = useState<string | TrustedHTML>('');

  const {username} = useParams();
  const [userInfo, setUserInfo] = useState<UserInfo>(UserUtils.getEmptyUserInfo());

  const fetchNDATemplate = useCallback( async () => {
    let response = await APIUtils.callGet('api/contract/NDA_template')
    if (response.status !== 200 || !response.data) {
      return console.error(response);
    }
    return setNDAhtml(response.data);
  }, []);

  const generateProjectTitle = async () => {
    let subjectPrompt = 'Provide the type of technical project this is referring to in 5 words or less without punctuation or the use of the word project: "'+projectInfo.prompt+'"';
    await APIUtils.callGet(`api/gpt?prompt=${subjectPrompt}`)
    .then(response => {
      if(response.status === 200) {
        projectInfo.title = response.data.gpt_response;
        return setProjectInfo(projectInfo);
      }
      return props.showModal({
        type: 'error',
        icon: 'contract',
        title: `Oops`,
        message: 'There was an error generating a title for your project. Please, try again.',
        redirect: `/project/create`,
      });
    })
    .catch((err) => {
      console.error(err);
      return props.showModal({
        type: 'error',
        icon: 'contract',
        title: `Oops`,
        message: 'There was an error generating a title for your project. Please, try again.',
        redirect: `/project/create`,
      });
    })
    .finally(() => {
      setNameGenerated(true);
    });
  }

  const generateProjectDeliverables = async () => {
    try {
      let taskPrompt = 'Given this project descripton: "'+projectInfo.prompt+'", Provide one unformatted separated list of tasks needed to complete this project.';
      let response= await APIUtils.callGet(`api/gpt?prompt=${taskPrompt}`);
      if(response.status === 200) {
        let deliverables: any = response.data.gpt_response.split("\n");

        let sortedDeliverables: any = [];
        let unknownDeliverables: any = [];
        for (let i = 0; i < deliverables.length; i++) {
          let deliverable = deliverables[i].replace('- ', '').trim();
          let deliverableIndex = deliverable.split('.')[0].trim();
          let number = Number(deliverableIndex);
          let isInteger = Number.isInteger(number);

          if (isInteger) {
            sortedDeliverables[number - 1] = deliverable.split('.')[1].trim();
          } else {
            unknownDeliverables.push(deliverable.trim())
          }
        }

        deliverables = sortedDeliverables.concat(unknownDeliverables);
        
        projectInfo.deliverables = deliverables;
        setProjectInfo(projectInfo);
        return setDeliverablesGenerated(true);
      }
      return props.showModal({
        type: 'error',
        icon: 'contract',
        title: `Oops`,
        message: 'There was an error generating deliverables for your project. Please, try again.',
        redirect: `/project/create`,
      });
    } catch (err) {
      console.log(err);
      return [];
    }
  }
  
  const handleIntakeSubmission = async (NDA: boolean) => {
    setIsIntakeLoading(true);
    window.history.pushState(null, '', window.location.pathname);
  
    await generateProjectTitle();
    await generateProjectDeliverables();

    if (NDA) {
      setDraftNDA(true);
      await fetchNDATemplate();
    }

    setTimeout(() => {setProposalDraftGenerated(true)}, 500);
    setTimeout(() => {setShowClientInfo(true)}, 1000);
  }

  const handlePromptChange = useCallback((value: string) => {
    let project: any = {...projectInfo};
    project['prompt'] = value;
    setProjectInfo(project);
  }, [projectInfo]);

  const fetchContact = async (clientID: any) => {
    const response = await APIUtils.callGet(`api/contact/client/${clientID}`);

    let contacts = response.data;

    return contacts[0];
  };

  const fetchClient = async (clientID: any) => {
    const response = await APIUtils.callGet(`api/company/${clientID}`);

    return response.data;
  };

  const handleProjectSubmission = async (clientInfo: Client, clientId: any) => {
    setIsIntakeLoading(false);
    setIsProjectLoading(true);
    setShowClientInfo(false);

    try {
      let clientID: any = null;
      let contactID: any = null;
      let contactInfo: any  = null;

      if (clientId === -1) {
        clientID = await createClient(clientInfo);
        contactInfo = await createContact(clientInfo, clientID);
        contactID = contactInfo.id;
      } else {
        clientID = clientId;
        contactInfo = await fetchContact(clientID);
        contactID = contactInfo.id;

        clientInfo = await fetchClient(clientId);
      }
      
      let projectID = await createProject(clientID);

      let proposalID = await createProposal(clientInfo, projectID, clientID, contactID);

      if (draftNDA) {
        await createNDAContract(projectID, contactID);
      }

      return props.showModal({
        type: 'success',
        icon: 'invoice',
        title: 'Project Successfully Created!',
        message: `Successfully submitted project request to ${userInfo.username}. You will be notified via email when a proposal is ready for review.`,
        redirect: `/invoice/edit/${proposalID}`,
      });
    } catch(err) {
      console.error(err);
      
      return props.showModal({
        type: 'error',
        icon: 'contract',
        title: `Oops`,
        message: 'There was an error submitting your project request. Please, try again.',
        redirect: `/projects`,
      });
    }
  }

  const createClient = async (clientInfo: Client) => {
    let payload = {
      name: clientInfo.name,
      address: clientInfo.address,
      unit: clientInfo.unit,
      city: clientInfo.city,
      state: clientInfo.state,
      zip: clientInfo.zip,
      userid: userInfo.id,
    };
    
    let response = await APIUtils.callPost('api/company/create', payload);
    if(response.status === 200) {
      return response.data;
    }
    throw console.error('unable to create company');
  }

  const createContact = async (clientInfo: Client, clientID: any) => {
    // TODO: fix this to allow for multiple contact creation with primary contact selection
    let payload = {
      email: clientInfo.contacts[0].email,
      firstname: clientInfo.contacts[0].firstname ? clientInfo.contacts[0].firstname : null,
      lastname: clientInfo.contacts[0].lastname ? clientInfo.contacts[0].lastname : null,
      phone: clientInfo.contacts[0].phone ? clientInfo.contacts[0].phone : null,
      companyid: clientID
    };
    
    const response: any = await APIUtils.callPost('api/contact/create', payload);
    if(response.status === 200) {
      return response.data;
    }

    throw console.error('unable to create contact');
  }

  const createProject = async (companyid: string) => {
    let payload = {
      userid: userInfo.id,
      companyid: companyid,
      name: projectInfo.title,
      prompt: projectInfo.prompt,
      status: 'draft'
    };

    let response = await APIUtils.callPut('api/project/create', payload);
    
    if(response.status === 200) {
      return response.data.id;
    }
  }

  const createNDAContract = async (projectid: string, contactid: any) => {
    let date = new Date();
    date.setDate(date.getDate() + 360);

    let payload = {
      contactid,
      userid: userInfo.id,
      projectid: projectid,
      type: 'NDA',
      title: projectInfo.title,
      duration: '6 months',
      template: NDAhtml,
      isdraft: false,
      overuid: true,
      expiryDate: date,
    }
    let response = await APIUtils.callPut('api/contract/create', payload);
    if(response.status === 200) {
      setNDACreated(true);
      return response.data.id;
    }
  }

  const createProposal = async (clientInfo: Client, projectid: string, clientID: string, contactID: string) => {
    let deliverables = projectInfo.deliverables;
    let invoiceItems: any = [];
    deliverables.forEach(deliverable => {
      invoiceItems.push({
        qty: 1,
        price: 0,
        description: deliverable
      });
    });

    let billTo = `${clientInfo.name}
      ${clientInfo.address} ${clientInfo.unit}
      ${clientInfo.city}, ${clientInfo.state} ${clientInfo.zip}
    `;

    let payload: any = {
      projectid: projectid,
      billTo: billTo,
      issuedat:  new Date(),
      total: 0.00,
      subject: projectInfo.title,
      description: projectInfo.prompt,
      clientid: clientID,
      contactid: contactID,
      notes: '',
      isproposal: true,
      userid: userInfo.id,
      intake: true,
    };
    payload.items = [...invoiceItems];

    let response = await APIUtils.callPut('api/invoice/create', payload);
    // TODO: Validate response
    if(response.status === 200) {
      setProposalCreated(true);
      return response.data.id;
    }
  }

  const onBackButtonEvent = useCallback((event: any) => {
    event.preventDefault();
    setIsIntakeLoading(false);
    setShowClientInfo(false);
    handlePromptChange('');
  }, [handlePromptChange]);

  const handleShowClientInfo = () => {
    setShowClientInfo(true);
    setIsIntakeLoading(false);
  }
  
  useEffect(() => {
    if (props.userInfo) {
      setUserInfo(props.userInfo);
    }
	}, [props.userInfo]);

  useEffect(() => {
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);  
    };
  }, [onBackButtonEvent]);

  return (
    <>
    {props.isLoading && (
      <div className="loader"></div>
    )}
    {!props.isLoading && props.userInfo && (
      <div className="componentWrap" id="AdminIntake">
        <DashHead
          pageTitle="Create New Project"
          userInfo={props.userInfo}
          back={() => NavUtils.redirectToProjects()}
        />
        {isIntakeLoading && (
          <IntakeLoader 
            username={props.userInfo?.username}
            nameGenerated={nameGenerated}
            deliverablesGenerated={deliverablesGenerated}
            proposalDraftGenerated={proposalDraftGenerated}
            showModal={handleShowClientInfo}
          />
        )}
        {isProjectLoading && (
          <IntakeProjectLoader 
            showDraftNDA={draftNDA}
            NDACreated={NDACreated}
            proposalCreated={proposalCreated}
            username={userInfo.username}
          />
        )}
        {!isProjectLoading && !isIntakeLoading && !showClientInfo && (
          <IntakePrompt 
            isLoading={props.isLoading}
            isIntakeLoading={isIntakeLoading}
            userInfo={userInfo}
            prompt={projectInfo.prompt}
            update={(value: string) => handlePromptChange(value)}
            submit={(NDA) => handleIntakeSubmission(NDA)}
            showModal={(details) => props.showModal(details)}
          />
        )}
        {!isProjectLoading && !isIntakeLoading && showClientInfo && (
          <IntakeClientInfo 
            showModal={(details) => props.showModal(details)}
            submit={(clientInfo: Client, clientId: number) => handleProjectSubmission(clientInfo, clientId)}
          />
        )}
      </div>
    )}
    </>
  );
}

export default Intake;
