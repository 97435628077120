class UserUtils {
    static getEmptyUserInfo() {
		return {
			firstname: '',
			lastname: '',
			username: '',
			email: '',
			phone: '',
			stripeconnectid: '',
			bankrouting: '',
			bankaccount: '',
		  	trialExpiryDate: '',
			premiumStartDate: '',
		};
	}
}

export default UserUtils;