import {useState, useEffect} from 'react';
import APIUtils from '../../../utils/APIUtils'
import {Modal} from '../../../interfaces/modal';

import {ReactComponent as EmailIcon} from '../../../assets/img/icons/envelope.svg';

const EmailModalCopy = (props: {
  modal: Modal;
  close: () => void;
}) => {
  const [sendLoading, setSendLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  const handleSendEmail = async (data: any) => {
    setSendLoading(true);
    let url;
    if (data.type === 'contract') {
      url = 'api/send_email/contract_link';
    }
    if (data.type === 'invoice') {
      url = 'api/send_email/invoice_link';
    }
    props.modal.data.email = email;
    let response = await APIUtils.callPost(url, props.modal.data);

    if (response.status === 200 && data.type === 'invoice') {
      window.location.reload();
    }

    if (props.modal.callback) {
      props.modal.callback(response);
    }
    setSendLoading(false);
    props.close();
  }

  useEffect(() => {
    if (props.modal.data.email) {
      setEmail(props.modal.data.email);
    }
  }, [props.modal]);

  return (
    <>
    <div className="modalCopy">
      <h1>Email</h1>
      <div className="icon">
        <EmailIcon />
      </div>
      <h3>Send Email</h3>
      <div className="message" dangerouslySetInnerHTML={{__html: props.modal.message}}></div>
      <div className="fieldWrap">
        <h5>Email</h5>
        <input type="email" value={email} placeholder="email@address..." onChange={(e) => setEmail(e.target.value)} />
      </div>
    </div>
    <button className="btn rounded" onClick={() => handleSendEmail(props.modal.data)}>
      {sendLoading && (
        <span><div className="loader"></div></span>
      )}
      {!sendLoading && (
        <span>Send</span>
      )}
    </button>
    </>
  )
}

export default EmailModalCopy;