import _ from 'lodash';
import {useEffect, useState, useCallback} from 'react';
import DashHead from '../Admin/DashHead';

import APIUtils from '../../utils/APIUtils';
import NavUtils from '../../utils/NavUtils';
import EmailUtils from '../../utils/EmailUtils';
import InvoiceTemplate from './InvoiceTemplate';
import PaymentUtils from '../../utils/PaymentUtils';
import QueryParamUtils from '../../utils/QueryParamUtils';

import {Modal} from '../../interfaces/modal';
import {UserInfo} from '../../interfaces/user';
import {Invoice} from '../../interfaces/invoice';
import {Contact} from '../../interfaces/client';

import {ReactComponent as CopyIcon} from '../../assets/img/copy.svg';
// import {ReactComponent as PreviewIcon} from '../../assets/img/preview.svg';
import {ReactComponent as EditIcon} from '../../assets/img/edit.svg';
import {ReactComponent as EmailIcon} from '../../assets/img/icons/envelope.svg';
import {ReactComponent as DeleteIcon} from '../../assets/img/icons/trash.svg';
import InvoiceStatus from './InvoiceStatus';

const PaymentProfileView = (props: {
  userInfo: UserInfo | undefined,
  isLoading: boolean,
  showModal: (details: Modal) => void,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [invoiceFound, setInvoiceFound] = useState<boolean>(true);
  const [pageTitle, setPageTitle] = useState<any>('Invoice #');
  // const [subtotal, setSubtotal] = useState<string>('0.00');
  // const [mainPageTitle, setMainPageTitle] = useState<any>('Invoices');
  const [defaultRecipientEmail, setDefaultRecipientEmail] = useState<String>('');
  const [invoice, setInvoice] = useState<Invoice>({
    id: 0,
    userid: 0,
    clientid: '',
    contactid: '',
    billTo: '',
    description: '',
    discount: '0',
    tax: 0.0,
    total: '0.0',
    notes: '',
    createdat: '',
    updatedat: '',
    issuedat: '',
    hash: '',
    dueat: '',
    subtotal: 0,
    discountAmount: 0,
    taxAmount: 0,
    items: [],
    isproposal: true,
    prompt: 'N/A',
    read_only: true,
    approvedDate: null,
    declinedDate: null,
  });

  // const [createdDate, setCreatedDate] = useState('');
  const [issuedDate, setIssuedDate] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [dueDayCount, setDueDayCount] = useState<string>('');
  const [paidDate, setPaidDate] = useState('');

  const [isLate, setIsLate] = useState<boolean>(false);
  const [isDraft, setIsDraft] = useState<boolean>(false);

  const [copied, setCopied] = useState<boolean>(false);
  const [previewLink, setPreviewLink] = useState('');
  const [editLink, setEditLink] = useState('');

  const [contactEmail, setContactEmail] = useState<string | undefined>('');
  const [contactName, setContactName] = useState<string | undefined>('');

  const fetchInvoice = _.debounce((id: string) => {
    APIUtils.callGet(`api/invoice/${id}`)
    .then(response => {
      if (response.status === 400) {
        setIsLoading(false);
        return setInvoiceFound(false);
      }
      setInvoice(response.data);
      // setMainPageTitle(`${response.data.isproposal ? 'Proposal' : 'Invoice'}`);
      setPageTitle(`${response.data.isproposal ? 'Proposal' : 'Invoice'} #${response.data.id}`);
      setIsLoading(false);
    })
    .catch((err) => {
      return console.error(err);
    });
  }, 300);

  // const convert = () => {
  //   APIUtils.callPost(`api/invoice/${getInvoiceIdFromQueryParams()}`, {isproposal: false, items: invoice.items})
  //   .then(response => {
  //       if (response.status === 200) {
  //         return props.showModal({
  //           type: 'success',
  //           icon: 'invoice',
  //           title: `Invoice #${response.data.id}`,
  //           message: 'Converted to invoice!',
  //           redirect: `/invoice/view/${response.data.id}`,
  //         });
  //       }
  //       return props.showModal({
  //         type: 'error',
  //         icon: 'invoice',
  //         title: `Updating Invoice`,
  //         message: response.message,
  //         redirect: '',
  //       });
  //   })
  //   .catch((err) => {
  //     console.error(err);
  //     return props.showModal({
  //       type: 'error',
  //       icon: 'invoice',
  //       title: `Updating Invoice`,
  //       message: err.message,
  //       redirect: '',
  //     });
  //   });
  // }

  // const markPaid = () => {
  //   APIUtils.callPost(`api/invoice_paid/${getInvoiceIdFromQueryParams()}`)
  //   .then(response => {
  //       if (response.status === 200) {
  //         return props.showModal({
  //           type: 'success',
  //           icon: 'invoice',
  //           title: `Invoice #${response.data.id}`,
  //           message: 'Marked as paid.',
  //           redirect: `/invoice/view/${response.data.id}`,
  //         });
  //       }
  //       return props.showModal({
  //         type: 'error',
  //         icon: 'invoice',
  //         title: `Updating Invoice`,
  //         message: response.message,
  //         redirect: '',
  //       });
  //   })
  //   .catch((err) => {
  //     console.error(err);
  //     return props.showModal({
  //       type: 'error',
  //       icon: 'invoice',
  //       title: `Updating Invoice`,
  //       message: err.message,
  //       redirect: '',
  //     });
  //   });
  // }

  const getInvoiceIdFromQueryParams = (leaveHash=false) => {
    let invoiceId = window.location.href.split("/").pop();
    if (!leaveHash && invoiceId?.indexOf('?') !== -1) {
      invoiceId = invoiceId?.split('?')[0];
    }

    return invoiceId;
  }

  const copyUrl = () => {
    if (props.userInfo === undefined) {
      return;
    }

    let url = `${window.location.origin}/${props.userInfo.username}/invoice/${getInvoiceIdFromQueryParams()}?hash=${invoice.hash}`;
    if (invoice.isproposal === true) {
      url = `${window.location.origin}/${props.userInfo.username}/proposal/${getInvoiceIdFromQueryParams()}?hash=${invoice.hash}`;
    }
    
    navigator.clipboard.writeText(url);
    setCopied(true);
    setTimeout(() => setCopied(false), 500);
  };

  const handleConfirmDeleteInvoice = () => {
    props.showModal({
      type: 'delete',
      icon: 'invoice',
      title: 'Are you sure?',
      message: `Contract #${invoice?.id} will be permenantly deleted. This action cannot be undone.`,
      callback: handleDeleteInvoice,
    });
  }

  const handleDeleteInvoice = async () => {
    let text = invoice.isproposal ? 'Proposal' : 'Invoice';
    try {
      const response = await APIUtils.callDelete(`api/invoice/${getInvoiceIdFromQueryParams()}`);
      if (response.status === 200) {
        return props.showModal({
          type: 'success',
          icon: 'invoice',
          title: `Invoice Deleted`,
          message: `Invoice #${invoice?.id} has been succsessfully deleted`,
          redirect: '/invoices',
        });
      }
    } catch (err) {
      console.log(err);
    }
    return props.showModal({
      type: 'error',
      icon: 'invoice',
      title: `Deleting ${text}`,
      message: `Unable to delete ${text}`,
      redirect: '',
    });
  };

  // const approveProposal = async () => {
  //   await PaymentUtils.approveProposal(invoice, props.showModal);
  // };

  // const declineProposal = async () => {
  //   await PaymentUtils.declineProposal(invoice, props.showModal);
  // };

  const handleEmailBtnClick = () => {
    props.showModal({
      type: 'email',
      icon: 'email',
      title: `Share Invoice`,
      message: `Send invoice to be viewed and/or approved via email.`,
      data: {
        email: defaultRecipientEmail,
        type: 'invoice',
        itemId: invoice?.id,
        userInfo: props.userInfo
      },
      callback: (response) => handleSendEmailResponse(response)
    });
  }

  const handleSendEmailResponse = (response: {
    status: number,
    message: string,
    data: any,
  }) => {
    if (response.status !== 200) {
      props.showModal({
        type: 'error',
        icon: 'email',
        title: `Sending Email`,
        message: `There was an error sending email to recipient.`,
      });
    }
    props.showModal({
      type: 'success',
      icon: 'email',
      title: `Email Sent`,
      message: `An email with the invoice link was sent to ${response.data.email}`,
    });
  }

  const formatDate = (date: Date) => {
    const dateVars = {
      day: date.getDate().toString(),
      month: (date.getMonth() + 1).toString(),
      year: date.getFullYear().toString(),
    }
    return `${dateVars.month}/${dateVars.day}/${dateVars.year}`;
  }

  const treatAsUTC = (date: Date) => {
    let result = new Date(date);
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
    return Number(result);
  }

  const daysBetween = useCallback((startDate: Date, endDate: Date) => {
    let millisecondsPerDay = 24 * 60 * 60 * 1000;
    let value = Math.round((treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay);
    if(value < 0) {
      setIsLate(true);
    }
    return value;
  }, []);

  const handleBackButton = () => {
    let backPage = QueryParamUtils.getParamValue('return');

    if (typeof backPage === 'string' && backPage.length) {
      return window.location.href = backPage;
    }

    NavUtils.redirectToPayments();
  }

  useEffect(() => {
    let invoiceId = getInvoiceIdFromQueryParams(true);
    if (typeof invoiceId === 'string' && invoiceId.length) {
      fetchInvoice(invoiceId);
    }
  }, []);

  useEffect(() => {
    if(!props.userInfo) return;

    let previewLink = `${window.location.origin}/${props.userInfo.username}/invoice/${getInvoiceIdFromQueryParams()}`;
    let editLink = `${window.location.origin}/invoice/edit/${getInvoiceIdFromQueryParams()}`;
    if (invoice.isproposal === true) {
      previewLink = `${window.location.origin}/${props.userInfo.username}/proposal/${getInvoiceIdFromQueryParams()}`;
      editLink = `${window.location.origin}/proposal/edit/${getInvoiceIdFromQueryParams()}`;
    }

    setPreviewLink(previewLink);
    setEditLink(editLink);
  }, [props.userInfo, invoice]);

  useEffect(() => {
    if (invoice.issuedat) {
      setIssuedDate(formatDate(new Date(invoice.issuedat)));
    }

    if (invoice.dueat) {
      setDueDate(formatDate(new Date(invoice.dueat)));
      const currentDate = new Date();
      const due = new Date(invoice.dueat);
      if (due < currentDate) setIsLate(true);
      setDueDayCount(daysBetween(currentDate, due).toString().replace("-",""));
    }

    if (invoice.paidat) {
      setPaidDate(formatDate(new Date(invoice.paidat)));
    }
    
    if (invoice.client && Array.isArray(invoice?.client?.contacts) && invoice.client.contacts.length) {
      if (typeof invoice.client?.contacts[0]?.email === 'string' && EmailUtils.isValid(invoice.client?.contacts[0].email)) {
        setDefaultRecipientEmail(invoice.client.contacts[0].email);
      }
      let contactInfo = invoice.client?.contacts.find((contact: Contact) => contact.id === invoice.contactid);
      setContactEmail(contactInfo?.email);
      if (contactInfo?.firstname && contactInfo?.lastname) {
        return setContactName(`${contactInfo.firstname} ${contactInfo.lastname}`);
      }
    }
  }, [invoice]);

  return (
    <>
    {isLoading && (
      <div className="loader"></div>
    )}
    {!isLoading && props.userInfo && invoiceFound && (
      <div className="componentWrap docProfileView" id="viewInvoice">
        <DashHead
          pageTitle={`${invoice.isproposal ? 'Proposal' : 'Invoice'} for ${invoice.client?.name}`}
          pageSubTitle={invoice.project?.name}
          userInfo={props.userInfo}
          back={handleBackButton}
        />
        <div className="split">
          <div className="details">
            <div className="detailItem">
              <div className="label">
                Status
              </div>
              <div className="value">
                <InvoiceStatus
                  invoice={invoice}
                />
              </div>
            </div>
            <div className="detailItem">
              <div className="label">
                Project
              </div>
              <div className="value">
                {invoice.project?.name && (
                  <>{invoice.project.name}</>
                )}
              </div>
            </div>
            <div className="detailItem">
              <div className="label">
                Client
              </div>
              <div className="value">
                {invoice.client?.name && (
                  <>{invoice.client.name}</>
                )}
              </div>
            </div>
            <div className="detailItem">
              <div className="label">
                Contact Person
              </div>
              <div className="value">
                {!contactName && (
                  <>
                    {contactEmail}
                  </>
                )}
                {contactName && (
                  <>
                    {contactName}
                  </>
                )}
                <a href={`mailto:${contactEmail}`} className="emailBtn">
                  <EmailIcon />
                </a>
              </div>
            </div>
            <div className="detailItem">
              <div className="label">
                Total Amount
              </div>
              <div className="value">
                {invoice.total && (
                  <>{PaymentUtils.formatPrice(invoice.total)}</>
                )}
                {/* FIX: Clean up invoice.discount. Saving as string? */}
                {invoice.total && invoice.discount !== '0' && (
                  <span className="secondary">
                    {`${invoice.discount}% discount`}
                  </span>
                )}
              </div>
            </div>
            <div className="detailItem">
              <div className="label">
                Sent
              </div>
              <div className="value">
                {issuedDate && (
                  issuedDate
                )}
              </div>
            </div>
            <div className="detailItem">
              <div className="label">
                Due
              </div>
              <div className="value">
                {dueDate && (
                  dueDate
                )}
              </div>
            </div>
            {!paidDate && (
            <div className="btnWrap">
              <div className="split">
                <button className="btn rounded" onClick={() => handleEmailBtnClick()}>
                  <span><EmailIcon /> Send To Email</span>
                </button>
                <button className={`btn rounded ${copied ? 'copied' : ''}`} onClick={copyUrl}>
                  <span>
                    <CopyIcon />
                    {
                      copied ? (
                        <>Copied</>
                      ) : (
                        <>Copy Link</>
                      )
                    }
                  </span>
                </button>
              </div>
              { !invoice.read_only &&
                <>
                  <a className="btn rounded outline green editBtn" href={'/invoice/edit/'+getInvoiceIdFromQueryParams()}>
                    <span>
                      <EditIcon /> Edit
                    </span>
                  </a>
                  <div className="or">
                    <span>or</span>
                  </div>
                  <button className="btn rounded outline delete" onClick={handleConfirmDeleteInvoice}>
                    <span>
                      <DeleteIcon /> Delete
                    </span>
                  </button>
                </>
              }
            </div>
            )}

          </div>

          <div className="box preview">
            <InvoiceTemplate 
              userInfo={props.userInfo}
              invoice={invoice}
            />
            <button className="btn rounded" onClick={() => NavUtils.redirectToPublicInvoice(props.userInfo?.username, invoice?.id, invoice?.hash)}>
              <span>Preview</span>
            </button>
          </div>

        </div>

      </div>
      )
    }
    </>
  );
}

export default PaymentProfileView;
