import _ from 'lodash';
import React, {useState, useEffect, useCallback} from 'react';
import DashHead from '../Admin/DashHead';

import APIUtils from '../../utils/APIUtils';
import NavUtils from '../../utils/NavUtils';
import QueryParamUtils from '../../utils/QueryParamUtils';

import ProposalItems from './ProposalItems';
import PaymentUtils from '../../utils/PaymentUtils';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {UserInfo} from '../../interfaces/user';
import {Modal} from '../../interfaces/modal';
import {Invoice} from '../../interfaces/invoice';
import {Contact, Client} from '../../interfaces/client';
import {PaymentItem} from '../../interfaces/invoice';

import {ReactComponent as CopyIcon} from '../../assets/img/copy.svg';
import {ReactComponent as PreviewIcon} from '../../assets/img/preview.svg';
import {ReactComponent as EmailIcon} from '../../assets/img/icons/envelope.svg';

const PaymentEditor = (props: {
  userInfo?:  UserInfo | undefined,
  isLoading:  boolean,
  isProposal: boolean,
  showModal:  (details: Modal) => void,
}) => {

  const [invoice, setInvoice] = useState<any>({
    issueDate: '',
    dueDate: '',
    discount: '0',
    notes: '',
    items: [],
    subject: '',
  });
  const [item, setItem] = useState<any>({});
  const [items, setItems] = useState<any>([]);

  const [issueDate, setIssueDate] = useState<Date | null | undefined>(new Date());
  const [dueDate, setDueDate] = useState<Date | null | undefined>(null);
  const [editOrCreate, setEditOrCreate] = useState('create');
  const [defaultRecipientEmail, setDefaultRecipientEmail] = useState<String>('');

  const [subTotal, setSubTotal] = useState<any>('');
  // const [taxTotal, setTaxTotal] = useState<any>('');
  const [discountTotal, setDiscountTotal] = useState<any>('');
  const [grandTotal, setGrandTotal] = useState<any>('');

  const [client, setClient] = useState<Client>();
  const [contactName, setContactName] = useState<string>('');

  const [copied, setCopied] = useState<boolean>(false);

  const BtnInput = ({ value, onClick }: {value: string; onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void}) => (
    <button className="btn outline" onClick={onClick}>
      {value ? value : <>MM/DD/YYYY</>}
    </button>
  );

  const updateInvoice = (value: any, key: string) => {
    // NOTE: stringify and parse to bypass issue with updating state
    // let invoiceInfo: any = JSON.parse(JSON.stringify(invoice));
    let invoiceInfo: any = {...invoice};
    invoiceInfo[key as keyof Invoice] = value;

    setInvoice(invoiceInfo);
  };

  const getSubtotal = useCallback(() => {
    let total = 0.0;

    items.forEach((lItem: any)  => {
        let qty = parseInt(lItem.qty);
        if (!qty) {
          qty = 1;
        }

        let price = parseFloat(lItem.price);

        total += (qty * price);
    });

    return total.toFixed(2);
  }, [items]);

  // const getTaxTotal = useCallback(() => {
  //   if (!items.length || (invoice.tax !== null && invoice.tax.length === 0)) {
  //       let total = 0.0;
  //       return total.toFixed(2);
  //   }

  //   let total: any = getSubtotal();
  //   let tax = total * parseFloat(Number(invoice.tax / 100).toString());

  //   return tax.toFixed(2);
  // }, [getSubtotal, invoice.tax, items.length]);

  const getDiscountTotal = useCallback(() => {
    if (!items.length || (invoice.discount !== null && invoice.discount.length === 0)) {
        let total = 0.0;
        return total.toFixed(2);
    }

    let total: any = getSubtotal();
    let discount = total * parseFloat(Number(invoice.discount / 100).toString());

    return discount.toFixed(2);
  }, [getSubtotal, invoice.discount, items.length]);

  const getTotal = useCallback(() => {
    if (!items.length) {
        let total = 0.0;
        return total.toFixed(2);
    }

    let total: any = getSubtotal();
    // let tax: any = getTaxTotal();
    let discount: any = getDiscountTotal();
    let grandTotal: any = parseFloat(total);

    // if (tax > 0) {
    //     grandTotal += parseFloat(tax);
    // }

    if (discount > 0) {
        grandTotal -= parseFloat(discount);
    }

    return grandTotal.toFixed(2);
  }, [getDiscountTotal, getSubtotal, items.length]);

  const createInvoiceItem = useCallback(() => {
    let allItems = [...items];
    allItems.push(item);

    setItems(allItems);
  }, [item, items]);

  const removeInvoiceItem = (i: number) => {
    let allItems = [...items];
    allItems.splice(i, 1);
    setItems(allItems.length > 0 ? allItems : []);
    // TODO: clean up format functions
    setSubTotal(getSubtotal());
    // setTaxTotal(getTaxTotal());
    setDiscountTotal(getDiscountTotal());
    setGrandTotal(getTotal());
  }

  const createInvoice = async () => {
    let payload: any = {
        dueat: dueDate,
        issuedat: issueDate,
        clientid: invoice.clientid,
        contactid: invoice.contactid,
        subject: invoice.subject,
        notes: invoice.notes,
        discount: parseInt(invoice.discount),
    };
    payload.items = [...items];
    if(items.length === 0) {
      return props.showModal({
        type: 'error',
        icon: 'invoice',
        title: `Creating ${props.isProposal ? 'Proposal' : 'Invoice'}`,
        message: `Add ${props.isProposal ? 'proposal' : 'invoice'} item.`,
        redirect: '',
      });
    }

    if (props.isProposal) {
      payload.isproposal = true;
    }

    let response = await APIUtils.callPut('api/invoice/create', payload);
    try {
      if (response.status === 200) {
        return props.showModal({
          type: 'success',
          icon: 'invoice',
          title: `${props.isProposal ? 'Proposal' : 'Invoice'} #${response.data.id}`,
          message: `${props.isProposal ? 'Proposal' : 'Invoice'} Created.`,
          redirect: `/invoice/edit/${response.data.id}`,
        });
      }
      return props.showModal({
        type: 'error',
        icon: 'invoice',
        title: `Creating ${props.isProposal ? 'Proposal' : 'Invoice'}`,
        message: `Unable to Create ${props.isProposal ? 'Proposal' : 'Invoice'}.`,
        redirect: '',
      });
    } catch(err) {
      return console.error(err);
    }
  };

  const editInvoice = async () => {
    let payload: any = {
      dueat: dueDate,
      issuedat: issueDate,
      clientid: invoice.clientid,
      contactid: invoice.contactid,
      projectid: invoice.projectid,
      subject: invoice.subject,
      notes: invoice.notes,
      discount: parseInt(invoice.discount),
    };
    payload.items = [...items];

    if(items.length === 0) {
      return props.showModal({
        type: 'error',
        icon: 'invoice',
        title: `Creating ${props.isProposal ? 'Proposal' : 'Invoice'}`,
        message: `Add ${props.isProposal ? 'proposal' : 'invoice'} item.`,
      });
    }

    let response = await APIUtils.callPost(`api/invoice/${getInvoiceIdFromQueryParams()}`, payload);
    try {
      if (response.status === 200) {
        return props.showModal({
          type: 'success',
          icon: 'invoice',
          title: `${props.isProposal ? 'Proposal' : 'Invoice'} #${response.data.id}`,
          message: `${props.isProposal ? 'Proposal' : 'Invoice'} updated.`,
          redirect: `/invoices`,
        });
      }
      return props.showModal({
        type: 'error',
        icon: 'invoice',
        title: `Updating ${props.isProposal ? 'Proposal' : 'Invoice'}`,
        message: response.message,
      });
    } catch(err) {
      return console.error(err);
    }
  };

  const markAsPaid = async () => {
    let response = await APIUtils.callPost(`api/invoice_paid/${getInvoiceIdFromQueryParams()}`);
    try {
      if (response.status === 200) {
        return props.showModal({
          type: 'success',
          icon: 'invoice',
          title: `Invoice Paid`,
          message: `Invoice successfully marked as paid.`,
          redirect: `/invoice/edit/${response.data.id}`,
        });
      }
      return props.showModal({
        type: 'error',
        icon: 'invoice',
        title: `Marking invoice as paid.`,
        message: response.message,
        redirect: '',
      });
    } catch(err) {
      return console.error(err);
    }
  }

  const fetchInvoice = (id: string) => {
    APIUtils.callGet(`api/invoice/${id}`)
    .then(response => {
      setInvoice(response.data);
      setClient(response.data.client);
      setIssueDate(new Date(response.data.issuedat));
      setDueDate(new Date(response.data.dueat));
      setItems(response.data.items);
      setEditOrCreate('edit');
    })
    .catch((err) => {
      return console.error(err);
    });
  };

  const fetchClient = async (id: number) => {
    try {
      let response = await APIUtils.callGet(`api/company/${id}`)
      let client: Client = response.data;
      setClient(client);
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const getInvoiceIdFromQueryParams = () => {
    return window.location.href.split("/").pop();
  }

  const handleKeyPress = (event: any) => {
    if(event.key === 'Enter'){
      if (editOrCreate === 'create') {
        return createInvoice();
      }

      return editInvoice();
    }
  }

  const handleChangeBtnClick = () => {
    return props.showModal({
      type: 'changeBillTo',
      icon: 'user',
      title: `Change Billing`,
      message: 'Assign the client, contact, and project to this invoice.',
      data: {
        client: invoice.client,
        contactid: invoice.contactid,
        projectid: invoice.projectid,
        userid: props.userInfo?.id
      },
      callback: (data) => handleChangeBillTo(data)
    });
  }

  const handleChangeBillTo = (data: {
    clientid: number,
    contactid: number,
    projectid: number,
  }) => {
    let newInvoiceInfo: any = {...invoice};
    if (data.clientid !== invoice.clientid) {
      fetchClient(data.clientid);
      newInvoiceInfo['clientid'] = data.clientid;
    }
    newInvoiceInfo['projectid'] = data.projectid;
    newInvoiceInfo['contactid'] = data.contactid;
    setInvoice(newInvoiceInfo);
  }

  const formatContactName = () => {
    let contactInfo = client?.contacts.find((contact: Contact) => contact.id === invoice.contactid);
    if (contactInfo?.firstname && contactInfo?.lastname) {
      return setContactName(`${contactInfo.firstname} ${contactInfo.lastname}`);
    }
    if (contactInfo) {
      setContactName(contactInfo.email);
    }
  }

  const copyUrl = () => {
    if (props.userInfo === undefined) {
      return;
    }

    let url = `${window.location.origin}/${props.userInfo.username}/invoice/${getInvoiceIdFromQueryParams()}?hash=${invoice.hash}`;
    if (invoice.isproposal === true) {
      url = `${window.location.origin}/${props.userInfo.username}/proposal/${getInvoiceIdFromQueryParams()}?hash=${invoice.hash}`;
    }
    
    navigator.clipboard.writeText(url);
    setCopied(true);
    setTimeout(() => setCopied(false), 500);
  };

  const handleEmailBtnClick = () => {
    props.showModal({
      type: 'email',
      icon: 'email',
      title: `Share Invoice`,
      message: `Send invoice to be viewed and/or approved via email.`,
      data: {
        email: defaultRecipientEmail,
        type: 'invoice',
        itemId: invoice?.id,
        userInfo: props.userInfo
      },
      callback: (response) => handleSendEmailResponse(response)
    });
  }

  const handleSendEmailResponse = (response: {
    status: number,
    message: string,
    data: any,
  }) => {
    if (response.status !== 200) {
      props.showModal({
        type: 'error',
        icon: 'email',
        title: `Sending Email`,
        message: `There was an error sending email to recipient.`,
      });
    }
    props.showModal({
      type: 'success',
      icon: 'email',
      title: `Email Sent`,
      message: `An email with the invoice link was sent to ${response.data.email}`,
    });
  }

  const handleBackButtonPress = () => {
    let backPage = QueryParamUtils.getParamValue('return');
    if (typeof backPage === 'string' && backPage.trim().length && backPage !== 'client') {
      return window.location.pathname = backPage;
    }
    
    NavUtils.redirectToInvoices();
  };

  const handleConfirmDeleteInvoice = () => {
    props.showModal({
      type: 'delete',
      icon: 'invoice',
      title: 'Are you sure?',
      message: `Contract #${invoice?.id} will be permenantly deleted. This action cannot be undone.`,
      callback: handleDeleteInvoice,
    });
  }

  const handleDeleteInvoice = async () => {
    let text = invoice.isproposal ? 'Proposal' : 'Invoice';
    try {
      const response = await APIUtils.callDelete(`api/invoice/${getInvoiceIdFromQueryParams()}`);
      if (response.status === 200) {
        return props.showModal({
          type: 'success',
          icon: 'invoice',
          title: `Invoice Deleted`,
          message: `Invoice #${invoice?.id} has been succsessfully deleted`,
          redirect: '/invoices',
        });
      }
    } catch (err) {
      console.log(err);
    }
    return props.showModal({
      type: 'error',
      icon: 'invoice',
      title: `Deleting ${text}`,
      message: `Unable to delete ${text}`,
      redirect: '',
    });
  };

  useEffect(() => {
    setSubTotal(getSubtotal());
    // setTaxTotal(getTaxTotal());
    setDiscountTotal(getDiscountTotal());
    setGrandTotal(getTotal());
  }, [createInvoiceItem, getDiscountTotal, getSubtotal, getTotal]);

  useEffect(() => {
    if (!props?.userInfo) return;
    
    let invoiceId = getInvoiceIdFromQueryParams();
    if (typeof invoiceId === 'string' && invoiceId.length && invoiceId !== 'create') {
      fetchInvoice(invoiceId);
    }
  }, [props.userInfo]);

  useEffect(() => {
    formatContactName();
  }, [invoice]);

  return (
    <>
    {props.isLoading && (
      <div className="loader"></div>
    )}
    {!props.isLoading && props.userInfo && (
      <div className="componentWrap" id="invoiceEditor">
        <DashHead
          pageTitle={`Edit ${invoice.isproposal ? 'Proposal' : 'Invoice'} for ${invoice.client?.name}`}
          pageSubTitle={invoice.project?.name}
          userInfo={props.userInfo}
          back={handleBackButtonPress}
        />

        <div className="box">
          
          <div className="paymentHead">
            {!invoice.paidat && invoice.issuedat && (
              <button className="btn rounded markAsPaidBtn" onClick={markAsPaid}>
                <span>Mark As Paid</span>
              </button>
            )}
            <button className="btn rounded outline green" onClick={() => handleEmailBtnClick()}>
              <span><EmailIcon /> Email</span>
            </button>
            <button className={`btn rounded outline green`} onClick={copyUrl}>
              <span>
                <CopyIcon />
                {
                  copied ? (
                    <>Copied</>
                  ) : (
                    <>Copy Link</>
                  )
                }
              </span>
            </button>
            <button className="btn rounded outline green" onClick={() => NavUtils.redirectToPublicInvoice(props.userInfo?.username, invoice?.id, invoice?.hash)}>
              <span><PreviewIcon /> Preview</span>
            </button>
            { !invoice.approvedDate &&
              <button className="btn rounded" onClick={editInvoice}>
                <span>Save</span>
              </button>
            }
          </div>

          <div className="editorWrap">
            <div className="split top">

              <div className="fieldWrap dates">
                <div className="dateWrap">
                  <div className="label">Issue Date</div>
                  <DatePicker
                    disabled={typeof invoice.approvedDate === 'string' && invoice.approvedDate.length}
                    selected={issueDate}
                    onChange={(date: Date) => setIssueDate(date)}
                    customInput={React.createElement(BtnInput)}
                    onKeyDown={handleKeyPress}
                  />
                </div>
                <div className="dateWrap">
                  <div className="label">Due Date</div>
                  <DatePicker
                    disabled={typeof invoice.approvedDate === 'string' && invoice.approvedDate.length}
                    selected={dueDate}
                    onChange={(date: Date) => setDueDate(date)}
                    customInput={React.createElement(BtnInput)}
                    onKeyDown={handleKeyPress}
                  />
                </div>
              </div>

              <div className="fieldWrap">
                <div className="label">Bill To</div>
                {contactName}
                <address>
                  {client?.name}
                  <br />
                  {client?.address}
                  <br />
                  {client?.city}, {client?.state} {client?.zip}
                </address>
                {!invoice.approvedDate &&
                  <button className="btn rounded inline" onClick={handleChangeBtnClick}>
                    <span>Change</span>
                  </button>
                }
              </div>

            </div>

            <div className="fieldWrap">
              <div className="label">Subject</div>
              <input value={invoice.subject ? invoice.subject: ''} onChange={!invoice.approvedDate ? e => {updateInvoice(e.target.value, 'subject')} : e => {}} placeholder={`Add ${props.isProposal ? 'a proposal' : 'an invoice'} subject or descriptor...`} />
            </div>

            <ProposalItems
              invoiceApproved={typeof invoice.approvedDate === 'string' && invoice.approvedDate.length}
              items={items}
              update={(items: PaymentItem[]) => setItems(items)}
              remove={(index: number) => removeInvoiceItem(index)}
            />

            <div className="fieldWrap">
              <div className="label">Notes / Payment Terms</div>
              <textarea rows={5} cols={50} value={invoice.notes !== null ? invoice.notes : ''} onChange={!invoice.approvedDate ? e => {updateInvoice(e.target.value, 'notes')} : (e) => {}} />
            </div>

            <div className="fieldWrap">
              <div className="slideContainer">
                <div className="label">Discount %</div>
                <input type="number" value={invoice.discount} min="0" placeholder="0" onChange={!invoice.approvedDate ? e => {updateInvoice(e.target.value, 'discount')} : (e) => {}} />
              </div>
            </div>
            
            <div className="fieldWrap invoiceSummary">
              <div className="lineItem">
                <span>Subtotal</span>
                <span>{PaymentUtils.formatPrice(subTotal)}</span>
              </div>
              {/* {taxTotal !== '0' && (
                <div className="lineItem">
                  <span>Tax %</span>
                  <span>{PaymentUtils.formatPrice(taxTotal)}</span>
                </div>
              )} */}
              {discountTotal !== '0.00' && (
                <div className="lineItem discount">
                  <span>Discount</span>
                  <span className="value">- {PaymentUtils.formatPrice(discountTotal)}</span>
                </div>
              )}
            </div>

            <div className="fieldWrap paymentTotal">
              <div className="lineItem">
                <div className="label">Total</div>
                <div className="price">
                  {PaymentUtils.formatPrice(grandTotal)}
                </div>
              </div>
            </div>

            <div className="btnWrap">
              {editOrCreate === "create" && (
                <button className="btn rounded" onClick={createInvoice}>
                  <span>Create</span>
                </button>
              )}
              {editOrCreate === "edit" && !invoice.approvedDate && (
                <>
                <button className="btn rounded" onClick={editInvoice}>
                  <span>Save</span>
                </button>
                <div className="or darker"><span>or</span></div>
                <button className="btn rounded outline red" onClick={handleConfirmDeleteInvoice}>
                  <span>Delete</span>
                </button>
                </>
              )}
            </div>
          </div>

        </div>
      </div>
    )}
    </>
  )
}

export default PaymentEditor;
