import {useState, useEffect, useRef} from 'react';
import DashHead from '../Admin/DashHead';
import AdminUserMetrics from './AdminUserMetrics';
import AdminProjectMetrics from './AdminProjectMetrics';
import AdminInvoiceMetrics from './AdminInvoiceMetrics';
import AdminContractMetrics from './AdminContractMetrics';
import AdminUserList from './AdminUserList';
import APIUtils from '../../utils/APIUtils';
import {UserInfo} from '../../interfaces/user';

const Dashboard = (props: {
  userInfo: UserInfo | undefined,
  isLoading: boolean,
}) => {
  const userMetricsRef = useRef(false);
  const invoiceMetricsRef = useRef(false);
  const contractMetricsRef = useRef(false);
  const projectMetricsRef = useRef(false);

  const [userMetrics, setUserMetrics] = useState<any>({});
  const [contractMetrics, setContractMetrics] = useState<any>({});
  const [invoiceMetrics, setInvoiceMetrics] = useState<any>({});
  const [projectMetrics, setProjectMetrics] = useState<any>({});

  const retrieveUserMetrics = () => {
    if(userMetricsRef.current) return;
    userMetricsRef.current = true;
    APIUtils.callGet('api/admin/total_users')
    .then(response => {
      setUserMetrics(response.data);
    })
    .catch((err) => {
      return console.error(err);
    });
  }

  const retrieveContractMetrics = () => {
    if(contractMetricsRef.current) return;
    contractMetricsRef.current = true;
    APIUtils.callGet('api/admin/total_contracts')
    .then(response => {
      setContractMetrics(response.data);
    })
    .catch((err) => {
      return console.error(err);
    });
  }

  const retrieveInvoiceMetrics = () => {
    if(invoiceMetricsRef.current) return;
    invoiceMetricsRef.current = true;
    APIUtils.callGet('api/admin/total_invoices')
    .then(response => {
      setInvoiceMetrics(response.data);
    })
    .catch((err) => {
      return console.error(err);
    });
  }

  const retrieveProjectMetrics = () => {
    if(projectMetricsRef.current) return;
    projectMetricsRef.current = true;
    APIUtils.callGet('api/admin/total_projects')
    .then(response => {
      setProjectMetrics(response.data);
    })
    .catch((err) => {
      return console.error(err);
    });
  }

  useEffect(() => {
    if (!props.userInfo) { return; }
    
    retrieveUserMetrics();
    retrieveProjectMetrics();
    retrieveInvoiceMetrics();
    retrieveContractMetrics();
  }, [props.userInfo]);

  return (
    <>
    {props.isLoading && (
      <div className="loader"></div>
    )}
    {!props.isLoading && props.userInfo && (
      <div className="componentWrap" id="adminDash">
        <DashHead
          pageTitle="Admin KPI Dashboard"
          userInfo={props.userInfo}
        />
        <div className="pageContent">

          <div className="main">
            <AdminUserMetrics
              {...userMetrics}
            />
            <AdminProjectMetrics
              {...projectMetrics}
            />
            <AdminInvoiceMetrics 
              {...invoiceMetrics}
            />
            <AdminContractMetrics
              {...contractMetrics}
            />
            <AdminUserList />
          </div>

        </div>
      </div>
    )}
    </>
  );
}

export default Dashboard;
