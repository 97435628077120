import React, {useState} from 'react';
import APIUtils from '../../utils/APIUtils';

const StripeConnectCTA = (props: {
  isConnected: boolean,
  stripeConnectID: string,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const launchSetupWizard = () => {
    setIsLoading(true);
    APIUtils.callGet(`api/payment/connect/${props.stripeConnectID}`)
    .then(response => {
      window.location = response.data;
    })
    .catch((err) => {
      return console.error(err);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  return (
    <>
      {!props.isConnected && (
        <div className="potionPayCTA">
          <h3>Setup Potion Pay to process card payments through Stripe Connect.</h3>
          <button className="btn outline light rounded" onClick={() => launchSetupWizard()}>
            {isLoading && (
              <span><div className="loader"></div></span>
            )}
            {!isLoading && (
              <span>Setup Payments</span>
            )}
          </button>
        </div>
      )}
    </>
  );
}

export default StripeConnectCTA;
