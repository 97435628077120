import NavUtils from '../../utils/NavUtils';
import PaymentUtils from '../../utils/PaymentUtils';

import {ReactComponent as CheckIcon} from '../../assets/img/icons/check.svg';
import {ReactComponent as TimeIcon} from '../../assets/img/icons/time.svg';
import {ReactComponent as ClockIcon} from '../../assets/img/icons/clock.svg';

const InvoiceDashMetrics = (props: {
  collected: string;
  pending: string;
  outstanding: string;
  showTitle?: boolean;
  setFilter?: (filter: string) => void;
}) => {
  const handleFilterClick = (filter: string) => {
    if (props.showTitle) {
      return NavUtils.redirectToInvoiceListing(filter);
    }
    if (props.setFilter) {
      return props.setFilter(filter);
    }
  };

  const handleCreateInvoiceClick = () => {
    let source: any = 'dashboard';

    NavUtils.redirectToCreateInvoice(source);
  };

  return (
    <div className="metricWrap">
      {props.showTitle && (
        <div className="headerWrap">
          <h2>Invoices</h2>
          <button className="btn outline rounded icon add" onClick={handleCreateInvoiceClick}>
            <span></span>
            <span></span>
          </button>
        </div>
      )}
      <div className="statsWrap" id="invoiceStats">
        <div className="box" onClick={() => handleFilterClick('paid')}>
          <div className="top">
            <div className="icon green">
              <CheckIcon />
            </div>
          </div>
          <div>
            <span className="label">Paid</span>
            <div className="metric price">
              {PaymentUtils.formatPrice(props.collected)}
            </div>
          </div>
        </div>
        <div className="box" onClick={() => handleFilterClick('pending')}>
          <div className="top">
            <div className="icon yellow">
              <TimeIcon />
            </div>
          </div>
          <div>
            <span className="label">Pending</span>
            <div className="metric price">{PaymentUtils.formatPrice(props.pending)}</div>
          </div>
        </div>
        <div className="box warn" onClick={() => handleFilterClick('overdue')}>
          <div className="top">
            <div className="icon red">
              <ClockIcon />
            </div>
          </div>
          <div>
            <span className="label">Overdue</span>
            <div className="metric price">{PaymentUtils.formatPrice(props.outstanding)}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvoiceDashMetrics;
