import React, {useState, useEffect} from 'react';

const ProgressBar = (props: {
  percent: number;
}) => {
  const [percent, setPercent] = useState(0);
  useEffect(() => {
    setTimeout(() => setPercent(props.percent), 300);
  }, [props.percent])
  return (
    <div className="progressBar">
      <div className="filledBar" style={{width: `${percent}%`}}></div>
    </div>
  )
}
export default ProgressBar;
