import {useState, useEffect} from 'react';

import LoginForm from './LoginForm';
import ForgotPassword from './ForgotPassword';
import Register from './Register';
import PasswordReset from './PasswordReset';

import {Modal} from '../../interfaces/modal';

import {ReactComponent as Logo} from '../../assets/img/logo.svg';
import loginImage from '../../assets/img/login-bg.jpg';

const Login = (props: {
  isLoading: boolean,
  isRegister?: boolean,
  isReset?: boolean,
  showModal: (details: Modal) => void,
}) => {
  const [showForgot, setShowForgot] = useState<boolean>(false);
  const [showRegister, setShowRegister] = useState<boolean>(false);
  const [showReset, setShowReset] = useState<boolean>(false);

  useEffect(() => {
    if (props.isRegister) {
      setShowRegister(true);
    }
    if (props.isReset) {
      setShowReset(true);
    }
  }, [props]);

  return (
    <>
    {props.isLoading && (
      <div className="loader"></div>
    )}
    {!props.isLoading && (
      <div id="Login" tabIndex={-1}>
        
          <div className="image" style={{backgroundImage: `url(${loginImage})`}}>
            <div>
              <Logo />
              <h1>
                The Engine
                <br />
                To Power
                <br />
                Your Freelance
                <br />
                Business
              </h1>
            </div>
          </div>

          <div className="form">
            <div className="formWrap">

              {!showForgot && !showRegister && !showReset && (
                <LoginForm 
                  setShowForgot={() => setShowForgot(true)}
                  register={() => setShowRegister(true)}
                  showModal={(details) => props.showModal(details)}
                />
              )}

              {showForgot && (
                <ForgotPassword 
                  setShowForgot={() => setShowForgot(false)}
                  showModal={(details) => props.showModal(details)}
                />
              )}

              {showRegister && (
                <Register
                  register={() => setShowRegister(false)}
                  showModal={(details) => props.showModal(details)}
                />
              )}

              {showReset && (
                <PasswordReset
                  reset={() => setShowReset(false)}
                  showModal={(details) => props.showModal(details)}
                />
              )}

            </div>
          </div>

      </div>
    )}
    </>
  );
}

export default Login;
