import {useState, useEffect, useCallback} from 'react';
import {Contract} from '../../interfaces/contract';

const ContractStatus = (props: {
  contract: Contract;
}) => {

  const [isExpired, setIsExpired] = useState<boolean>(false);
  const [dueDayCount, setDueDayCount] = useState('');
  const [signedDate, setSignedDate] = useState('');

  const checkIsDraft = () => {
    
  }

  const treatAsUTC = (date: Date) => {
    let result = new Date(date);
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
    return Number(result);
  }

  const daysBetween = useCallback((startDate: Date, endDate: Date) => {
    let millisecondsPerDay = 24 * 60 * 60 * 1000;
    let value = Math.round((treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay);
    if(value < 0) {
      setIsExpired(true);
    }
    return value;
  }, []);

  useEffect(() => {
    // if (props.contract) {
    //   let isDraft = checkIsDraft();

    //   setIsDraft(isDraft);
    // }
    if (props.contract.expiryDate) {
      const currentDate = new Date();
      const due = new Date(props.contract.expiryDate);
      if (due < currentDate) setIsExpired(true);

      setDueDayCount(daysBetween(currentDate, due).toString().replace("-",""));
    }
    if (props.contract.signedDate) {
      const signed = new Date(props.contract.signedDate);

      setSignedDate(`${signed.getMonth()}/${signed.getDate()}/${signed.getFullYear()}`)
    }

  }, [props.contract]);

  const statusClass = `
    ${props.contract.isdraft ? 'draft' : ''}
    ${!props.contract.isdraft && !props.contract.signedDate && !props.contract.declinedDate && !isExpired ? 'pending' : ''}
    ${!props.contract.isdraft && props.contract.signedDate ? 'signed' : ''}
    ${!props.contract.isdraft && props.contract.declinedDate ? 'declined' : ''}
    ${!props.contract.isdraft && !props.contract.signedDate && !props.contract.declinedDate && isExpired ? 'expired' : ''}
  `;

  return (
    <>
    <div className={`status ${statusClass}`}>
      {statusClass}
    </div>
    {!props.contract.signedDate && dueDayCount && !isExpired && (
      <div className="details">
        {parseInt(dueDayCount) === 1 && (
          <>Due in {dueDayCount} day</>
        )}
        {parseInt(dueDayCount) === 0 && (
          <>Due today</>
        )}
        {parseInt(dueDayCount) > 1 && (
          <>Due in {dueDayCount} days</>
        )}
      </div>
    )}
    {props.contract.signedDate && (
      <div className="details">
        Signed on {signedDate}
      </div>
    )}
    </>
  )
}

export default ContractStatus;
