import React from 'react';

const AdminProjectMetrics = (props: {
  count: number,
  draft: number,
  active: number,
  completed: number
}) => {
  
  return (
    <div className="metricWrap">
      <div className="headerWrap">
        <h2>Projects</h2>
      </div>
      <div className="adminStats" id="adminProjectStats">
        <div className="box">
          <div className="metric">{props.count}</div>
          <span className="label">Total</span>
        </div>
        <div className="box">
          <div className="metric">{props.draft}</div>
          <span className="label">Draft</span>
        </div>
        <div className="box">
          <div className="metric">{props.active}</div>
          <span className="label">Active</span>
        </div>
        <div className="box">
          <div className="metric">{props.completed}</div>
          <span className="label">Completed</span>
        </div>
      </div>
    </div>
  );
}

export default AdminProjectMetrics;
