import _ from 'lodash';

import DashHead from '../Admin/DashHead';
import ProjectsListing from './ProjectsListing';

import {UserInfo} from '../../interfaces/user';
import {Modal} from '../../interfaces/modal';

const Projects = (props: {
  userInfo?: UserInfo | undefined;
  isLoading: boolean;
  showModal: (details: Modal) => void
}) => {
  // const [searchTerm, setSearchTerm] = useState<string>('');
  // const [filter, setFilter] = useState<string>('');

  // const retrieveProjects = async () => {
  //   try {
  //     let url = `api/project/all`;

  //     if (searchTerm.trim().length) {
  //       url += `?search=${searchTerm}`
  //     }

  //     if (filter.trim().length) {
  //       if (searchTerm.trim().length) {
  //         url += `&filter=${filter}`
  //       } else {
  //         url += `?filter=${filter}`
  //       } 
  //     }

  //     let response: any = await APIUtils.callGet(url);
  //     let projects: Project[] = response.data;
      
  //     setProjects(projects);
  //     setProjectLoading(false);
  //   } catch (err) {
  //     console.log(err);
  //     return [];
  //   }
  // };

  // const debounceRetrieveProjects = _.debounce(retrieveProjects, 500);

  // const search = _.debounce((event: any) => {
  //   setSearchTerm(event.target.value);
  // }, 300);

  // const getNDALink = () => {
  //   if (selectedProject?.contracts === undefined) {
  //     return '#';
  //   }
    
  //   let nda = selectedProject.contracts.find(contract => {
  //     if (typeof contract.type !== 'string') {
  //       return false;
  //     }

  //     return contract.type.toLowerCase() === "nda";
  //   });

  //   if (nda === null || typeof nda !== 'object') {
  //     return '#';
  //   }

  //   if (props.userInfo?.id === nda.userid) {
  //     return `/contract/view/${nda.id}`;
  //   }

  //   return `/contract/${nda.id}?hash=${nda.hash}`;
  // }

  // const getSOWLink = () => {
  //   if (selectedProject?.contracts === undefined) {
  //     return '#';
  //   }
    
  //   let sow = selectedProject.contracts.find(contract => {
  //     if (typeof contract.type !== 'string') {
  //       return false;
  //     }

  //     return contract.type.toLowerCase() === "sow";
  //   });

  //   if (sow === null || typeof sow !== 'object') {
  //     return '#';
  //   }

  //   if (props.userInfo?.id === sow.userid) {
  //     return `/contract/view/${sow.id}`;
  //   }

  //   return `/contract/${sow.id}?hash=${sow.hash}`;
  // }

  // const getProposalLink = () => {
  //   if (selectedProject?.proposal === undefined) {
  //     return '#';
  //   }
    
  //   let proposal = selectedProject.proposal;

  //   if (proposal === null || typeof proposal !== 'object') {
  //     return '#';
  //   }

  //   if (props.userInfo?.id === selectedProject.proposal.userid) {
  //     return `/invoice/view/${proposal.id}?hash=${proposal.hash}`;
  //   }

  //   return `${proposal.publicUrl}?hash=${proposal.hash}`;
  // }

  // const getInvoiceLink = () => {
  //   if (selectedProject?.invoice === undefined) {
  //     return '#';
  //   }
    
  //   let invoice = selectedProject.invoice;

  //   if (invoice === null || typeof invoice !== 'object') {
  //     return '#';
  //   }
    
  //   if (props.userInfo?.id === selectedProject.invoice.userid) {
  //     return `/invoice/view/${invoice.id}?hash=${invoice.hash}`;
  //   }

  //   return `${invoice.publicUrl}?hash=${invoice.hash}`;
  // }

  // const navigateToNDA = () => {
  //   let ndaLink = getNDALink();

  //   window.open(ndaLink);
  // };

  // const navigateToScopeOfWork = () => {
  //   let sowLink = getSOWLink();

  //   window.open(sowLink);
  // };

  // const navigateToProposal = () => {
  //   let link = getProposalLink();

  //   window.open(link);
  // };

  // const navigateToInvoice = () => {
  //   let link = getInvoiceLink();

  //   window.open(link);
  // };

  // const getMailTo = (): string => {
  //   let email = selectedProject !== undefined ? selectedProject.contacts[0].email : '';
    
  //   return `mailto:${email}`;
  // };

  // const getTel = (): string => {
  //   let phone = selectedProject !== undefined ? selectedProject.contacts[0].phone : '';
    
  //   return `tel:${phone}`;
  // };

  // const updateSelectedFilterText = (filter: string) => {
  //   if (filter === 'All') {
  //     filter = '';
  //   }
  //   setFilter(filter.toLowerCase());
  // }
  
  // useEffect(() => {
  //   (async () => {
  //     await debounceRetrieveProjects();
  //   })();
  // }, [searchTerm, filter]);

  return (
    <>
    {props.isLoading && (
      <div className="loader"></div>
    )}
    {!props.isLoading && props.userInfo && (
      <div className="componentWrap" id="projects">
        <DashHead
          pageTitle={'Projects'}
          userInfo={props.userInfo}
          create={'project'}
          showModal={(details: Modal) => props.showModal(details)}
        />
        <div className="pageContent">
          <ProjectsListing />
        </div>
      </div>
    )}
    </>
  );
}

export default Projects;
