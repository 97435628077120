import {useState, useCallback, useEffect} from 'react';
import APIUtils from '../../utils/APIUtils';
import EmailUtils from '../../utils/EmailUtils';

import {Modal} from '../../interfaces/modal';

const ForgotPassword = (props: {
  email?: string;
  setShowForgot: Function;
  showModal: (details: Modal) => void;
}) => {

  const [loginLoading, setLoginLoading] = useState(false);
  const [error, setError] = useState<string>('');

  const [forgotEmail, setForgotEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);

  const validateEmail = useCallback((email: string) => {
    if(!email) {
      setError('Enter email address.');
      return false;
    }
    if(!EmailUtils.isValid(email)) {
      setError('Enter a valid email address.');
      return false;
    }
    setIsValidEmail(EmailUtils.isValid(email));
    return true;
  }, []);

  const handleForgotPassword = () => {
    setLoginLoading(true);
    let isValid = validateEmail(forgotEmail);
    if (!isValid) return setLoginLoading(false);

    APIUtils.callPost('api/account/forgot', {forgotEmail: forgotEmail})
		.then(response => {
      if(response.status !== 200) {
        return props.showModal({
          type: 'error',
          icon: 'user',
          title: 'Forgot Password',
          message: response.message,
          redirect: '',
        });
      }
      return props.showModal({
        type: 'success',
        icon: 'user',
        title: 'Passowrd reset',
        message: `A password reset email has been sent to ${response.data.userInfo.email}.`,
        redirect: '/',
      });
    })
		.catch((err) => {
      console.error(err.message);
      return props.showModal({
        type: 'error',
        icon: 'user',
        title: 'Password Reset',
        message: err.message,
        redirect: '',
      });
		})
    .finally(() => {
      setLoginLoading(false);
    });
  }

  const handleForgotPasswordEnter = (event: any) => {
    if(event.key === 'Enter'){
      handleForgotPassword();
    }
  }

  useEffect(() => {
    if (props.email) {
      setForgotEmail(props.email);
    }
  }, [props.email]);

  useEffect(() => {
    if (forgotEmail) {
      validateEmail(forgotEmail);
    }
    if (isValidEmail) {
      setError('');
    }
  }, [forgotEmail]);

  return (
    <>
      <div className="formHead">
        <p>Forgot your password?</p>
        <h2>Send a password reset email</h2>
        <p>If you're logged out and can't remember your password, we can send you an email with a link to reset your password.</p>
      </div>

      <div className="formBody">
        {
          error && (
            <div className="alert error">
              {error}
            </div>
          )
        }
        <div className="field">
          <div className="label">Email</div>
          <input className={(!isValidEmail) || (!forgotEmail) ? 'error' : ''} type="text" name="email" onChange={(e) => setForgotEmail(e.currentTarget.value)} value={props.email} placeholder="Email" autoComplete="off" autoCapitalize="off" onKeyDown={handleForgotPasswordEnter} />
        </div>
        <button className="btn rounded inline" onClick={() => handleForgotPassword()}>
          {loginLoading && (
            <span><div className="loader"></div></span>
          )}
          {!loginLoading && (
            <span>Recover Password</span>
          )}
        </button>
      </div>

      <div className="formLink">
        <p>Have an account? <button className="loginLink" onClick={() => props.setShowForgot(false)}>Sign In</button></p>
      </div>
    </>
  )
}

export default ForgotPassword;
