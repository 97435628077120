import _ from 'lodash';
import {useState, useEffect} from 'react';
import APIUtils from '../../utils/APIUtils';
import NavUtils from '../../utils/NavUtils';
import RecentActivityLoading from '../Admin/RecentActivityLoading';
import {Activity} from '../../interfaces/activity';

import {ReactComponent as CheckIcon} from '../../assets/img/icons/check.svg';
import {ReactComponent as SignedIcon} from '../../assets/img/icons/signed.svg';
import {ReactComponent as DeclinedIcon} from '../../assets/img/icons/declined.svg';
import {ReactComponent as InvoiceIcon} from '../../assets/img/icons/invoices.svg';
import {ReactComponent as ContractIcon} from '../../assets/img/icons/contracts.svg';
import {ReactComponent as ProjectIcon} from '../../assets/img/icons/projects.svg';
import {ReactComponent as UserIcon} from '../../assets/img/individual.svg';

const RecentActivity = (props: {
  userID?: number;
}) => {
  const [activityLoading, setActivityLoading] = useState(true);
  const [userActivity, setUserActivity] = useState<Activity[]>([]);

  const retrieveAccountActivity = () => {
    APIUtils.callGet(`api/activity`)
    .then(response => {
      setUserActivity(response.data);
      setActivityLoading(false);
    })
    .catch((err) => {
      return console.error(err);
    });
  };

  const debouncedRetrieveAccountActivity = _.debounce(retrieveAccountActivity, 500);

  const activityTypeFriendly = (activity: any) => {
    const id = `#${activity.activityID}`;
    const name = activity.activityName ? activity.activityName : `#${activity.activityId}`;
    const value = activity.activityAmount;
    
    switch (activity.activityType) {
      case 'contract_create':
      case 'contract':
        return `Contract ${id} with ${name} has been created.`;
      case 'contract_submitted':
        return `Contract ${id} submitted to ${name}.`;
      case 'contract_draft':
        return `Your contract with ${name} has been drafted.`;
      case 'contract_edit':
        return `Your contract with ${name} has been updated.`;
      case 'contract_delete':
        return `${name} has deleted contract ${id}.`;
      case 'invoice_create':
        return `Invoice ${id} for ${name} has been created.`;
      case 'invoice_submitted':
        return `${name} paid you ${value} for invoice ${id}`;
      case 'proposal_create':
        return `Proposal ${id} for ${name} has been created.`;
      case 'proposal_submitted':
        return `Proposal ${id} submitted to ${name} for approval.`;
      case 'proposal_edit':
          return `${name} edited proposal ${id}.`;
      case 'proposal_delete':
          return `${name} deleted proposal ${id}.`;
      case 'invoice_draft':
        return `Proposal ${id} for ${name} has been drafted.`;
      case 'invoice_edit':
        return `${name} edited invoice ${id}.`;
      case 'invoice_delete':
        return `Invoice ${id} has been deleted by ${name}.`;
      case 'project_create':
        return `Project ${id} for ${name} has been created.`;
      case 'project_submitted':
        return `Project Submitted to ${name}`;
      case 'contact_create':
        return `Contact ${name} has been created.`;
      case 'contact_delete':
        return `Contact ${name} has been deleted.`;
      case 'company_create':
        return `Client ${name} has been created.`;
      case 'company_delete':
        return `Client ${name} has been deleted.`;
    }
  }

  const activityIcon = (activity: any) => {
    switch (activity.activityType) {
      case 'contract_submitted':
      case 'contract_create':
      case 'contract_draft':
      case 'contract_edit':
      case 'contract':
        return <div className="icon green"><ContractIcon /></div>
      case 'contract_delete':
        return <div className="icon red"><DeclinedIcon /></div>
      case 'invoice_create':
        return <div className="icon white"><ContractIcon /></div>
      case 'invoice_submitted':
        return <div className="icon green"><CheckIcon /></div>
      case 'proposal_create':
        return <div className="icon white"><InvoiceIcon /></div>
      case 'proposal_submitted':
        return <div className="icon green"><CheckIcon /></div>
      case 'invoice_draft':
        return <div className="icon white"><InvoiceIcon /></div>
      case 'invoice_edit':
      case 'proposal_edit':
        return <div className="icon white"><InvoiceIcon /></div>
      case 'invoice_delete':
      case 'proposal_delete':
        return <div className="icon red"><DeclinedIcon /></div>
      case 'project_create':
        return <div className="icon white"><ProjectIcon /></div>
      case 'project_submitted':
        return <div className="icon green"><ProjectIcon /></div>
      case 'contact_create':
        return <div className="icon green"><UserIcon /></div>
      case 'contact_delete':
        return <div className="icon red"><UserIcon /></div>
      case 'company_create':
        return <div className="icon green"><UserIcon /></div>
      case 'company_delete':
        return <div className="icon red"><UserIcon /></div>
    }
  }

  useEffect(() => {
    if (props.userID === undefined) return;
    debouncedRetrieveAccountActivity();
  }, [props.userID]);

  return (
    <>
      {activityLoading && (
        <div id="recentActivity">
          <RecentActivityLoading />
        </div>
      )}
      {
        !activityLoading && userActivity.length > 0 && (
          <div id="recentActivity">
            <h2>Recent Activity</h2>
            {
              !activityLoading && userActivity.map(
                (activity, i) => {
                  const created = new Date(activity.createdat);
                  const monthNames = [
                    "Jan", "Dec", "March", "April", "May",
                    "June","July", "Aug", "Sept", "Oct", "Nov", "Dec",
                  ];

                  const handleActivityItemClick = () => {
                    const hash = typeof activity.hash === 'string' ? activity.hash : '';

                    console.log(activity.activityType);

                    if (!activity.activityType.includes('delete')) {
                      if (activity.activityType.includes('invoice') || activity.activityType.includes('proposal')) {
                        NavUtils.redirectToActivityItem('invoice', activity.activityID, hash, window.location.pathname);
                      }
                      if (activity.activityType.includes('contract')) {
                        NavUtils.redirectToActivityItem('contract', activity.activityID, hash, window.location.pathname);
                      }
                      if (activity.activityType.includes('project')) {
                        NavUtils.redirectToViewProject(activity.activityID, window.location.pathname);
                      }
                      if (activity.activityType.includes('contact') || activity.activityType.includes('company')) {
                        NavUtils.redirectToSingleClient(activity.activityID, window.location.pathname);
                      }
                    }
                    return;
                  }

                  const message = activityTypeFriendly(activity);

                  const hour = (created.getHours() % 12) !== 0 ? (created.getHours() % 12) : 12;
                  const minute = created.getMinutes() < 10 ? `0${created.getMinutes()}` : created.getMinutes();
                  const amorpm = (created.getHours() >= 12) ? "pm" : "am";
                  const time = `${hour}:${minute.toString()} ${amorpm}`;

                  return (
                    <div key={activity.id} className={!activity.activityType.includes('delete') ? "ListItem link" : "ListItem disabled"} onClick={() => handleActivityItemClick()}>
                      <div className="left">
                        {activityIcon(activity)}
                        <h4>
                          {message}
                          <div className="time">
                            {monthNames[created.getMonth()]} {created.getDate()} at {time}
                          </div>
                        </h4>
                      </div>
                    </div>
                  )

                }
              )
            }
          </div>
        )
      }
    </>
  )
}

export default RecentActivity;
