import _ from 'lodash';
import {useState, useEffect} from 'react';
import {Modal} from '../../interfaces/modal';
import {UserInfo} from '../../interfaces/user';
import PaymentUtils from '../../utils/PaymentUtils';

const CreateInvoiceStepThree = (props: {
  userInfo?:  UserInfo | undefined,
  invoice: any,
  invoiceItems: any[],
  updateInvoiceInfo: any,
  handleContinueClick: any,
  showModal:  (details: Modal) => void,
}) => {
  const [subTotal, setSubTotal] = useState('0.00');
  const [taxTotal, setTaxTotal] = useState('0.00');
  const [discountTotal, setDiscountTotal] = useState('0.00');
  const [grandTotal, setGrandTotal] = useState('0.00');

  const getSubtotal = () => {
    let subtotal = 0.0;
    props.invoiceItems.forEach((lItem: any)  => {
      let qty = parseInt(lItem.qty);
      if (!qty) {
        qty = 1;
      }

      let price = parseFloat(lItem.price);

      subtotal += (qty * price);
    });
    setSubTotal(subtotal.toFixed(2));
  };

  const getDiscountTotal = () => {
    if (props.invoice.discount !== null && props.invoice.discount.length === 0) {
      return setDiscountTotal('0');
    }
    let discount = Number(subTotal) * Number(props.invoice.discount / 100);
    setDiscountTotal(discount.toFixed(2));
  };

  const getTotal = () => {
    if (!props.invoiceItems.length) {
      return setGrandTotal('0');
    }
    let total = parseFloat(subTotal) - parseFloat(discountTotal);
    setGrandTotal(total.toFixed(2));
  };

  const handleContinueClick = () => {
    if (!isFormValid()) {
      return props.showModal({
        type: 'error',
        icon: 'user',
        title: 'Invalid Invoice Data',
        message: 'Please ensure you entered a discount between 0 and 100.',
        redirect: '',
      });
    }
  
    props.handleContinueClick();
  }

  const isFormValid = () => {
    let discount = Number(props.invoice.discount);

    if (isNaN(discount)) {
      return false;
    }

    if (discount < 0 || discount > 100) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    getSubtotal();
    getDiscountTotal();
    getTotal();
  }, [props.invoice]);

  useEffect(() => {
    getTotal();
  }, [getDiscountTotal]);

  return (
    <>
      <div className="stepHead">
          <h2>
          <span>Step 3 of 4</span>
          Discount & Notes
          </h2>
          <p>Set a discount for this invoice and include any notes or terms.</p>
      </div>
      <div id="step3">

        <div className="fieldWrap">
            <div className="label">Discount %</div>
            <input type="number" className="outline noBorder" value={props.invoice.discount} onChange={(e: any) => {props.updateInvoiceInfo('discount', e.target.value)}} />
        </div>

        <div className="fieldWrap">
            <div className="label">Add notes</div>
            <textarea className="outline noBorder" onChange={(e: any) => {props.updateInvoiceInfo('notes', e.target.value)}} value= {props.invoice.notes}>
            </textarea>
        </div>

        <div className="fieldWrap invoiceSummary">
          <div className="lineItem">
            <span>Subtotal</span>
            <span>{subTotal}</span>
          </div>
          {/* {taxTotal !== '0.00' && (
            <div className="lineItem">
              <span>Tax %</span>
              <span>{props.invoice.tax}</span>
            </div>
          )} */}
          {discountTotal !== '0' && (
            <div className="lineItem discount">
              <span>Discount</span>
              <span className="value">- {discountTotal}</span>
            </div>
          )}
        </div>

        <div className="fieldWrap paymentTotal">
          <div className="lineItem">
            <div className="label">
              Total
            </div>
            <div className="price">
              {PaymentUtils.formatPrice(grandTotal)}
            </div>
          </div>
        </div>

        <button className="btn rounded" onClick={handleContinueClick}>
            <span>Generate Invoice</span>
        </button>
      </div>
    </>
  );
}

export default CreateInvoiceStepThree;
