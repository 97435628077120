import React, {useState, useEffect} from 'react';
import PaymentUtils from '../../utils/PaymentUtils';

const InvoiceViewItem = (props: {
  item: {
    description: string,
    qty: number,
    price: string,
  },
  isProposal: boolean,
}) => {
  const [total, setTotal] = useState('0.00');
  
  useEffect(() => {
    if (props.item) {
      let qty = props.item.qty ? props.item.qty : 0;
      let price = props.item.price ? props.item.price : '0';
      let total = (qty * parseFloat(price)).toFixed(2).toString();

      setTotal(total);
    }
  }, [props.item]);

  return (
    <div className="invoiceItem">
      <div className="desc">
        {props.item.description ? (
          <p>{props.item.description}</p>
        ): (
          <p>No item description.</p>
        )}
      </div>
      <div className="details">
        {props.item.qty !== 0 && props.item.price !== '0' && (
          <>
          {props.item.qty > 1 && (
            <div className="calc">{props.item.qty} x ${props.item.price}</div>
          )}
            <div className="price">{PaymentUtils.formatPrice(total)}</div>
          </>
        )}
      </div>
    </div>
  );
}

export default InvoiceViewItem;
