import {Modal} from '../../../interfaces/modal';

import {ReactComponent as ContractIcon} from '../../../assets/img/contract.svg';

const NDAModal = (props: {
  modal: Modal;
  close: () => void;
}) => {

  const handleOptionClick = (option: boolean) => {
    if (props.modal.callback) {
      props.modal.callback(option);
      props.close();
    }
  }

  return (
    <>
    <div className="modalCopy">
      <div className="icon">
        <ContractIcon />
      </div>
      <h3>{props.modal.title}</h3>
      <div className="message">
        {props.modal.message}
      </div>
      <div className="split">
        <button className="btn rounded outline green" onClick={() => handleOptionClick(true)}>Yes</button>
        <button className="btn rounded outline green" onClick={() => handleOptionClick(false)}>No</button>
      </div>
    </div>
    </>
  )
}

export default NDAModal;