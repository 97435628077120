import React, {useState, useEffect, useRef, useCallback} from 'react';
import {ReactComponent as ProgressBar} from '../../assets/img/intake-progress.svg';
import {ReactComponent as MobileProgressBar} from '../../assets/img/intake-progress-mobile.svg';
import {UserInfo} from '../../interfaces/user';
import {gsap} from 'gsap';
import {DrawSVGPlugin} from "gsap/dist/DrawSVGPlugin";

const Intake = (props: {
  isLoading: boolean,
  isIntakeLoading: boolean,
  userInfo: UserInfo,
  prompt: string,
  update: (value: string) => void,
  submit: (NDA: boolean) => void,
}) => {
  const limit = 50;
  const enabledRef = useRef(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [showNDA, setShowNDA] = useState(false);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const isMobile = width <= 551;

  gsap.registerPlugin(DrawSVGPlugin);

  const leftGreenIn = useRef<gsap.core.Tween>();
  const rightGreenIn = useRef<gsap.core.Tween>();
  const leftGreenOut = useRef<gsap.core.Tween>();
  const rightGreenOut = useRef<gsap.core.Tween>();

  const progress = (percent: number) => {
    gsap.to("#left-blue-path", {duration: 0.5, drawSVG: `0 ${percent}%`, visibility: 'visible'});
    gsap.to("#right-yellow-path", {duration: 0.5, drawSVG: `0 ${percent}%`, visibility: 'visible'});
  }

  const handleTextAreaChange = (e: any) => {
    props.update(e.target.value);
  }

  const handleProgressChange = useCallback((count: number, limit: number) => {
    
    let percent = Math.round((count / limit) * 100);
    
    if(percent < 101) {
      setIsEnabled(false);
      setShowNDA(false);
      if ((leftGreenIn.current && leftGreenIn.current.isActive()) || (rightGreenIn.current && rightGreenIn.current.isActive())) return;

      gsap.set("#left-bottle .fill", {fill: '#5B82E5' , transitionDelay: '0'});
      gsap.set("#right-bottle .fill", {fill: '#EBD882' , transitionDelay: '0'});
      
      enabledRef.current = false;
      leftGreenIn.current = gsap.fromTo("#left-green-path", {drawSVG: "100% 0"}, {duration: 0.5, drawSVG: "100% 100%"});
      rightGreenIn.current = gsap.fromTo("#right-green-path", {drawSVG: "100% 0"}, {duration: 0.5, drawSVG: "100% 100%"});
      
      setTimeout(() => {
        gsap.set(
          [
          '#left-green-path',
          '#right-green-path'
          ],
          {visibility: 'hidden'}
        )
      }, 500);

      return progress(percent);
    }

    setIsEnabled(true);

    if (enabledRef.current) return;
    enabledRef.current = true;

    if ((leftGreenOut.current && leftGreenOut.current.isActive()) || (rightGreenOut.current && rightGreenOut.current.isActive())) return;
    
    leftGreenOut.current = gsap.fromTo("#left-green-path", {drawSVG: "100% 100%"}, {duration: 1, drawSVG: "100% 0", visibility: 'visible'});
    rightGreenOut.current = gsap.fromTo("#right-green-path", {drawSVG: "100% 100%"}, {duration: 1, drawSVG: "100% 0", visibility: 'visible'});
    
    gsap.set("#left-bottle .fill", {fill: '#5ecd4d', transitionDelay: '0.8'});
    gsap.set("#right-bottle .fill", {fill: '#5ecd4d', transitionDelay: '0.8'});

  }, []);

  useEffect(() => {
    let count = props.prompt.length;
    handleProgressChange(count, limit);
  }, [props.prompt, handleProgressChange]);

  useEffect(() => {
    gsap.to("#left-blue-path", {duration: 0.5, drawSVG: false, visibility: 'hidden'});
    gsap.to("#right-yellow-path", {duration: 0.5, drawSVG: false, visibility: 'hidden'});
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => {
      window.removeEventListener('resize', () => setWidth(window.innerWidth));
    }
  }, []);

  return (
    <>
    {props.isLoading && (
      <div className="loader"></div>
    )}
    {!props.isLoading && (
      <div className="contentWrap">
        <div className="pageContent">
          <div className="form">
            <button className={isEnabled ? 'btn rounded show' : 'btn rounded'} onClick={() => setShowNDA(true)}>
              <span>Get Started</span>
            </button>
            {isMobile ? (
              <MobileProgressBar />
            ): (
              <ProgressBar />
            )}
            <textarea placeholder="Describe your project specifications…" onChange={(e) => handleTextAreaChange(e)}></textarea>
          </div>
          <div className={showNDA ? 'NDA show' : 'NDA'}>
            <h3>Would You Like An NDA For This Project?</h3>
            <div className="btnWrap">
              <div className="split">
                <button className="btn rounded outline" onClick={() => props.submit(true)}>
                  <span>Yes</span>
                </button>
                <button className="btn rounded outline" onClick={() => props.submit(false)}>
                  <span>No</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
    </>
  );
}

export default Intake;
