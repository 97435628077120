import PaymentUtils from '../../utils/PaymentUtils';

import {ReactComponent as CheckIcon} from '../../assets/img/icons/check.svg';
import {ReactComponent as TimeIcon} from '../../assets/img/icons/time.svg';
import {ReactComponent as ClockIcon} from '../../assets/img/icons/clock.svg';

const SingleProjectMetrics = (props: {
  value: string;
  paid: string;
  owed: string;
}) => {
  return (
    <div className="metricWrap">
      <div className="statsWrap" id="invoiceStats">
        <div className="box">
          <div className="top">
            <div className="icon green">
              <CheckIcon />
            </div>
          </div>
          <div>
            <span className="label">Total Paid</span>
            <div className="metric price">{PaymentUtils.formatPrice(props.paid)}</div>
          </div>
        </div>
        <div className="box warn">
          <div className="top">
            <div className="icon red">
              <ClockIcon />
            </div>
          </div>
          <div>
            <span className="label">Total Owed</span>
            <div className="metric price">{PaymentUtils.formatPrice(props.owed)}</div>
          </div>
        </div>
        <div className="box">
          <div className="top">
            <div className="icon yellow">
              <TimeIcon />
            </div>
          </div>
          <div>
            <span className="label">Project Value</span>
            <div className="metric price">
              {PaymentUtils.formatPrice(props.value)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleProjectMetrics;
