import {useEffect, useState, useCallback} from 'react';

import DashHead from '../Admin/DashHead';
import APIUtils from '../../utils/APIUtils';
import NavUtils from '../../utils/NavUtils';
import QueryParamUtils from '../../utils/QueryParamUtils';
import ContractStatus from './ContractStatus';

import {UserInfo} from '../../interfaces/user';
import {Contract} from '../../interfaces/contract';
import {Modal} from '../../interfaces/modal';

import {ReactComponent as Logo} from '../../assets/img/logo.svg';
import {ReactComponent as CopyIcon} from '../../assets/img/icons/copy.svg';
import {ReactComponent as EmailIcon} from '../../assets/img/icons/envelope.svg';
import {ReactComponent as EditIcon} from '../../assets/img/icons/edit.svg';
import {ReactComponent as DeleteIcon} from '../../assets/img/icons/trash.svg';

const ContractProfileView = (props: {
  userInfo?: UserInfo | undefined,
  isLoading: boolean,
  showModal: (details: Modal) => void,
}) => {

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [contract, setContract] = useState<Contract>();
  const [contactName, setContactName] = useState<any>('');
  const [defaultContactEmail, setDefaultContactEmail] = useState<any>('');

  const [templateHTML, setTemplateHTML] = useState<any>({
    __html: ''
  });

  const [isDisclosure, setIsDisclosure] = useState<boolean>(false);
  const [isPublishable, setIsPublishable] = useState<boolean>(false);

  const [isExpired, setIsExpired] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);
  const [issuedDate, setIssuedDate] = useState('');
  const [issuedTime, setIssuedTime] = useState<string>('');

  const requiredFields = [
    'title',
    'expiryDate',
    'projectid',
    'userid',
  ]

  const checkIsPublishable = (contract: Contract) => {
    let errors: string[] = [];
    requiredFields.map((field: string, index: number) => {
      if (!contract[`${field}` as keyof typeof contract]) {
        errors.push(field);
      }
    });
    if  (errors.length > 0) {
      return false;
    }
    return true;
  }

  const fetchContract = useCallback((id: string) => {
    APIUtils.callGet(`api/contract/${id}`)
    .then(response => {
      if (response.status !== 200) {
        setIsLoading(false);
        return NavUtils.redirectToContracts();
      }
      setIsPublishable(checkIsPublishable(response.data));
      setContract(response.data);
      setTemplateHTML({
        __html: response.data.previewTemplate
      })

      if (props.userInfo?.id === response.data.userid) {
        setIsDisclosure(true);
      } else {
        setIsDisclosure(false);
      }
      setIsLoading(false);
    })
    .catch((err) => {
      console.error(err);
      return props.showModal({
        type: 'error',
        icon: 'contract',
        title: `Fetching Contract`,
        message: 'Cound not return contract info.',
        redirect: '/contracts',
      });
    });
  },[props]);

  const getContractIdFromQueryParams = () => {
    return window.location.href.split("/").pop();
  }

  const getContractIdFromQueryParamsNoHash = () => {
    let id = window.location.href.split("/").pop();
    if (id !== undefined) {
      return id.split('?')[0];
    }

    return '';
  }

  const fetchContractTemplate = async () => {
    let response = await APIUtils.callGet(`api/view_contract/${window.location.href.split("/").pop()}`)
    if(response.status === 200) {
      setTemplateHTML({
        __html: response.data.__html
      });
    };
  };

  const handleConfirmDeleteContract = () => {
    props.showModal({
      type: 'delete',
      icon: 'contract',
      title: 'Are you sure?',
      message: `Contract #${contract?.id} will be permenantly deleted. This action cannot be undone.`,
      callback: handleDeleteContract,
    });
  }

  const handleDeleteContract = async () => {
    try {
      await APIUtils.callDelete(`api/contract/${getContractIdFromQueryParams()}`, {});
      return props.showModal({
        type: 'success',
        icon: 'contract',
        title: `Contract #${getContractIdFromQueryParamsNoHash()}`,
        message: 'Contract deleted.',
        redirect: '/contracts',
      });
    } catch (err: any) {
      console.log(err);
      return props.showModal({
        type: 'error',
        icon: 'contract',
        title: `Deleting Contract #${getContractIdFromQueryParamsNoHash()}`,
        message: err.message,
      });
    }
  };

  // const publishContract = async () => {
  //   try {
  //     let response = await APIUtils.callPost(`api/contract_publish/${getContractIdFromQueryParams()}`, {});
  //     return props.showModal({
  //       type: 'success',
  //       icon: 'contract',
  //       title: `Contract #${getContractIdFromQueryParams()}`,
  //       message: 'Contract published.',
  //       redirect: '/contracts',
  //     });
  //   } catch (err: any) {
  //     console.log(err);
  //     return props.showModal({
  //       type: 'error',
  //       icon: 'contract',
  //       title: `Publishing Contract #${getContractIdFromQueryParams()}`,
  //       message: err.message,
  //       redirect: '',
  //     });
  //   }
  // };

  // const convertContractDraft = async () => {
  //   try {
  //     let response = await APIUtils.callPost(`api/contract_draft/${getContractIdFromQueryParams()}`, {});
  //     return props.showModal({
  //       type: 'success',
  //       icon: 'contract',
  //       title: `Contract #${getContractIdFromQueryParams()}`,
  //       message: 'Contract Converted to Draft.',
  //       redirect: `/contract/edit/${getContractIdFromQueryParams()}`,
  //     });
  //   } catch (err: any) {
  //     console.log(err);
  //     return props.showModal({
  //       type: 'error',
  //       icon: 'contract',
  //       title: `Converting Contract #${getContractIdFromQueryParams()} to Draft`,
  //       message: err.message,
  //       redirect: '',
  //     });
  //   }
  // };

  const copyContractUrl = () => {
    navigator.clipboard.writeText(window.location.origin + '/contract/' + getContractIdFromQueryParams() + '?hash=' + contract?.hash);
    setCopied(true);
    setTimeout(() => setCopied(false), 500);
  };

  const treatAsUTC = (date: Date) => {
    let result = new Date(date);
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
    return Number(result);
  }

  const daysBetween = useCallback((startDate: Date, endDate: Date) => {
    let millisecondsPerDay = 24 * 60 * 60 * 1000;
    let value = Math.round((treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay);
    if(value < 0) {
      setIsExpired(true);
    }
    return value;
  }, []);

  const handleSendEmailBtnClick = () => {
    props.showModal({
      type: 'email',
      icon: 'email',
      title: `Share Contract`,
      message: `Send contract to be viewed and/or signed via email.`,
      data: {
        email: defaultContactEmail,
        type: 'contract',
        itemId: contract?.id,
        hash: contract?.hash,
        userInfo: props.userInfo
      },
      callback: (response) => handleSendEmailResponse(response)
    });
  }

  const handleSendEmailResponse = (response: {
    status: number,
    message: string,
    data: any,
  }) => {
    if (response.status !== 200) {
      props.showModal({
        type: 'error',
        icon: 'email',
        title: `Sending Email`,
        message: `There was an error sending email to recipient.`,
      });
    }
    props.showModal({
      type: 'success',
      icon: 'email',
      title: `Email Sent`,
      message: `An email with the contract link was sent to ${response.data.email}`,
    });
  }

  useEffect(() => {
    let contractId = getContractIdFromQueryParams();
    if (typeof contractId === 'string' && contractId.length) {
      fetchContract(contractId);
      fetchContractTemplate();
    }
  }, [props.userInfo]);

  useEffect(() => {
    const monthNames = [
      "Jan", "Feb", "March", "April", "May",
      "June","July", "Aug", "Sept", "Oct", "Nov", "Dec",
    ];
    if (contract?.issueDate) {
      const issued = new Date(contract.issueDate);
      const issuedDate = `${monthNames[issued.getMonth()]} ${issued.getDate().toString()}`;
      setIssuedDate(issuedDate);
      const hour = (issued.getHours() % 12) !== 0 ? (issued.getHours() % 12) : 12;
      const minute = issued.getMinutes() < 10 ? `0${issued.getMinutes()}` : issued.getMinutes();
      const amorpm = (issued.getHours() >= 12) ? "pm" : "am";
      const time = `${hour}:${minute.toString()} ${amorpm}`;
      setIssuedTime(time);
    }
    if (contract?.expiryDate) {
      const currentDate = new Date();
      const due = new Date(contract.expiryDate);
      daysBetween(currentDate, due).toString().replace("-","");
    }
    if (contract?.contactid && contract.company?.contacts) {
      let contactInfo = contract.company.contacts.find(contact => contact.id = contract.contactid);
      setDefaultContactEmail(contactInfo?.email);
      if (contactInfo?.firstname && contactInfo?.lastname) {
        return setContactName(`${contactInfo.firstname} ${contactInfo.lastname}`);
      }
    }
  }, [contract]);

  const handleBackButtonPress = () => {
    let backPage = QueryParamUtils.getParamValue('return');
    if (typeof backPage === 'string' && backPage.trim().length) {
      return window.location.href = backPage;
    }

    NavUtils.redirectToContracts();
  }

  let statusClass = `
    ${contract?.isdraft ? 'draft' : ''}
    ${contract?.signedDate ? 'success' : ''}
    ${contract?.declinedDate || isExpired ? 'late' : ''}
    ${!contract?.signedDate && !contract?.declinedDate && !contract?.isdraft && !isExpired ? 'pending' : ''}
  `;

  return (
    <>
    {isLoading && (
      <div className="loader"></div>
    )}
    {!isLoading && props.userInfo && (
      <div className="componentWrap docProfileView" id="contractView" >
      <DashHead
        pageTitle={`${contract?.type} for ${contract?.company?.name}`}
        pageSubTitle={contract?.title}
        userInfo={props.userInfo}
        back={handleBackButtonPress}
      />
      <div className="split">

        <div className="details">

          <div>

            <div className="detailItem">
              <div className="label">
                Type
              </div>
              <div className="value">
                {contract?.type}
              </div>
            </div>

            <div className="detailItem">
              <div className="label">
                Status
              </div>
              <div className="value">
                {contract && (
                  <ContractStatus 
                    contract={contract}
                  />
                )}
              </div>
            </div>

            <div className="detailItem">
              <div className="label">
                Project
              </div>
              <div className="value">
                {contract?.title}
              </div>
            </div>

            <div className="detailItem">
              <div className="label">
                Client
              </div>
              <div className="value">
                {contract?.company?.name}
              </div>
            </div>

            <div className="detailItem">
              <div className="label">
                Contact Person
              </div>
              <div className="value">
                {!contactName && (
                  <>
                    {defaultContactEmail}
                  </>
                )}
                {contactName && (
                  <>
                    {contactName}
                  </>
                )}
                <a href={`mailto:${defaultContactEmail}`} className="emailBtn">
                  <EmailIcon />
                </a>
              </div>
            </div>

            <div className="detailItem">
              <div className="label">
                Duration
              </div>
              <div className="value">
                {contract?.duration && (
                  <>{contract.duration}</>
                )}
                {!contract?.duration && (
                  <div className="status late">Duration Not Set.</div>
                )}
              </div>
            </div>
            
            {
              contract?.issueDate && (
                <div className="detailItem">
                  <div className="label">
                    Sent
                  </div>
                  <div className="value">
                    {issuedDate}, {issuedTime}
                  </div>
                </div>
              )
            }

          </div>
          
          {!contract?.signedDate && (
            <div className="btnWrap">
              <div className="split">
                <button className="btn rounded" onClick={() => handleSendEmailBtnClick()}>
                  <span><EmailIcon /> Send To Email</span>
                </button>
                <button className={`btn rounded ${copied ? 'copied' : ''}`} onClick={copyContractUrl}>
                  <span>
                    <CopyIcon />
                    {
                      copied ? (
                        <>Copied</>
                      ) : (
                        <>Copy Link</>
                      )
                    }
                  </span>
                </button>
              </div>
              <a className="btn rounded outline green editBtn" href={'/contract/edit/'+getContractIdFromQueryParams()}>
                <span>
                  <EditIcon /> Edit
                </span>
              </a>
              <div className="or">
                <span>or</span>
              </div>
              <button className="btn rounded outline delete" onClick={handleConfirmDeleteContract}>
                  <span>
                    <DeleteIcon /> Delete
                  </span>
                </button>
            </div>
          )}

        </div>

        <div className="box preview">
          <button className="btn rounded" onClick={() => NavUtils.redirectToPublicContract(contract?.id)}>
            <span>Preview</span>
          </button>
          <div>
            <Logo />
          </div>
          <div dangerouslySetInnerHTML={templateHTML}></div>
        </div>

      </div>
    </div>
    )}
    </>
  );
}

export default ContractProfileView;
