import _ from 'lodash';
import {useState, useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';

import DashHead from '../Admin/DashHead';
import InvoiceListItem from './InvoiceListItem';
import APIUtils from '../../utils/APIUtils';
import Filters from '../General/Filters';
import InvoiceDashMetrics from '../Admin/InvoiceDashMetrics';
import ListItemLoading from '../Admin/ListItemLoading';

import {UserInfo} from '../../interfaces/user';
import {Invoice} from '../../interfaces/invoice';

const Payments = (props: {
  userInfo?: UserInfo | undefined;
  isLoading: boolean;
}) => {
  const [invoiceLoading, setInvoiceLoading] = useState<boolean>(true);
  const [filter, setFilter] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [payments, setPayments] = useState<Invoice[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const [invoiceStats, setInvoiceStats] = useState<any>({
    paid: 0,
    pending: 0,
    late: 0,
  });

  const retrievePayments = _.debounce(async () => {
    try {
      let url = `api/invoice/all`;

      if (searchTerm.trim().length) {
        url += `?search=${searchTerm}`;
      }

      if (filter.trim().length) {
        if (searchTerm.trim().length) {
          url += `&filter=${filter}`
        } else {
          url += `?filter=${filter}`
        } 
      }

      let response: any = await APIUtils.callGet(url);
      let invoices: Invoice[] = response.data;
      
      setPayments(invoices);
      setInvoiceLoading(false);
    } catch (err) {
      console.log(err);
      setPayments([]);
    }
  }, 300);

  const retrieveInvoiceStats = _.debounce(() => {
    APIUtils.callGet(`api/invoice_stats`)
    .then(response => {
      setInvoiceStats(response.data);
    })
    .catch((err) => {
      return console.error(err);
    });
  });

  const search = _.debounce((event: any) => {
    setSearchTerm(event.target.value);
  }, 300);

  const updateSelectedFilterText = (filter: string) => {
    if (filter === 'All') {
      filter = '';
    }
    setFilter(filter.toLowerCase());
  }

  useEffect(() => {
    if (!props.userInfo) return;

    retrieveInvoiceStats();
    retrievePayments();
  }, [props.userInfo, searchTerm, filter]);

  useEffect(() => {
    let defaultFiltlerValue = searchParams.get('filter');
    if (defaultFiltlerValue && defaultFiltlerValue !== null) {
      setFilter(defaultFiltlerValue);
    }
  }, []);
  

  return (
    <>
    {props.isLoading && (
      <div className="loader"></div>
    )}
    {!props.isLoading && props.userInfo && (
      <>
      <div className="componentWrap" id="payments">
        <DashHead
          pageTitle={'Invoices'}
          userInfo={props.userInfo}
          create={'/invoice/create'}
        />
        <div className="pageContent">
          <InvoiceDashMetrics
            collected={invoiceStats.paid}
            pending={invoiceStats.pending}
            outstanding={invoiceStats.late}
            showTitle={false}
            setFilter={(value) => updateSelectedFilterText(value)}
          />
          <Filters 
            filterValues={['All', 'Drafts', 'Pending', 'Paid', 'Overdue']}
            selectedFilter={filter}
            setSelectedFilter={(filter) => updateSelectedFilterText(filter)}
            showDraftsAsProposals={true}
          />
          <div className="main">
            {
              invoiceLoading && (
                <ListItemLoading />
              )
            }
            {
              !invoiceLoading &&
              payments.length > 0 && 
              payments.map((item, i) => {
                return (
                  <InvoiceListItem 
                    key={i}
                    invoice={item}
                  />
                ) 
              })
            }
            {
              !invoiceLoading &&
              payments.length == 0 && (
                <p className="placeholder">No Invoices found.</p>
              )
            }
          </div>

        </div>
      </div>
      </>
    )}
    </>
  );
}

export default Payments;
