import _ from 'lodash';
import {useState, useEffect, useCallback} from 'react';
import {useParams} from 'react-router-dom';

import DashHead from '../Admin/DashHead';
import APIUtils from '../../utils/APIUtils';
import NavUtils from '../../utils/NavUtils';
import QueryParamUtils from '../../utils/QueryParamUtils';
import SingleProjectMetrics from './SingleProjectMetrics';
import SingleProjectInvoices from './SingleProjectInvoices';
import SingleClient from '../Client/SingleClient';
import SingleProjectContracts from './SingleProjectContracts';

import {UserInfo} from '../../interfaces/user';
import {Project} from '../../interfaces/project';
import {Modal} from '../../interfaces/modal';

const SingleProject = (props: {
  userInfo?: UserInfo | undefined;
  isLoading: boolean;
  showModal: (details: Modal) => void;
}) => {
  const [projectLoading, setProjectLoading] = useState<boolean>(true);
  const [project, setProject] = useState<Project>();
  const [tab, setTab] = useState<string>('client');
  const [projectStats, setProjectStats] = useState<any>({
    total: 0,
    paid: 0,
    owed: 0,
  });

  const params = useParams();
  const projectid = params.id;

  const retrieveProject = async (projectid: string) => {
    try {
      let url = `api/project/${projectid}`;

      let response: any = await APIUtils.callGet(url);
      let project: Project = response.data;

      setProject(project);
      setProjectLoading(false);
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const retrieveProjectStats = async (projectid: string) => {
    try {
      let response: any = await APIUtils.callGet(`api/project/single_stats/${projectid}`);

      if (response.status === 200) {
        setProjectStats(response.data.stats);
      }

      return false;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const handleBackButtonPress = () => {
    let backPage = QueryParamUtils.getParamValue('return');
    if (typeof backPage === 'string' && backPage.trim().length) {
      return window.location.href = backPage;
    }

    NavUtils.redirectToProjects();
  }

  useEffect(() => {
    if(typeof projectid !== 'undefined') {
      retrieveProject(projectid);
      retrieveProjectStats(projectid);
    }
  }, [projectid]);

  useEffect(() => {
    if (project?.invoices && project?.invoices.length > 0) {
      return setTab('invoice');
    }
    if (project?.contracts && project.contracts.length > 0) {
      return setTab('contract');
    }
  }, [project]);

  return (
    <>
    {projectLoading && (
      <div className="loader"></div>
    )}
    {!projectLoading && project && (
      <div className="componentWrap" id="singleProject">

        <DashHead
          pageTitle={project.name}
          pageSubTitle={project.company?.name}
          userInfo={props.userInfo}
          status={project.status}
          back={handleBackButtonPress}
        />

        <div className="pageContent">
          <SingleProjectMetrics 
            value={projectStats.total}
            paid={projectStats.paid}
            owed={projectStats.owed}
          />
          <div className="singleProjectTabs">

            <div className="half">
              {project.invoices && project.invoices.length > 0 && (
                <button className={tab === 'invoice' ? "btn link tab activeTab" : "btn link tab"} onClick={() => setTab('invoice')}>
                  Invoices
                </button>
              )}
              {project.contracts && project.contracts.length > 0 && (
                <button className={tab === 'contract' ? "btn link tab activeTab" : "btn link tab"} onClick={() => setTab('contract')}>
                  Contracts
                </button>
              )}
              <button className={tab === 'client' ? "btn link tab activeTab nobar" : "btn link tab"} onClick={() => setTab('client')}>
                Client
              </button>
            </div>

            {project.invoices && project.invoices.length > 0 && tab === 'invoice' && (
              <SingleProjectInvoices
                projectid={project.id}
                invoices={project.invoices}
                company={project.company?.name}
              />
            )}

            {project.contracts && project.contracts.length > 0 && tab === 'contract' && (
              <SingleProjectContracts 
                projectid={project.id}
                contracts={project.contracts}
              />
            )}
            
            { tab === 'client' && 
              project.companyid && (
              <SingleClient 
                clientId={project.companyid}
                isLoading={true}
                showModal={(details: Modal) => props.showModal(details)}
                hideHeaders={true}
              />
            )}

          </div>
        </div>
      </div>
    )}
    </>
  );
}

export default SingleProject;
